// import React, { useEffect, useState } from 'react'

// import { useAuth } from '../../components/context/Auth'
// import { CheckGreenIcon, DownloadIcon, ReloadIcon, Search, UpArrowIcon } from '../../components/icons/Icon'
// import styles from "./Invoices.module.css"
// import Paginantion from '../../components/Paginantion'
// import { useNavigate, useSearchParams } from 'react-router-dom'

// const Invoices = () => {
//   const navigate = useNavigate();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
//   const { managerId, designation } = useAuth();
//   const [data, setData] = useState([]);
//   const [daysTab, setDaysTab] = useState('today')
//   //   const [shipingTab, setShipingTab] = useState('all')
//   const [orderType, setOrderType] = useState('')
//   const [cartTab, setCartTab] = useState('all')
//   const [orders, setOrders] = useState([])
//   const [keyword, setKeyword] = useState('')
//   const [sortKey, setSortKey] = useState('')
//   const [loading, setLoading] = useState(false)
//   const [orderStatus, setOrderStatus] = useState('')
//   //   const [warehouses, setWarehouses] = useState([])
//   //   const [warehouseName, setWarehouseName] = useState('')
//   // const [managers, setManagers] = useState([])
//   // const [filterManagers, setFilterManagers] = useState([])
//   const [ordermanagerId, setOrdermanagerId] = useState([])
//   const [dispatchManagerId, setDispatchManagerId] = useState([])
//   const [managerName, setManagerName] = useState('')
//   const [dispatchManagerName, setDispatchManagerName] = useState('')
//   const [startDate, setStartDate] = useState('')
//   const [endDate, setEndDate] = useState('')
//   const [state, setState] = React.useState({
//     totalPages: "",
//     currentPage: 1
//   });

//   // useEffect(() => {
//   //   setFilterManagers([...ordermanagerId, ...dispatchManagerId])

//   // }, [ordermanagerId, dispatchManagerId])

//   // useEffect(() => {
//   //   // Create a Set to store unique IDs
//   //   const uniqueIds = new Set();

//   //   // Use filter to create a new array with only unique objects based on 'id'
//   //   const uniqueData = filterManagers.filter(item => {
//   //     if (!uniqueIds.has(item.managerId)) {
//   //       uniqueIds.add(item.managerId);
//   //       return true;
//   //     }
//   //     return false;
//   //   });

//   //   setManagers(uniqueData);
//   // }, [filterManagers]);

//   const { totalPages, } = state;

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true)
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         "managerId": parseInt(managerId),
//         "keyword": keyword,
//         "invoiceStatus": orderStatus,
//         "sortKey": sortKey,
//         // "orderType": shipingTab,
//         "cartType": cartTab,
//         "ordermanagerId": managerName,
//         "dispatchManagerId": dispatchManagerName,
//         "day": daysTab,
//         "startDate": startDate,
//         "endDate": endDate
//       });

//       var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow'
//       };

//       fetch(`${process.env.REACT_APP_URL}invoice/allInvoices?page=${page}&limit=8`, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//           if (result.status === 'success') {
//             setLoading(false)
//             setData(result.data)
//             setOrders(result.orders)
//             if (result.warehouses) {
//               //   setWarehouses(result.warehouses)
//             }
//             if (result.managers) {
//               setOrdermanagerId(result.managers.ordermanagers)
//               setDispatchManagerId(result.managers.dispatchmanagers)
//             }
//           }
//           if (result.pages) {
//             setState(prevState => ({
//               ...prevState,
//               totalPages: result.pages ? result.pages : "",
//             }))
//           }
//         })
//         .catch(error => console.log('error', error));
//     }
//   }, [managerId, sortKey, keyword, page, orderStatus, cartTab, managerName, daysTab, startDate, endDate, dispatchManagerName])

//   return (
//     <React.Fragment>

//       <div className={styles.right_main}>
//         <div className={styles.dispatchDashboard_header}>
//           <h1 className={styles.dispatchHeading}>Invoices</h1>

//           <div className={styles.celender_div}>

//             <div className={styles.reload} onClick={() => window.location.reload()}>
//               <span><ReloadIcon /></span>
//               <span className={styles.reload_txt}>Reload</span>
//             </div>

//             <div className={styles.dashboard_header_select_week}>
//               <div className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => setDaysTab('today')}>Today</div>
//               <div className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => setDaysTab('yesterday')}>Yesterday</div>
//               <div className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => setDaysTab('month')}>This Month</div>
//             </div>

//             <div className={styles.startEndDate_div}>
//               <span>Start Date&nbsp;&nbsp;</span>
//               <input
//                 className={styles.startEndDate}
//                 type="date"
//                 value={startDate}
//                 onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
//               />
//             </div>
//             <div className={styles.startEndDate_div}>
//               <span>End Date&nbsp;&nbsp;</span>
//               <input
//                 className={styles.startEndDate}
//                 type="date"
//                 value={endDate}
//                 onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
//               />
//             </div>
//           </div>

//         </div>

//         <div className={styles.dashboard_main_container}>
//           <div className={styles.dashboard_main_div}>

//             <div className={styles.Invices_statusCard_div}>
//               {orders && orders.map((item, index) =>
//                 <div key={index} className={`${styles.statusCard} ${orderStatus === item.invoiceStatus && item.invoiceStatus === 'Reupload' ? styles.packed_active :
//                   orderStatus === item.invoiceStatus && item.invoiceStatus === 'Uploaded' ? styles.Dispatches_active :
//                     orderStatus === item.invoiceStatus && item.invoiceStatus === 'Pending' ? styles.inProgress_active :
//                       orderStatus === item.invoiceStatus && item.invoiceStatus === 'All' ? styles.orange_active :
//                         orderStatus === item.invoiceStatus && item.invoiceStatus === 'Cancelled' ? styles.cancel_status :
//                           orderStatus === item.invoiceStatus && item.invoiceStatus === 'OrderReturn' ? styles.Pending_active :
//                             ''}`}
//                   // onClick={() => {setOrderStatus(item.status);}}
//                   onClick={() => {
//                     setOrderStatus(item.invoiceStatus);
//                     if (searchParams.has("page")) {
//                       const token = searchParams.get("page");
//                       if (token) {
//                         searchParams.delete("page");
//                         setSearchParams(searchParams);
//                       }
//                     }
//                   }}>
//                   <span className={
//                     item.invoiceStatus === 'OrderReturn' ? styles.statusRed :
//                       // item.status === 'In Progress' ? styles.statusYellow :
//                       item.invoiceStatus === 'Reupload' ? styles.statusReupload :
//                         item.invoiceStatus === 'All' ? styles.statusAll :
//                           item.invoiceStatus === 'Pending' ? styles.statusYellow :
//                             item.invoiceStatus === 'Uploaded' ? styles.statusGreen :
//                               item.invoiceStatus === 'Cancelled' ? styles.statusWhite :
//                                 ''}>
//                     {item.count}
//                   </span>
//                   <span className={styles.statusTxt}>{item.invoiceStatus}</span>
//                 </div>
//               )}
//             </div>

//             <div className={styles.dispatchDashboard_header}>
//               <div className={styles.dashboard_search_div}>
//                 <input type="search"
//                   placeholder='Search'
//                   className={styles.dashboard_search}
//                   value={keyword}
//                   onChange={(e) => setKeyword(e.target.value)}
//                 />
//                 <Search color="#ffffff" css={styles.dashboard_search_icon} />
//               </div>

//               <div className={styles.source_header_select_week}>
//                 <div className={`${styles.source_days_select} ${orderType === '' ? styles.days_active : ''}`} onClick={() => setOrderType('')}>All</div>
//                 <div className={`${styles.source_days_select} ${orderType === 'online' ? styles.days_active : ''}`}
//                 // onClick={() => {
//                 //     setOrderType('online');
//                 //     if (searchParams.has("page")) {
//                 //         const token = searchParams.get("page");
//                 //         if (token) {
//                 //             searchParams.delete("page");
//                 //             setSearchParams(searchParams);
//                 //         }
//                 //     }
//                 // }}
//                 >Online</div>
//                 <div className={`${styles.source_days_select} ${orderType === 'offline' ? styles.days_active : ''}`}
//                 //  onClick={() => {
//                 //     setOrderType('offline');
//                 //     if (searchParams.has("page")) {
//                 //         const token = searchParams.get("page");
//                 //         if (token) {
//                 //             searchParams.delete("page");
//                 //             setSearchParams(searchParams);
//                 //         }
//                 //     }
//                 // }}
//                 >Offline</div>
//               </div>

//               <div className={styles.dashboard_header_select_week}>
//                 <div className={`${styles.dashboard_days_select} ${cartTab === 'all' ? styles.days_active : ''}`} onClick={() => setCartTab('all')}>All</div>
//                 <div className={`${styles.dashboard_days_select} ${cartTab === 'Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Cart')}>Cart</div>
//                 <div className={`${styles.dashboard_days_select} ${cartTab === 'Partner Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Partner Cart')}>Partner Cart</div>
//               </div>

//               {/* {designation === 'superadmin' &&
//                 <div className={styles.dashboard_Sort_By}>
//                   <label className={styles.dashboard_sortBy_txt}>Warehouse</label>
//                   <select
//                     className={styles.dashboard_sortBy_select}
//                     value={warehouseName}
//                     onChange={(e) => setWarehouseName(e.target.value)}
//                   >
//                     <option value='All'>View All</option>
//                     {
//                       warehouses && warehouses.map((item, index) =>
//                         <option key={index} value={item.warehousename}>{item.warehousename}</option>
//                       )
//                     }
//                   </select>
//                 </div>
//               } */}


//               {designation === 'superadmin' &&
//                 <div className={styles.dashboard_Sort_By}>
//                   <label className={styles.dashboard_sortBy_txt}>OM</label>
//                   <select
//                     className={styles.dashboard_sortBy_select}
//                     value={managerName}
//                     onChange={(e) => setManagerName(e.target.value)}
//                   >
//                     <option value='All'>View All</option>
//                     {
//                       ordermanagerId && ordermanagerId.map((item, index) =>
//                         <option key={index} value={item.managerId}>{item.name}</option>
//                       )
//                     }
//                   </select>
//                 </div>
//               }

//               {designation === 'superadmin' &&
//                 <div className={styles.dashboard_Sort_By}>
//                   <label className={styles.dashboard_sortBy_txt}>DM</label>
//                   <select
//                     className={styles.dashboard_sortBy_select}
//                     value={dispatchManagerName}
//                     onChange={(e) => setDispatchManagerName(e.target.value)}
//                   >
//                     <option value='All'>View All</option>
//                     {
//                       dispatchManagerId && dispatchManagerId.map((item, index) =>
//                         <option key={index} value={item.managerId}>{item.name}</option>
//                       )
//                     }
//                   </select>
//                 </div>
//               }


//               <div className={styles.dashboard_Sort_By}>
//                 <label className={styles.dashboard_sortBy_txt}>Sort By </label>
//                 <select
//                   className={styles.dashboard_sortBy_select}
//                   value={sortKey || orderStatus}
//                   onChange={(e) => {
//                     if (e.target.value === 'Ascending' || e.target.value === 'Descending' || e.target.value === 'lastAction') {
//                       setSortKey(e.target.value)
//                     } else {
//                       setOrderStatus(e.target.value)
//                       setSortKey("")
//                     }
//                   }}
//                 >
//                   <option value="All">View All</option>
//                   {/* <option value="All">All</option> */}
//                   <option value="New Order">New Order</option>
//                   <option value="Cancelled">Cancelled</option>
//                   <option value="In Progress">In Progress</option>
//                   <option value="Packed">Packed</option>
//                   <option value="Pending">Pending</option>
//                   <option value="Dispatched">Dispatched</option>
//                   <option value="Ascending">Ascending</option>
//                   <option value="Descending">Descending</option>
//                   <option value="lastAction">Last Action</option>
//                 </select>
//               </div>

//               <div className={styles.dashboard_Sort_By} >
//                 <div role='button' className={styles.dashboard_sortBy_txt}>Send Data</div>
//                 <div role='button'><UpArrowIcon /></div>
//               </div>
//               <div className={styles.dashboard_Sort_By} >
//                 <div role='button' className={styles.dashboard_sortBy_txt}>Download</div>
//                 <div role='button'><DownloadIcon /></div>
//               </div>

//             </div>


//             {loading ? <div className={'order_loadingMain'}>
//               <img src='/wallicon.gif' alt='walliconGIF' />
//             </div> :
//               <>

//                 {data.length !== 0 ?
//                   <div className={styles.dispatchOrders_height}>
//                     <table className={styles.itemTable}>
//                       <thead>
//                         <tr className={styles.item}>
//                           <th>
//                             {true ? <span className={styles.checkbox_empty}></span> : <span className={styles.checkbox}><CheckGreenIcon /></span>}
//                           </th>
//                           <th>Name & City</th>
//                           <th>Cart Type</th>
//                           <th>Date</th>
//                           <th>Order Id</th>
//                           <th>Amount</th>
//                           <th>Status</th>
//                           <th>Last Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {data && data.map((val, index) =>
//                           <tr key={index}
//                             className={`${styles.item} ${val.reqStatus === "Pending" ? 'text-danger' : ""}`}
//                             onClick={() => { navigate('/invoices/invoice?orderId=' + val.orderId)}}
//                             title={val.reqStatus === "Pending" ? val.reqIssue : ""}
//                           >
//                             <th> {true ? <span className={styles.checkbox_empty}></span> : <span className={styles.checkbox}><CheckGreenIcon /></span>}</th>
//                             <td className={styles.ordersName_truncate}
//                               title={val.storeName + ", " + val.city}
//                             >
//                               <span className={styles.hide}> {val.storeName + ", " + val.city}</span></td>
//                             <td>{val.cartType} </td>
//                             <td>{val.orderDate} </td>
//                             <td>{val.orderId} </td>
//                             <td>{val.totalAmount}</td>
//                             <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
//                               styles.table_txt_green : val.status === 'Payment Pending' ?
//                                 styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending') ?
//                                   styles.table_txt_yellow : val.status === 'New Order' ?
//                                     styles.table_txt_blue : ''}`}>
//                               {val.status}
//                             </td>
//                             <td>{val.lastAction} </td>
//                           </tr>
//                         )}
//                       </tbody>
//                     </table>
//                   </div>
//                   :
//                   <div className={styles.noData}>
//                     No Data Found !
//                   </div>}
//               </>
//             }

//             {data.length !== 0 ?
//               <Paginantion
//                 total={totalPages}
//                 current={page}
//               />
//               :
//               ''
//             }
//           </div>
//         </div>
//       </div>

//     </React.Fragment>
//   )
// }

// export default Invoices





import React, { useEffect, useState } from "react";

import styles from "./Invoices.module.css"
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import Button from "../../components/updatedComponents/Dealers/Button";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import { DispatchDownloadIcon, DispatchReloadIcon } from "../../components/icons/Icon";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import { Shareinvoice } from "../../components/icons/Icon4";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import fetchData from "../../APIsControll/apiControll";
import { useNavigate } from 'react-router-dom'
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";

const Invoices = () => {
  const navigate = useNavigate();

  const [daysTab, setDaysTab] = useState("today");
  const [keyword1, setKeyword1] = useState("");
  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [statusData, setStatusData] = useState([])
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoicestatus, setInvoicestatus] = useState("");
  const [loading, setLoading] = useState(false);

  const { mode, managerId } = useAuth();
  const [ordermanager, setOrdermanager] = useState([]);
  const [dispatchmanager, setDispatchmanager] = useState([]);
  const [selectedordermanager, setSelectedOrdermanager] = useState("");
  const [selecteddispatchmanager, setSelectedDispatchmanager] = useState("");
  console.log("ordermanager", ordermanager);
  const [filteredData, setFilteredData] = useState([
    { status: "New Order" },
    { status: "Draft" },
    { invoiceStatus: "Payment Pending" },
    { status: "Packing In Progress" },
    { invoiceStatus: "Completed" },
    { status: "Cancelled" }
  ]);
  const [selectedSortby, setSelectedSortby] = useState("");

  const dayOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },

  ];
  const orderHeader = [
    'check',
    "Name & City",
    "Order Date",
    "Order Status",
    "Order ID",
    "Amount",
    "Status",
    "Last Action",
  ];

  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setDaysTab(selectedFilter?.value || "today");
  };

  const options = ordermanager.map(ormanager => ({
    label: ormanager.name,
    value: ormanager.managerId
  }));
  console.log("options", options);

  const handleOredermanagerSelection = (optionis) => {
    setSelectedOrdermanager(optionis.value);
  };
  const handleOredermanagerRejection = () => {
    setSelectedOrdermanager(null);
  };

  const options1 = dispatchmanager.map(dismanager => ({
    label: dismanager.name,
    value: dismanager.managerId
  }));
  const option3 = [
    { label: "New Order", value: "New Order" },
    { label: "Draft", value: "Draft" },
    { label: "In Progress", value: "In Progress" },
    { label: "Pending", value: "Pending" },
    { label: "Payment Pending", value: "Payment Pending" },
    { label: "Packing In Progress", value: "Packing In Progress" },
    { label: "Packed", value: "Packed" },
    { label: "Item Out of Stock", value: "Item Out of Stock" },
    { label: "Edit Request Approved", value: "Edit Request Approved" },
    { label: "Dispatched Pending", value: "Dispatched Pending" },
    { label: "Dispatched", value: "Dispatched" },
    { label: "Completed", value: "Completed" },
    { label: "Delivered", value: "Delivered" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Payment Under Verification", value: "Payment Under Verification" },
    { label: "Pending", value: "Pending" },
    { label: "Uploaded", value: "Uploaded" },
    { label: "Reupload", value: "Reupload" },
    { label: "OrderReturn", value: "OrderReturn" },
  ]
  console.log("options", options1);
  const handleDispatchmanagerSelection = (optionis) => {
    setSelectedDispatchmanager(optionis.value);
  };
  const handleDispatchmanagerRejection = () => {
    setSelectedDispatchmanager(null);
  };



  const getallinvoicedata = async () => {
    setLoading(true)
    try {
      const payload = {
        managerId: parseInt(managerId),
        keyword: keyword1 || '',
        invoiceStatus: invoicestatus,
        sortKey: selectedSortby ? selectedSortby : "",
        ordermanagerId: selectedordermanager,
        dispatchManagerId: selecteddispatchmanager,
        day: daysTab ? daysTab : '',
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      }
      console.log("azhar", payload);

      const res = await fetchData(`invoice/allInvoices?page=${page}&limit=20`, payload);
      if (res.status === "success") {
        setLoading(false)

        setData(res)

        setPage(res?.page);
        setPages(res?.pages);
        setStatusData(res?.orders)
        setOrdermanager(res?.managers?.ordermanagers);
        setDispatchmanager(res?.managers?.dispatchmanagers);

      }

    } catch (error) {
      console.log(error);

    }
    finally{
      setLoading(false)

    }
  }

  useEffect(() => {
    getallinvoicedata()
  }, [daysTab, startDate, endDate, page, keyword1, selectedordermanager, selecteddispatchmanager, invoicestatus, selectedSortby])




  useEffect(() => {
    const filtered = data?.data?.map(item => {
      const statusObj = { status: item.status };
      const invoiceStatusObj = { invoiceStatus: item.invoiceStatus };

      return [statusObj, invoiceStatusObj];
    }).flat();

    setFilteredData(filtered);
  }, [data]);

  console.log("Filtered Data:", filteredData);


  const handleSortbySelection = (option) => {
    setSelectedSortby(option?.label);
  };

  const handleSortbyRejection = () => {
    setSelectedSortby(null);
  };
  const getStatusColor = (invoiceStatus) => { 
    console.log("ayyy",invoiceStatus);
    
    switch (invoiceStatus) {
      case "ordered":
      case "Packed":
      case "success":
        return "green";
        case "Pending":
          return "#FF9B04";

          case "Uploaded":
            return "#4164E3";
            case "Cancelled":
              return "red";
              case "Reupload":
                return "#34A853";
      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Request For Cancel":
        return "red";

      case "Packing In Progress":
      case "Draft":
      case "In Progress":
      case "Payment Pending":
        return "red";

      case "New Order":
      case "draft":
        return "#7698FF";

      default:
        return "";
    }
  };
   const handleReload= ()=>{
    getallinvoicedata()
   }

  return (
    <React.Fragment>

      <div
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }} className={styles.main} >
        <div className={`${styles.mainCon} ${mode ? styles.mainConDark : styles.mainConLight}`}>
          <div className={styles.order_top}>
            <div className={styles.order_top_left}>
              <TopTabBar text={"Invoice"} />
            </div>
            <div className={styles.order_right}>
              <div>
                <SlidingFilter2
                  itemList={dayOptions.map((item) => item.label)}
                  setFilter={handleDayChange}
                  selectedItem={
                    dayOptions.find((item) => item.value === daysTab)?.label
                  }
                />
              </div>
              <div>

                <InputTypedate
                  text={"From"}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setDaysTab("");
                  }}
                />
              </div>
              <div>

                <InputTypedate
                  text={"To"}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setDaysTab("");
                  }}
                />
              </div>
              <div>
                <Button
                  buttonIcon={<DispatchReloadIcon />}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={handleReload            
                }
                />
              </div>
              <div>
                <Button
                  buttonIcon={<DispatchDownloadIcon />}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                />
              </div>

            </div>
          </div>

          <div
            style={{
              backgroundColor: mode ? "#232529" : "white",
            }} className={styles.carddiv}
          >
            {statusData?.map((status) => (
              <div
                onClick={() => setInvoicestatus(status?.invoiceStatus)}
                key={status.statusText}
                className={`${styles.order_filter} ${mode ? styles.order_filter_dark : ""
                  } ${status.status === status ? styles.order_filter_active : ""
                  }`}

              >

                <DealerStatusCard
                  statusText={status.invoiceStatus}
                  statusItemCount={status.count}
                />
              </div>
            ))}
          </div>

          <div
            className={styles.search}>
            <div className={styles.searchcontainer}>
              <SearchBar

                placeholder={"Search by Order ID"}
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)} />
            </div>
            <div className={styles.dropdowncontainer}>
              <DropDown
                label={" Order Manager"}
                listLabel={"All"}
                options={options}
                onSelect={handleOredermanagerSelection}
                onReject={handleOredermanagerRejection}
              />
              <DropDown
                label={" Dispatch  Manager"}
                listLabel={"All"}
                options={options1}
                onSelect={handleDispatchmanagerSelection}
                onReject={handleDispatchmanagerRejection}
              />

              <DropDown
                label={"Sort By"}
                listLabel={"All"}
                options={option3}
                onSelect={handleSortbySelection}
                onReject={handleSortbyRejection}
              />
              <div className={styles.sendbtn}><Button
                buttonText={"Send Data"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonIcon={< Shareinvoice />}
              /></div>
            </div>
          </div>


          {/* that also scroll */}
          <div className={styles.tablemain} > 
            <ul className={styles.order_data_list}>
            <li
              className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                }`}
            >

              {orderHeader.map((h) => (
                <>
                  <div className={styles.entry} key={h} >

                    {
                      h === 'check' ?
                        <div >    <label className={styles.cl_checkbox}>
                          <input
                            type="checkbox"

                          />
                          <span></span>
                        </label></div>
                        : h
                    }

                  </div>
                </>
              ))}

            </li>
            <div className={styles.order_data_list_container}>
              {loading ?
                <div style={{ margin: "1rem auto" }}>
                  <Spinner height={80} width={80} />
                </div>
                :
                data?.data?.length === 0 ? (
                  <img
                    src="/assets/updatedAssets/noDATA.png"
                    alt="img"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      margin: "1rem auto",
                    }}
                  />
                ) :
                  <>
                    {data?.data?.map((d, index) => (
                      <li
                        key={index}
                        className={`${styles.order_data_list_row} ${mode ? styles.order_data_list_row_dark : ""
                          }`}
                        onClick={() => {
                          navigate('/invoices/invoice?orderId=' + d.orderId)
                        }}
                        >
                        <div className={styles.entry}>    <label className={styles.cl_checkbox}>
                          <input
                            type="checkbox" />
                          <span></span>
                        </label></div>
                        <div className={styles.entry}>
                          {d.storeName + ", " + d.storeName}
                        </div>
                        <div className={styles.entry}>{d.orderDate}</div>
                        <div className={styles.entry}  >{d.status}</div>
                        <div className={styles.entry}>{d.orderId}</div>
                        <div className={styles.entry}>{d.totalAmount}</div>
                        <div 
                          className={styles.entry}
                          style={{ color: getStatusColor(d?.invoiceStatus) }}
                        >
                          {d.invoiceStatus}
                        </div>
                        <div className={styles.entry}>{d.lastAction}</div>
                      </li>
                    ))}
                  </>
              }
            </div>


          </ul>
          </div>

          <div className={styles.mgx_pagination} style={{ backgroundColor: mode ? "#232529" : "#fff" }} >
            <div style={{ width: "50%" }}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  fontSize: "14px",
                }}
              >
                <GoToPage
                  currentPage={page}
                  setCurrentPage={setPage}
                  totalPages={pages}
                  key={"1"}
                />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "12px",
              }}
            >
              <Pagenation
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
        </div>
      </div>



    </React.Fragment>
  )
}

export default Invoices
