import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  NewEyeIcon,
  RemoveIcon,
  SaveAsDraftIcon,
  SaveIcon,
  TestTopAddDealerIcon,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import styles from "./PurchaseOrder.module.css";
import PurchaseNewItemList from "../../components/updatedComponents/OrderManager/PurchaseNewItemList";
import PurchaseItemList from "../../components/updatedComponents/OrderManager/PurchaseItemList";
import React, { useState } from "react";

const ProductionTransfer = () => {
  const { managerName, mode, managerId } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get("orderId");

  const [itemsdetails, setItemsdetails] = useState([
    {
      itemNo: "",
      price: "",
      product_name: "",
      thumbnail: "",
      totalQty: "",
      qty: "",
      unit: "",
      rate: "",
      amount: "",
      warehouse: "",
    },
  ]);

  const productionTransferHeader = [
    "S.No",
    "Item No",
    "Product",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];
  return (
    <div className={styles.production}>
      <div className={styles.scroll_wrapper}>
        <form action="" className={styles.form}>
          <VerticalDropDown2
            height={"37px"}
            fontSize={"12px"}
            dropDownText={"Auto"}
            label={"Production Manager"}
            //   value={}
            //   options={}
            //   onchange={}
            dropDownOpen={true}
            //   disableOpen={}
          />
          <VerticalDropDown2
            height={"37px"}
            fontSize={"12px"}
            dropDownText={"Auto"}
            label={"Transfer Warehouse"}
            //   value={}
            //   options={}
            //   onchange={}
            //   dropDownOpen={true}
            //   disableOpen={}
          />
          <VerticalDropDown2
            height={"37px"}
            fontSize={"12px"}
            dropDownText={"Auto"}
            label={"Production Warehouse"}
            //   value={}
            //   options={}
            //   onchange={}
            //   dropDownOpen={true}
            //   disableOpen={}
          />
        </form>

        <ul className={styles.p_table}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <li
              className={`${styles.p_table_header} ${
                mode ? styles.p_table_header_dark : ""
              }`}
            >
              {productionTransferHeader.map((h, index) => (
                <div
                  className={`${styles.entry_2} ${
                    h === "Item No" ? styles.item_no : ""
                  }`}
                  key={`${h}-${index}`}
                >
                  {h}
                </div>
              ))}
            </li>
            {!orderId && itemsdetails.length > 1 && (
              <div style={{ cursor: "pointer", visibility: "hidden" }}>
                <RemoveIcon />
              </div>
            )}
          </div>
          <div className={styles.p_table_list}>
            <>
              {itemsdetails.map((item, index) => (
                <React.Fragment key={index}>
                  {orderId ? (
                    <PurchaseItemList index={index} mode={mode} item={item} />
                  ) : (
                    <p style={{ textAlign: "center" }}>More to come</p>
                    //   <PurchaseNewItemList
                    //     index={index}
                    //     mode={mode}
                    //     item={item}
                    //     managerId={managerId}
                    //     itemInpRefs={(el) => (itemInpRefs.current[index] = el)}
                    //     itemsdetailslength={itemsdetails.length}
                    //     onSelectItem={(item) => {
                    //       handleSelectItem(item, index);
                    //     }}
                    //     addItem={() => handleAddClick()}
                    //     removeItem={() => handleRemoveClick(index)}
                    //     showItemSearch={
                    //       showItemSearch && focusedItemIndex === index
                    //     }
                    //     setShowItemSearch={setShowItemSearch}
                    //     focusedIndex={focusedItemIndex}
                    //     onFocus={() => handleItemInputFocus(index)}
                    //     onBlur={handleItemInputBlur}
                    //     onCompletion={() => handleSubmit()}
                    //     warehouse_id={generalInfo.warehouse_id}
                    //     openAddItemModal={openAddItemModal}
                    //     quantityRefs={quantityRefs.current}
                    //   />
                  )}
                </React.Fragment>
              ))}
            </>
          </div>
          {!orderId && (
            <div
              className={styles.cricle_btn}
              // onClick={() => {
              //   handleAddClick();
              // }}
            >
              <TestTopAddDealerIcon color="#4164E3" />
            </div>
          )}
        </ul>
      </div>
      <div className={`${styles.footer} ${mode ? styles.footer_dark : ""}`}>
        <div className={styles.footer_right}>
          {orderId ? (
            <div>
              <Button
                buttonIcon={<NewEyeIcon />}
                buttonText={"View Invoice"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                // buttonClick={() =>
                //   navigate("/orders/purchaseinvoice/" + orderId)
                // }
              />
            </div>
          ) : (
            <div>
              <Button
                buttonIcon={<SaveAsDraftIcon />}
                buttonText={"Save as Draft"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
              />
            </div>
          )}
          <div
            className={`${styles.footer_right_1} ${
              mode ? styles.footer_right_1_dark : ""
            }`}
          >
            <div>
              <p>Sub Total:</p>
              <p>₹</p>
            </div>
            <div>
              <p>Shipping Charges:</p>
              <p>₹</p>
            </div>
            <div>
              <p>Total Amount(INR):</p>
              <p>₹</p>
            </div>
          </div>
          {!orderId && (
            <div className={styles.footer_right_2}>
              <Button
                buttonIcon={<SaveIcon />}
                buttonText={"Save"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#FFFFFF"}
                buttonBorder={"none"}
                // buttonClick={() => {
                //   if (verifyEntries()) {
                //     handleSubmit();
                //   }
                // }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionTransfer;
