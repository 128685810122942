import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import styles from "../.../../../../pages/Home.module.css";
import { LogInBtnIcon } from "../../icons/Icon";
import { PinSetupIcon } from "../../icons/Icon2";

const AdminOtpverify = ({
  loading,
  pin,
  setPin,
  setConfirmPin,
  confirmPin,
  handleClick,
}) => {
  const { mode } = useAuth();

  const [error, setError] = useState("");
  const pinRefs = useRef([]);
  const confirmPinRefs = useRef([]);
  const [showPin, setShowPin] = useState(new Array(6).fill(false));
  const [showConfirmPin, setShowConfirmPin] = useState(
    new Array(6).fill(false)
  );

  const handlePinChange = (value, index, type) => {
    if (value.length > 1 || !/^\d$/.test(value)) return;

    const updatedPin = type === "pin" ? [...pin] : [...confirmPin];
    updatedPin[index] = value;

    if (type === "pin") setPin(updatedPin);
    else setConfirmPin(updatedPin);

    const updatedShowPin = type === "pin" ? [...showPin] : [...showConfirmPin];
    updatedShowPin[index] = true;

    if (type === "pin") setShowPin(updatedShowPin);
    else setShowConfirmPin(updatedShowPin);

    setTimeout(() => {
      const refs = type === "pin" ? pinRefs.current : confirmPinRefs.current;
      if (value && index < 5) {
        refs[index + 1].focus();
      } else if (value && index === 5 && type === "pin") {
        confirmPinRefs?.current[0]?.focus();
      }
    }, 100);

    setTimeout(() => {
      updatedShowPin[index] = false;
      if (type === "pin") setShowPin(updatedShowPin);
      else setShowConfirmPin(updatedShowPin);
    }, 100);
  };

  const handleKeyDown = (e, index, type) => {
    const refs = type === "pin" ? pinRefs.current : confirmPinRefs.current;

    if (e.key === "Backspace") {
      const updatedPin = type === "pin" ? [...pin] : [...confirmPin];
      updatedPin[index] = "";

      if (type === "pin") setPin(updatedPin);
      else setConfirmPin(updatedPin);

      if (index > 0) refs[index - 1].focus();

      if (type === "confirmPin" && index === 0) {
        pinRefs.current[5].focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pinValue = pin.join("");

    if (pinValue.length !== 6) {
      setError("PIN must be exactly 6 digits.");
      return;
    }
    // if (pinValue !== confirmPinValue) {
    //     setError('PIN and Confirm PIN must match.');
    //     return;
    // }
    setError("");
    handleClick(); // Add your submission logic here
  };

  useEffect(() => {
    const input = pinRefs.current[0];
    const handleFocus = (event) => {
      event.preventDefault();
      input.focus();
    };
    input.addEventListener("focus", handleFocus);

    return () => {
      input.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <div
      className={styles.login_container}
      style={mode ? { backgroundColor: "#2C2E33" } : {}}
    >
      <div
        className={styles.login1}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <div className={styles.sub_Login}>
          <div className={styles.login_Header}>
            <div className={styles.login_Header_Text}>
              <h2 style={mode ? { color: "#ffffff" } : {}}>Login With Otp </h2>
              <p style={mode ? { color: "#9C9797" } : {}}>
                Enter the OTP sent to your registered mobile number to log in.
              </p>
            </div>
            <div>
              <PinSetupIcon />
            </div>
          </div>
          <form className={styles.login_Form} onSubmit={handleSubmit}>
            <div className={styles.loginFormconintener}>
              <div className={styles.input_group}>
                <label
                  className={styles.input_Tage_lable}
                  style={mode ? { color: "#ffffff" } : {}}
                >
                  OTP
                </label>
                <div className={styles.pin_container}>
                  {pin.map((digit, index) => (
                    <input
                      key={index}
                      type="tel"
                      autoFocus={index === 0 ? true : false}
                      maxLength={1}
                      value={digit}
                      onChange={(e) =>
                        handlePinChange(e.target.value, index, "pin")
                      }
                      onKeyDown={(e) => handleKeyDown(e, index, "pin")}
                      className={`${styles.pin_input}`} // Remove invalid style condition
                      ref={(el) => (pinRefs.current[index] = el)}
                      style={{
                        backgroundColor: mode ? "#1B1D21" : "#fff",
                        color: mode ? "#fff" : "#000",
                        border: mode ? "none" : "1px solid #E4E7EB",
                      }}
                    />
                  ))}
                </div>
              </div>
              {/* <div className={styles.input_group} style={{ marginTop: "20px" }} >
                                <label className={styles.input_Tage_lable} style={mode ? { color: "#ffffff" } : {}}>Confirm PIN</label>
                                <div className={styles.pin_container}>
                                    {confirmPin.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handlePinChange(e.target.value, index, 'confirmPin')}
                                            onKeyDown={(e) => handleKeyDown(e, index, 'confirmPin')}
                                            className={`${styles.pin_input}`}  // Remove invalid style condition
                                            ref={(el) => (confirmPinRefs.current[index] = el)}
                                            style={{ backgroundColor: mode ? "#1B1D21" : "#fff", color: mode ? "#fff" : "#000", border: mode ? "none" : "1px solid #E4E7EB" }}
                                        />
                                    ))}
                                </div>
                            </div> */}
              {error && <div className={styles.error_message}>{error}</div>}
              <button
                type="submit"
                disabled={loading}
                className={styles.login_button}
                style={{ marginTop: "20px" }}
              >
                {loading ? (
                  <>
                    <span className={styles.spinner}></span>
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    <LogInBtnIcon />
                    <p>Login</p>
                  </>
                )}
              </button>
            </div>
          </form>
          <div style={{ marginTop: "40px" }} />
        </div>
      </div>
    </div>
  );
};

export default AdminOtpverify;
