import styles from "../../../pages/ordermanager/PurchaseOrder.module.css";

const PurchaseItemList = ({ mode, index, item }) => {
  return (
    <>
      <li
        className={`${styles.p_table_row} ${
          mode ? styles.p_table_row_dark : ""
        }`}
      >
        <div className={styles.entry_2}> {index + 1} </div>
        <div className={styles.entry_2}>{item.itemNo}</div>
        <div className={styles.entry_2}> {item.product_name} </div>
        <div className={styles.entry_2}>{item.qty}</div>
        <div className={styles.entry_2}> {item.unit}</div>
        <div className={styles.entry_2}> {item.price}</div>
        <div className={styles.entry_2}> {item.price * item.qty}</div>
      </li>
    </>
  );
};

export default PurchaseItemList;

// eslint-disable-next-line no-lone-blocks
{
  /* <tr>
        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {index + 1}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.itemNo}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.product_name}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.qty}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.unit}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.price}
        </td>

        <td
          className={`${styles.table_cell} ${
            mode ? styles.table_cell_dark : ""
          }`}
        >
          {item.price * item.qty}
        </td>
      </tr> */
}
