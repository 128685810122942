import { TestTopAddDealerIcon } from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import styles from "./Inventory.module.css";
import { useAuth } from "../../components/context/Auth";
import { useEffect, useState } from "react";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import AddTaxRateModal from "./TaxRateModal";

const TaxRate = () => {
  const { mode, managerId } = useAuth();
  const [taxRates, setTaxRates] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successCounter, setSuccessCounter] = useState(0);

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSuccess = () => {
    setSuccessCounter((prevCounter) => prevCounter + 1);
    console.log("Tax rate operation was successful!");
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      searchKeyword: searchKeyword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const getTaxData = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_URL}inventory/allTaxRate?page=1`,
          requestOptions
        );

        const data = await res.json();
        if (data.status === "success") {
          setLoading(false);
          setTaxRates(data.data);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching tax data:", error);
      }
    };

    getTaxData();
  }, [managerId, searchKeyword, successCounter]);

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <TopTabBar text={" Product / Tax Rate"} />
          </div>

          <div className={styles.tax_rate_btn}>
            <Button
              buttonIcon={<TestTopAddDealerIcon />}
              buttonText={"Create Tax Rate"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                handleOpenModal(
                  <AddTaxRateModal
                    handleCloseModal={handleCloseModal}
                    mode={mode}
                    managerId={managerId}
                    type={"create"}
                    onSuccess={handleSuccess}
                  />
                );
              }}
            />
          </div>
          <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal}>
            {modalContent}
          </ModalWrapper>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar
            placeholder={"Search by Tax ID"}
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />
        </div>
        <div className={styles.inventory_table_container}>
          <ul className={styles.responsiveTable}>
            <li
              className={styles.tableHeader}
              style={
                mode
                  ? {
                      backgroundColor: "#232529",
                      color: "#fafafa",
                    }
                  : { backgroundColor: "#D9DCE5", color: "#646B88" }
              }
            >
              <div className="col">Status</div>
              <div className="col">Tax ID</div>
              <div className="col">Name</div>
              <div className="col">Rate</div>
            </li>

            {loading ? (
              <div className={styles.collection_container}>
                <Spinner />
              </div>
            ) : (
              <div className={styles.inventory_data_container}>
                {taxRates.map((t, index) => (
                  <li
                    key={index}
                    className={styles.tableHeader}
                    style={
                      mode
                        ? {
                            backgroundColor: "#1B1D21",
                            color: "#fafafa",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                    }
                    onClick={() => {
                      handleOpenModal(
                        <AddTaxRateModal
                          handleCloseModal={handleCloseModal}
                          mode={mode}
                          managerId={managerId}
                          type={"edit"}
                          taxRate={t}
                          onSuccess={handleSuccess}
                        />
                      );
                    }}
                  >
                    <div
                      className="col"
                      style={{
                        color: t.status === "Active" ? "#34A853" : "#F93E3E",
                      }}
                    >
                      {t.status}
                    </div>
                    <div className="col">{t.taxRateId}</div>
                    <div className="col">{t.name}</div>
                    <div className="col">{t.value}%</div>
                  </li>
                ))}
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TaxRate;
