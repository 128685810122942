import React, { useState } from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";
import { NavbarSearchIcon } from "../../icons/Icon";

const ToggleSearchBar = ({
  onChange,
  value,
  placeholder,
  type,
  maxLength,
  onToggle,
}) => {
  const { mode } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = (newState) => {
    setIsExpanded(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div
      className={`${styles.t_s_con} ${
        isExpanded ? styles.expanded : styles.collapsed
      } ${mode ? styles.t_s_con_dark : {}}`}
    >
      <div
        onClick={() => toggleExpanded(!isExpanded)}
        className={`${styles.t_s_Button}`}
        aria-label="Open Search"
      >
        <NavbarSearchIcon color={mode ? "#ffffff" : "#646B88"} />
      </div>
      {isExpanded && (
        <input
          type={type ? type : "text"}
          placeholder={placeholder}
          className={`${styles.t_s_inp} ${isExpanded ? "" : styles.hidden}  ${
            mode ? styles.t_s_inp_dark : {}
          }`}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
          autoFocus
          // onBlur={() => toggleExpanded(false)}
        />
      )}
    </div>
  );
};

export default ToggleSearchBar;

// import React, { useState } from "react";
// import styles from "./FromComponent.module.css";
// import { useAuth } from "../../context/Auth";
// import { NavbarSearchIcon } from "../../icons/Icon";

// const ToggleSearchBar = ({ onChange, value, placeholder, type, maxLength }) => {
//   const { mode } = useAuth();
//   const [isExpanded, setIsExpanded] = useState(false);

//   return (
//     <div
//       className={`${styles.t_s_con} ${
//         isExpanded ? styles.expanded : styles.collapsed
//       } ${mode ? styles.t_s_con_dark : {}}`}
//     >
//       <div
//         onClick={() => setIsExpanded(!isExpanded)}
//         className={`${styles.t_s_Button}`}
//         aria-label="Open Search"
//       >
//         <NavbarSearchIcon color={mode ? "#ffffff" : "#646B88"} />
//       </div>
//       {isExpanded && (
//         <input
//           type={type ? type : "text"}
//           placeholder={placeholder}
//           className={`${styles.t_s_inp} ${isExpanded ? "" : styles.hidden}  ${
//             mode ? styles.t_s_inp_dark : {}
//           }`}
//           onChange={onChange}
//           value={value}
//           maxLength={maxLength}
//           autoFocus
//           onBlur={() => setIsExpanded(false)}
//         />
//       )}
//     </div>
//   );
// };

// export default ToggleSearchBar;
