import React, { useEffect, useState } from 'react'

import styles from "./Invoices.module.css"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import Spinner from '../../components/updatedComponents/FormComponents/Spinner';

const UploadInvoice = () => {
  const { mode} = useAuth();

    const { managerId } = useAuth()
    const [searchparams] = useSearchParams()
    const orderId = searchparams.get("orderId")
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
        const navigate = useNavigate();
    
    const orderHeader = [
        "File",
        "Date",
        "Order ID",
        "Amount",
        "Status",
        "Last Action",
      
    ];

    useEffect(() => {
        if (orderId && managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "invoice/getInvoicePdf", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setData(result.data)

                        setLoading(false)
                    }
                   
                })
                .catch(error => console.log('error', error));
        }

    }, [orderId, managerId])

    return (
        <React.Fragment>
               <div
                style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }} className={styles.main} >
                <div className={`${styles.mainCon} ${mode ? styles.mainConDark : styles.mainConLight}`}>
                    <div className={styles.order_top}>
                        <div className={styles.order_top_left}>
                            <TopTabBar text={"Sale Invoice"} />
                        </div>

                    </div>
            
   
                       <div className={styles.tablemain}><ul className={styles.order_data_list}>
                        <li
                            className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                                }`}>

                            {orderHeader.map((h) => (
                                <div className={styles.entry} key={h}>
                                    {h}
                                </div>
                            ))}

                        </li>
                        <div className={styles.order_data_list_container}>
                            {loading ?
                                <div style={{ margin: "1rem auto" }}>
                                    <Spinner height={80} width={80} />
                                </div>
                                :
                                data?.data?.length === 0 ? (
                                    <img
                                        src="/assets/updatedAssets/noDATA.png"
                                        alt="img"
                                        style={{
                                            height: "12rem",
                                            width: "16rem",
                                            margin: "1rem auto",

                                        }}
                                    />
                                ) :
                                    <>
                                        {data?.map((d, i) => (
                                            <li style={{cursor:"pointer"}}
                                                key={i}
                                                className={`${styles.order_data_list_row2} ${mode ? styles.order_data_list_row_dark : ""
                                                    }`}
                                                    onClick={() => window.open(d.invoice && process.env.REACT_APP_S3URL0 + d.invoice, "mozillaWindow", "popup")}
                                            >
                                                <div className={styles.entry}>
                                                     {d.invoice}
                                                </div>
                                                <div  className={styles.entry}>{d.invoiceDate}</div>
                                                <div className={styles.entry}>{d.orderId}</div>
                                                <div className={styles.entry}>{d.amount}</div>
                                                <div className={styles.entry}>{d.status}</div>
                                                <div className={styles.entry}>{d.lastAction}</div>
                                                {/* <div
                                                    className={styles.entry}

                                                >
                                                    {d.price}
                                                </div> */}
                                                {/* <div className={styles.entry}>{d.qty * d.price}</div> */}
                                            </li>
                                        ))}
                                    </>
                            }
                        </div>
                    </ul></div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default UploadInvoice



// import React, { useEffect, useState } from 'react'
// import styles from "./Invoices.module.css"
// import { useSearchParams } from 'react-router-dom';
// import { useAuth } from '../../components/context/Auth';

// const UploadInvoice = () => {

//     // const navigate = useNavigate();
//     const { managerId } = useAuth()
//     const [searchparams] = useSearchParams()
//     const orderId = searchparams.get("orderId")
//     const [data, setData] = useState([])
//     const [loading, setLoading] = useState(false)

//     useEffect(() => {
//         if (orderId && managerId) {
//             setLoading(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "orderId": orderId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "invoice/getInvoicePdf", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === 'success') {
//                         setData(result.data)
//                         setLoading(false)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }

//     }, [orderId, managerId])

//     return (
//         <React.Fragment>
//             <div className={styles.right_main}>
//                 <div className={styles.dispatchDashboard_header}>
//                     <h1 className={`${styles.dispatchHeading} mt-0`}>Uploaded Invoices</h1>
//                 </div>
//                 {
//                     loading ? <div className={'order_loadingMain'}>
//                         <img src='/wallicon.gif' alt='walliconGIF' />
//                     </div> :
//                         <>
//                             {data.length !== 0 ?
//                                 <div className={styles.dispatchOrders_height}>
//                                     <table className={styles.itemTable}>
//                                         <thead>
//                                             <tr className={styles.item}>
//                                                 <th>File</th>
//                                                 <th>Date</th>
//                                                 <th>Order Id</th>
//                                                 <th>Amount</th>
//                                                 <th>Status</th>
//                                                 <th>Last Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {data && data.map((val, index) =>
//                                                 <tr key={index}
//                                                     className={`${styles.item} ${val.reqStatus === "Pending" ? 'text-danger' : ""}`}
//                                                     onClick={() => window.open(val.invoice && process.env.REACT_APP_URL + val.invoice, "mozillaWindow", "popup")}
//                                                     title={val.reqStatus === "Pending" ? val.reqIssue : ""}
//                                                 >
//                                                     <td>{val.invoice} </td>
//                                                     <td>{val.invoiceDate} </td>
//                                                     <td>{val.orderId} </td>
//                                                     <td>{val.amount}</td>
//                                                     <td className={`${val.status === 'Dispatched' || val.status === 'Packed'||val.status === 'Uploaded' ?
//                                                         styles.table_txt_green : val.status === 'Payment Pending'|| val.status === 'Canceled' ?
//                                                             styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending'|| val.status === 'Payment Pending') ?
//                                                                 styles.table_txt_yellow : val.status === 'New Order' ?
//                                                                     styles.table_txt_blue : ''}`}>
//                                                         {val.status}
//                                                     </td>
//                                                     <td>{val.lastAction}</td>
//                                                 </tr>
//                                             )}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                                 :
//                                 <div className={styles.noData}>
//                                     No Data Found !
//                                 </div>
//                             }
//                         </>
//                 }
//             </div>
//         </React.Fragment>
//     )
// }

// export default UploadInvoice