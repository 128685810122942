import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useCallback,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  ArowUpIcon,
  EditManagerIcon,
  NavbarArrowDownIcon,
  NavbarFullScreenIcon,
  CancelIcon2,
  SaveTaxRateIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";

import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const TopTabBar = lazy(() =>
  import("../../components/updatedComponents/Tanolet/TopTabBar.jsx")
);
const SearchBar = lazy(() =>
  import("../../components/updatedComponents/FormComponents/SearchBar.jsx")
);
const SlidingFilter2 = lazy(() =>
  import("../../components/updatedComponents/Dealers/SlidingFilter2")
);
const TextElement = lazy(() =>
  import("../../components/updatedComponents/FormComponents/TextElement")
);
const Button = lazy(() =>
  import("../../components/updatedComponents/Dealers/Button")
);
const Spinner = lazy(() =>
  import("../../components/updatedComponents/FormComponents/Spinner")
);
const MultiSelectDropdown = lazy(() =>
  import(
    "../../components/updatedComponents/FormComponents/MultiSelectDropdown.jsx"
  )
);
const ManagerModal = lazy(() =>
  import("../../components/updatedComponents/Manager/ManagerModal")
);
const ModalWrapper = lazy(() =>
  import("../../components/updatedComponents/layoutWrapper/ModalWrapper")
);
const RenderRadioButtonGroup = lazy(() =>
  import("./RenderRadioButtonGroup.jsx")
);
const RenderInput = lazy(() => import("./RenderInput.jsx"));
const RenderMultiSelectField = lazy(() =>
  import("./RenderMultiSelectField.jsx")
);

const CollectionInfo = () => {
  const { managerId, mode } = useAuth();
  const { show } = useToast();
  const params = useParams();
  const liRefs = useRef([]);
  const [searchparams] = useSearchParams();
  const product_id = searchparams.get("product_id");
  const collection_id = searchparams.get("collection_id");
  const collection_name = params.collection;
  const product_name = params.product;
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [wholesaleTabs, setWholesaleTabs] = useState("Inventory");
  const [showDropdown, setShowDropdown] = useState(null);
  const [openAll, setOpenAll] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taxRates, setTaxRates] = useState([]);
  const [itemSearch, setItemSearch] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [itemUpdated, setItemUpdated] = useState(false);
  const [isUpdateAll, setIsUpdateAll] = useState(false);
  const [updateAllItems, setUpdateAllItems] = useState({
    description: "",
    hscode: "",
    alias: "",
    weight: "",
    vm: "",
    unit: "",
    dmrp: "",
    dp: "",
    odp: "",
    outsourcedItem: "",
    outSourceItemlimit: "",
    productionItem: "",
    sellCondition: [],
    taxRate: [],
  });

  const fetchData = useCallback(async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      product_id: product_id,
      collection_id: collection_id,
      sortBy: wholesaleTabs.toLowerCase(),
      searchKeyword: itemSearch,
      warehouses:
        selectedWarehouses?.length !== 0
          ? selectedWarehouses.map((w) => ({ warehousename: w }))
          : [],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_URL + "inventory/items",
        requestOptions
      );
      const result = await response.json();

      setAllItems(result.data);
      setWarehouses(result.warehouses);
      if (wholesaleTabs.toLowerCase() === "items") {
        setTaxRates(result.taxRate);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    collection_id,
    itemSearch,
    managerId,
    product_id,
    selectedWarehouses,
    wholesaleTabs,
  ]);

  useEffect(() => {
    if (!managerId) return;

    const delayDebounceFn = setTimeout(() => {
      if (itemSearch || selectedWarehouses.length > 0 || collection_id) {
        setLoading(true);
        fetchData();
      }
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [
    collection_id,
    fetchData,
    itemSearch,
    managerId,
    product_id,
    selectedWarehouses,
    wholesaleTabs,
    itemUpdated,
  ]);

  const handleWarehouseChange = (updatedOptions) => {
    setSelectedWarehouses(updatedOptions);
  };

  const handleFieldChange = (index, field, value) => {
    if (field === "hscode" && value.length > 8) {
      show("Hs code must be less than 8 characters!", "warning");
    }
    if (field === "vm" && /\.\d{5,}$/.test(String(value).trim())) {
      show("Volume can only have up to 4 digits after the decimal!", "warning");
    }
    if (field === "unit" && value === "Select") {
      show("Please select a unit!", "warning");
    }
    const updatedItemsCopy = [...allItems];

    let filteredTaxRates = [];

    if (field === "taxRate") {
      filteredTaxRates = taxRates
        .filter((taxRate) => value.includes(taxRate.name))
        .map((taxRate) => ({
          taxRateId: taxRate.taxRateId,
          name: taxRate.name,
          valuev: taxRate.value.toString(),
        }));
      updatedItemsCopy[index] = {
        ...updatedItemsCopy[index],
        [field]: filteredTaxRates,
      };
    } else {
      updatedItemsCopy[index] = { ...updatedItemsCopy[index], [field]: value };
    }

    setAllItems(updatedItemsCopy);

    const itemToUpdate = updatedItemsCopy[index];
    const isItemUpdated = updatedItems.find(
      (item) => item.itemNo === itemToUpdate.itemNo
    );

    if (isItemUpdated) {
      setUpdatedItems((prevUpdatedItems) =>
        prevUpdatedItems.map((item) =>
          item.itemNo === itemToUpdate.itemNo
            ? {
                ...item,
                [field]: field === "taxRate" ? filteredTaxRates : value,
              }
            : item
        )
      );
    } else {
      setUpdatedItems((prevUpdatedItems) => [
        ...prevUpdatedItems,
        {
          ...itemToUpdate,
          [field]: field === "taxRate" ? filteredTaxRates : value,
        },
      ]);
    }
  };

  const handleFieldChangeForAllItems = (field, value) => {
    if (field === "hscode" && value.length > 8) {
      show("Hs code must be less than 8 characters!", "warning");
    }
    if (field === "vm" && /\.\d{5,}$/.test(String(value).trim())) {
      show("Volume can only have up to 4 digits after the decimal!", "warning");
    }
    if (field === "unit" && value === "Select") {
      show("Please select a unit!", "warning");
    }
    setUpdateAllItems((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    const updatedItems = allItems.map((item) => ({
      ...item,
      [field]:
        field === "taxRate"
          ? taxRates
              .filter((taxRate) => value.includes(taxRate.name))
              .map((taxRate) => ({
                taxRateId: taxRate.taxRateId,
                name: taxRate.name,
                valuev: taxRate.value.toString(),
              }))
          : value,
    }));

    setAllItems(updatedItems);

    setUpdatedItems((prevUpdatedItems) => {
      const updatedItemsMap = new Map();

      prevUpdatedItems.forEach((item) => {
        updatedItemsMap.set(item.itemNo, item);
      });

      updatedItems.forEach((item) => {
        updatedItemsMap.set(item.itemNo, item);
      });

      return Array.from(updatedItemsMap.values());
    });
  };

  const clearUpdateAllItems = () => {
    setUpdateAllItems((prevState) =>
      Object.keys(prevState).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {})
    );
  };

  const handleUpdateItem = async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    // const isValidVm = (value) => {
    //   if (value === null || value === undefined) return false;
    //   return /^\d+(\.\d{1,4})?$/.test(String(value).trim());
    // };

    // const isValidHscode = (value) => {
    //   if (!value) return false;
    //   return String(value).trim().length === 8;
    // };

    // const invalidItems = updatedItems.filter((item) => {
    //   console.log(`Validating VM: '${item.vm}', HSCode: '${item.hscode}'`);
    //   return !isValidVm(item.vm) || !isValidHscode(item.hscode);
    // });

    // if (invalidItems?.length > 0) {
    //   console.error("Validation failed for items:", invalidItems);
    //   show(
    //     "Validation failed: Ensure 'vm' has up to 4 decimal places and 'hscode' is 8 characters long.",
    //     "error"
    //   );
    //   return;
    // }

    const raw = JSON.stringify({
      managerId: managerId,
      warehouses:
        selectedWarehouses?.length !== 0
          ? selectedWarehouses.map((w) => ({ warehousename: w }))
          : [],
      itemsdetails: updatedItems?.length !== 0 ? updatedItems : [],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      await fetch(
        process.env.REACT_APP_URL + "inventory/updateInventory",
        requestOptions
      );
      setLoading(false);
      setEditMode(false);
      if (updateAllItems) {
        show("All Items Updated Successfully", "success");
        setIsUpdateAll(false);
        clearUpdateAllItems();
      } else {
        show("Item Updated Successfully", "success");
      }
      setItemUpdated(true);
    } catch (error) {
      show("Unable To  Update Item(s)", "error");
      console.error("Error during Updating Item:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsUpdateAll(false);
    clearUpdateAllItems();
    setEditMode(false);
  };

  const toggleDropdown = (index) => {
    setOpenAll(false);

    if (showDropdown === index) {
      setShowDropdown(null);
    } else {
      setShowDropdown(index);
    }
  };

  const handleOpenAll = () => {
    const isOpening = !openAll;
    setOpenAll(isOpening);
    setShowDropdown(isOpening ? "all" : null);
  };

  useEffect(() => {
    if (
      allItems?.length > 0 &&
      !editMode &&
      !isUpdateAll &&
      liRefs.current[0]
    ) {
      setIsFocusMode(true);
      liRefs.current[0].focus();
      setFocusedIndex(0);
    }
  }, [allItems, editMode, isUpdateAll]);

  const handleKeyDown = (e, index) => {
    if (!isFocusMode) return;

    if (e.key === "Enter") {
      setShowDropdown(showDropdown === index ? null : index);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (index < allItems?.length - 1) {
        const nextIndex = index + 1;
        setFocusedIndex(nextIndex);
        liRefs.current[nextIndex]?.focus();
        liRefs.current[nextIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (index > 0) {
        const prevIndex = index - 1;
        setFocusedIndex(prevIndex);
        liRefs.current[prevIndex]?.focus();
        liRefs.current[prevIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  // useEffect(() => {
  //   const handleKeyPress = (e) => {
  //     if ((e.altKey || e.metaKey) && e.key.toLowerCase() === "c") {
  //       e.preventDefault();
  //       setFocusedIndex(0);
  //       liRefs.current[0]?.focus();
  //       setIsFocusMode(true);
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyPress);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  // const handleKeyDown = (e, index) => {
  //   if (!isFocusMode) return;

  //   if (e.key === "Enter") {
  //     setShowDropdown(showDropdown === index ? null : index);
  //   } else if (e.key === "ArrowDown") {
  //     if (index < allItems?.length - 1) {
  //       const nextIndex = index + 1;
  //       setFocusedIndex(nextIndex);
  //       liRefs.current[nextIndex]?.focus();
  //       liRefs.current[nextIndex]?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }
  //   } else if (e.key === "ArrowUp") {
  //     if (index > 0) {
  //       const prevIndex = index - 1;
  //       setFocusedIndex(prevIndex);
  //       liRefs.current[prevIndex]?.focus();
  //       liRefs.current[prevIndex]?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }
  //   }
  // };

  const tabChange = (label) => {
    setAllItems([]);
    const selectedFilter = slidingFilterData.find((item) => item === label);
    setWholesaleTabs(selectedFilter || "items");
  };

  const style = mode
    ? {
        backgroundColor: "#232529",
        color: "#fafafa",
        borderColor: "#232529",
      }
    : {
        backgroundColor: "#D9DCE5",
        borderColor: "#D9DCE5",
        color: "#646B88",
      };

  const slidingFilterData = ["Inventory", "Items"];
  const inventoryHeader = [
    "Status",
    "Item",
    "Warehouse",
    "Inventory Type",
    "Weight (Kg)",
    "Volume (CBM)",
    "Opening",
    "Purchase",
    "Sold",
    "Adjust",
    "Closing",
  ];
  const itemsHeader = [
    "Status",
    "Item",
    "Batch No.",
    "Warehouse",
    "Inventory Type",
    "Description",
    "HS Code",
    "Alias",
    "Weight (Kg)",
    "Volume (CBM)",
    "Unit",
    "MRP",
    "Online Price",
    "Offline Price",
  ];
  const sellCondition = [
    { label: "City", value: "City" },
    { label: "State", value: "State" },
    { label: "Country", value: "Country" },
    { label: "Continent", value: "Continent" },
  ];
  const units = [
    { label: "Select", value: "Select" },
    { label: "Roll", value: "Roll" },
    { label: "Pcs", value: "Pcs" },
    { label: "Box", value: "Box" },
    { label: "Sq Meter", value: "Sq Meter" },
    { label: "Meter", value: "Meter" },
    { label: "Sq Feet", value: "Sq Feet" },
    { label: "Feet", value: "Feet" },
    { label: "Kg", value: "Kg" },
  ];

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <Suspense fallback={<div>Loading...</div>}>
            <TopTabBar
              text={`Product / ${product_name} / ${collection_name}`}
            />
          </Suspense>
        </div>

        <div className={styles.collection_header}>
          <div className={styles.collection_header_left}>
            <div className={styles.inventory_search_bar}>
              <Suspense fallback={<div>Loading...</div>}>
                <SearchBar
                  placeholder={"Search by Item"}
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                />
              </Suspense>
            </div>
            <div className={styles.hide}>
              <Suspense fallback={<div>Loading...</div>}>
                <SlidingFilter2
                  itemList={slidingFilterData.map((item) => item)}
                  setFilter={tabChange}
                  selectedItem={slidingFilterData.find(
                    (item) => item === wholesaleTabs
                  )}
                />
              </Suspense>
            </div>
          </div>
          <div className={styles.collection_header_right}>
            <div className={styles.drop_down}>
              <p style={{ color: mode ? "#fff" : "#000" }}>Warehouse</p>
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <MultiSelectDropdown
                    label="All"
                    options={warehouses?.map((w) => ({
                      label: w.warehousename,
                      value: w.warehousename,
                    }))}
                    onChange={handleWarehouseChange}
                    mode={mode}
                    padding={"10px"}
                  />
                </Suspense>
              </div>
            </div>
            {wholesaleTabs === "Inventory" && (
              <div
                style={{ cursor: "pointer" }}
                className={styles.hide}
                onClick={() => handleOpenAll()}
              >
                <NavbarFullScreenIcon color={mode ? "#ffffff" : "#646B88"} />
              </div>
            )}
            {wholesaleTabs === "Items" && !editMode && (
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Button
                    buttonIcon={<EditManagerIcon />}
                    buttonText={"Edit"}
                    buttonBgColor={"#4164E3"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => {
                      setEditMode(true);
                    }}
                  />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                  <Button
                    buttonIcon={<EditManagerIcon />}
                    buttonText={"Edit All"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => {
                      setIsUpdateAll(true);
                      // handleOpenAll();
                      setEditMode(true);
                    }}
                  />
                </Suspense>
              </>
            )}

            {wholesaleTabs === "Items" && editMode && (
              <Suspense fallback={<div>Loading...</div>}>
                <Button
                  buttonIcon={<SaveTaxRateIcon />}
                  buttonText={"SAVE"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Suspense>
            )}

            <Suspense fallback={<div>Loading...</div>}>
              <ModalWrapper
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                noBtn
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <ManagerModal
                    heading={"Update Item"}
                    centerHeading={"Are you sure you want to Update?"}
                    subHeading={"To confirm! type update in the text field."}
                    targetWord={"update"}
                    handleSubmit={() => {
                      handleUpdateItem();
                    }}
                    handleCloseModal={() => {
                      setIsModalOpen(false);
                      clearUpdateAllItems();
                    }}
                  />
                </Suspense>
              </ModalWrapper>
            </Suspense>

            {wholesaleTabs === "Items" && editMode && (
              <Suspense fallback={<div>Loading...</div>}>
                <Button
                  buttonIcon={<CancelIcon2 />}
                  buttonText={"CANCEL"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={handleCancel}
                />
              </Suspense>
            )}
          </div>
        </div>

        <ul
          className={`${styles.responsiveTable} ${styles.hidden_table}`}
          style={{ gap: "5px" }}
        >
          <li
            className={`${styles.collection_list} ${styles.collection_list_FW}`}
            style={style}
          >
            {wholesaleTabs === "Inventory" ? (
              <>
                {inventoryHeader?.map((h) => (
                  <div className="col" key={h}>
                    {h}
                  </div>
                ))}
              </>
            ) : (
              <>
                {itemsHeader?.map((h) => (
                  <div className="col" key={h}>
                    {h}
                  </div>
                ))}
              </>
            )}
          </li>

          <div
            className={styles.inventory_data_container}
            style={{ gap: "5px" }}
          >
            {isUpdateAll && wholesaleTabs !== "Inventory" && (
              <div className={styles.collection_data}>
                <li
                  className={`${styles.collection_list} ${
                    mode ? styles.collection_list_dark : ""
                  }`}
                >
                  <>
                    <div className="col">-</div>
                    <div style={{ fontWeight: "600" }} className="col">
                      -
                    </div>
                    <div className="col">-</div>
                    <div className={styles.collection_item}>-</div>
                    <div className="col">- </div>
                    {["description", "hscode", "alias", "weight", "vm"].map(
                      (i) => (
                        <div className={`col ${styles.warn}`}>
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={updateAllItems[i]}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (i === "vm" && value.startsWith(".")) {
                                value = 0 + value;
                              }
                              handleFieldChangeForAllItems(i, value);
                            }}
                          />
                          {i === "hscode" && <span> *8 digits </span>}
                          {i === "vm" && <span> *4 digits after decimal </span>}
                        </div>
                      )
                    )}
                    <Suspense fallback={<div>Loading...</div>}>
                      <div className="col">
                        <select
                          className={`${styles.unit_dd} ${
                            mode ? styles.unit_dd_dark : ""
                          }`}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (isUpdateAll) {
                              handleFieldChangeForAllItems("unit", value);
                            }
                          }}
                        >
                          {units.map((option, index) => (
                            <>
                              <option
                                key={index}
                                value={option.value}
                                className={`${styles.unit_dd_option} ${
                                  mode ? styles.unit_dd_option_dark : ""
                                }`}
                              >
                                {option.label}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </Suspense>
                    {["dmrp", "dp", "odp"].map((i) => (
                      <div className="col">
                        <input
                          className={
                            mode
                              ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                              : `${styles.collection_list_inp}`
                          }
                          type="text"
                          value={updateAllItems[i]}
                          onChange={(e) =>
                            handleFieldChangeForAllItems(i, e.target.value)
                          }
                        />
                      </div>
                    ))}
                  </>
                </li>

                <div
                  className={`${styles.collection_hidden_Data} ${
                    mode ? styles.collection_hidden_Data_dark : ""
                  }`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <RenderRadioButtonGroup
                        label="Outsourced:"
                        editMode={editMode}
                        options={[
                          { value: "Active", label: "Active" },
                          { value: "Disable", label: "Disable" },
                        ]}
                        wholesaleTabs={wholesaleTabs}
                        mode={mode}
                        value={updateAllItems?.outsourcedItem}
                        onChange={(value) =>
                          handleFieldChangeForAllItems("outsourcedItem", value)
                        }
                      />
                    </Suspense>
                  </div>
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <div className={`col ${styles.update_all_multi}`}>
                        <span>Limit:</span>
                        <input
                          className={
                            mode
                              ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                              : `${styles.collection_list_inp}`
                          }
                          type="number"
                          value={updateAllItems["outSourceItemlimit"]}
                          onChange={(e) =>
                            handleFieldChangeForAllItems(
                              "outSourceItemlimit",
                              e.target.value
                            )
                          }
                        />
                        <span>pcs</span>
                      </div>
                    </Suspense>
                  </div>
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <RenderRadioButtonGroup
                        label="Production Item:"
                        value={updateAllItems?.productionItem}
                        editMode={editMode}
                        options={[
                          { value: "Active", label: "Active" },
                          { value: "Disable", label: "Disable" },
                        ]}
                        wholesaleTabs={wholesaleTabs}
                        mode={mode}
                        onChange={(value) =>
                          handleFieldChangeForAllItems("productionItem", value)
                        }
                      />
                    </Suspense>
                  </div>
                  <div className={styles.update_all_multi}>
                    <span>Sell Condition:</span>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MultiSelectDropdown
                        border={"1px solid #646B88"}
                        mode={mode}
                        label={"Select"}
                        options={
                          sellCondition?.length > 0
                            ? sellCondition.map((s) => ({
                                label: s?.label,
                                value: s?.value,
                              }))
                            : []
                        }
                        // value={updateAllItems?.sellCondition?.map((s) => s)}
                        value={
                          Array.isArray(updateAllItems?.sellCondition)
                            ? updateAllItems?.sellCondition?.map((s) => s)
                            : []
                        }
                        onChange={(selectedValue) =>
                          handleFieldChangeForAllItems(
                            "sellCondition",
                            selectedValue
                          )
                        }
                        width={"124px"}
                        fontSize={"12px"}
                      />
                    </Suspense>
                  </div>

                  <div className={styles.update_all_multi}>
                    <span>Tax Rate:</span>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MultiSelectDropdown
                        border={"1px solid #646B88"}
                        mode={mode}
                        label={"Select"}
                        options={
                          taxRates?.length > 0
                            ? taxRates.map((t) => ({
                                label: t?.name,
                                value: t?.name,
                              }))
                            : []
                        }
                        // value={updateAllItems?.taxRate?.map((t) => t?.name)}
                        value={(updateAllItems?.taxRate || []).map(
                          (t) => t?.name
                        )}
                        onChange={(selectedValue) =>
                          handleFieldChangeForAllItems("taxRate", selectedValue)
                        }
                        width={"124px"}
                        fontSize={"12px"}
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.collection_container}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Spinner />
                </Suspense>
              </div>
            ) : (
              allItems &&
              allItems?.map((item, index) => (
                <div
                  className={`${styles.collection_data}   ${
                    index === focusedIndex ? styles.collection_list_FOCUSED : ""
                  }`}
                  key={index}
                  ref={(el) => (liRefs.current[index] = el)}
                  tabIndex={0}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onClick={() => setFocusedIndex(index)}
                >
                  <li
                    // ref={(el) => (liRefs.current[index] = el)}
                    // onFocus={() => setFocusedIndex(index)}
                    // tabIndex={-1}
                    // onKeyDown={(e) => handleKeyDown(e, index)}
                    className={`${styles.collection_list} ${
                      mode ? styles.collection_list_dark : ""
                    }`}
                    style={{
                      borderRadius:
                        showDropdown === index || showDropdown === "all"
                          ? "5px 5px 0 0"
                          : "5px",
                    }}
                  >
                    {wholesaleTabs === "Inventory" ? (
                      <>
                        <div
                          className="col"
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div style={{ fontWeight: "600" }} className="col">
                          {item.itemNo || ""}
                        </div>
                        <div className={styles.collection_item}>
                          <p>{item.warehouse || ""}</p>
                          <span>{item.warehouse || ""}</span>
                        </div>
                        <div className="col">{item.inventoryType || ""}</div>
                        <div className="col">{item.weight || ""}</div>
                        <div className="col">{item.vm || ""}</div>
                        <div className="col">{item.openings || ""}</div>
                        <div className="col">{item.purchase || ""}</div>
                        <div className="col">{item.sold || ""}</div>
                        <div className="col">{item.adjust || ""}</div>
                        <div className="col">{item.closing || ""}</div>
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                          className="col"
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div style={{ fontWeight: "600" }} className="col">
                          {item.itemNo || ""}
                        </div>
                        <div className="col">{item.batch || ""}</div>
                        <div className={styles.collection_item}>
                          <p>{item.warehouse || ""}</p>
                          <span>{item.warehouse}</span>
                        </div>
                        <div className="col">{item.inventoryType || ""}</div>
                        {["description", "hscode", "alias", "weight", "vm"].map(
                          (i) => (
                            <Suspense fallback={<div>Loading...</div>} key={i}>
                              <RenderInput
                                item={item}
                                index={index}
                                mode={mode}
                                editMode={editMode}
                                wholesaleTabs={wholesaleTabs}
                                handleFieldChange={handleFieldChange}
                                field={i}
                                value={item[i]}
                              />
                            </Suspense>
                          )
                        )}
                        <Suspense fallback={<div>Loading...</div>}>
                          <div className="col">
                            {editMode ? (
                              <select
                                value={item["unit"]}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "unit",
                                    e.target.value
                                  )
                                }
                                className={`${styles.unit_dd} ${
                                  mode ? styles.unit_dd_dark : ""
                                }`}
                              >
                                {units.map((option, index) => (
                                  <option
                                    key={index}
                                    value={option.value}
                                    className={`${styles.unit_dd_option} ${
                                      mode ? styles.unit_dd_option_dark : ""
                                    }`}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              item["unit"]
                            )}
                          </div>
                        </Suspense>
                        {["dmrp", "dp", "odp"].map((i) => (
                          <Suspense fallback={<div>Loading...</div>} key={i}>
                            <RenderInput
                              item={item}
                              index={index}
                              mode={mode}
                              editMode={editMode}
                              wholesaleTabs={wholesaleTabs}
                              handleFieldChange={handleFieldChange}
                              field={i}
                              value={item[i]}
                            />
                          </Suspense>
                        ))}
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </li>

                  {(showDropdown === index || showDropdown === "all") && (
                    <div
                      className={`${styles.collection_hidden_Data} ${
                        mode ? styles.collection_hidden_Data_dark : ""
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderRadioButtonGroup
                            label="Outsourced:"
                            value={item.outsourcedItem}
                            key={`${item.itemNo}${item.warehouse}our_sourced`}
                            name={`${item.itemNo}${item.warehouse}our_sourced`}
                            editMode={editMode}
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Disable", label: "Disable" },
                            ]}
                            onChange={(value) =>
                              handleFieldChange(index, "outsourcedItem", value)
                            }
                            wholesaleTabs={wholesaleTabs}
                            mode={mode}
                          />
                        </Suspense>
                      </div>
                      <div>
                        {wholesaleTabs === "Inventory" ? (
                          <Suspense fallback={<div>Loading...</div>}>
                            <TextElement
                              label={"Recommended Items:"}
                              labelColor={mode ? "#fff" : undefined}
                              valueColor={mode ? "#fff" : undefined}
                              value={
                                item.recommendedItems?.length > 0
                                  ? item.recommendedItems.map((r) => r?.itemNo)
                                  : ""
                              }
                              fontSize={"12px"}
                              fontWeight={"500"}
                            />
                          </Suspense>
                        ) : (
                          <Suspense fallback={<div>Loading...</div>}>
                            <RenderInput
                              label="Limit:"
                              item={item}
                              index={index}
                              mode={mode}
                              editMode={editMode}
                              wholesaleTabs={wholesaleTabs}
                              handleFieldChange={handleFieldChange}
                              field="outSourceItemlimit"
                              value={item.outSourceItemlimit}
                            />
                          </Suspense>
                        )}
                      </div>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderRadioButtonGroup
                            label="Production Item:"
                            value={item.productionItem}
                            key={`${item.itemNo}${item.warehouse}production_item`}
                            name={`${item.itemNo}${item.warehouse}production_item`}
                            editMode={editMode}
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Disable", label: "Disable" },
                            ]}
                            onChange={(value) =>
                              handleFieldChange(index, "productionItem", value)
                            }
                            wholesaleTabs={wholesaleTabs}
                            mode={mode}
                          />
                        </Suspense>
                      </div>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderMultiSelectField
                            label="Sell Condition"
                            value={item.sellCondition.map((s) => s)}
                            fieldName="sellCondition"
                            options={
                              sellCondition?.length > 0
                                ? sellCondition.map((s) => ({
                                    label: s?.label,
                                    value: s?.value,
                                  }))
                                : []
                            }
                            index={index}
                            mode={mode}
                            editMode={editMode}
                            wholesaleTabs={wholesaleTabs}
                            handleFieldChange={handleFieldChange}
                            width={"120px"}
                          />
                        </Suspense>
                      </div>

                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderMultiSelectField
                            label="Tax Rate"
                            value={item.taxRate.map((t) => t?.name)}
                            fieldName="taxRate"
                            options={
                              taxRates?.length > 0
                                ? taxRates.map((t) => ({
                                    label: t?.name,
                                    value: t?.name,
                                  }))
                                : []
                            }
                            index={index}
                            mode={mode}
                            editMode={editMode}
                            wholesaleTabs={wholesaleTabs}
                            handleFieldChange={handleFieldChange}
                            width="120px"
                          />
                        </Suspense>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            <li
              style={{
                color: "#FFF",
                width: "100%",
                padding: "2rem",
                visibility: "hidden",
              }}
            >
              wdawaddwa
            </li>
          </div>
        </ul>

        <div className={styles.item_card_mob_det_con}>
          {loading && (
            <div className={styles.collection_container}>
              <Suspense fallback={<div>Loading...</div>}>
                <Spinner />
              </Suspense>
            </div>
          )}
          {allItems &&
            allItems.map((i, index) => (
              <div
                className={`${styles.item_card_mob} ${
                  mode ? styles.item_card_mob_dark : ""
                }`}
                key={`${i.itemNo}_${index}`}
                style={
                  mode ? { backgroundColor: "#1B1D21", color: "#fff" } : {}
                }
              >
                <div key={`${i.itemNo}_${index}_info`}>
                  <p>{i.itemNo}</p>
                  <p
                    style={{
                      color: i.status === "Active" ? "#34A853" : "#F93E3E",
                    }}
                  >
                    {i.status}
                  </p>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_weight`}
                >
                  <div>
                    <p>Weight</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.weight}
                    </p>
                  </div>
                  <div>
                    <p>Purchase</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.purchase}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_opening`}
                >
                  <div>
                    <p>Opening</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.openings}
                    </p>
                  </div>
                  <div>
                    <p>Closing</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.closing}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_adjust`}
                >
                  <div>
                    <p>Adjust</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.adjust}
                    </p>
                  </div>
                  <div>
                    <p>Sold</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.sold}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_os}
                  key={`${i.itemNo}_${index}_outsourced`}
                >
                  <TextElement
                    label={"Outsourced:"}
                    value={i.outsourcedItem}
                    fontWeight={"400"}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CollectionInfo;
