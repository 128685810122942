// import React, { useState, useEffect } from 'react'
// import { useNavigate,  useSearchParams } from 'react-router-dom'
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
// import { Ban, Checked, Clock, DeleteFile, DeleteIcon, Edit, View } from '../../components/icons/Icon'
// import AddFaq from './AddFaq'
// import AddCollection from './AddCollection'
// import AddStyle from './AddStyle'
// import AddType from './AddType'
// import styles from './Product.module.css'
// import Modal from '../../components/Modal'
// import { useAuth } from '../../components/context/Auth'

// const ViewProduct = ({ setProduct }) => {
//     const { managerId } = useAuth();
//     //  const {id} = useParams()
//     const product_name = window.location.pathname.split("/")[window.location.pathname.split("/")?.length - 1]
//     const url = window.location.href.split("/")[window.location.href.split("/")?.length - 2]
//     const navigate = useNavigate()
//     const [searchParams] = useSearchParams();
//     const getId = searchParams.get("product_id");
//     const product_id = (!getId || getId === null) ? "" : getId
//     const [styleData, setStyleData] = useState([])
//     const [typeData, setTypeData] = useState([])
//     const [collectionData, setCollectionData] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [showDeleteModal, setShowDeleteModal] = useState(false)
//     const [deleteStyleId, setDeleteStyleId] = useState('')
//     const [deleteTrue, setDeleteTrue] = useState(false)
//     const [deleteStyleName, setDeleteStyleName] = useState('')
//     const [showDeleteTypeModal, setShowDeleteTypeModal] = useState(false)
//     const [deleteTypeId, setDeleteTypeId] = useState('')
//     const [deleteTypeTrue, setDeleteTypeTrue] = useState(false)
//     const [deleteTrueName, setDeleteTrueName] = useState('')
//     const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false)
//     const [deleteCollectionId, setDeleteCollectionId] = useState('')
//     const [deleteCollectionTrue, setDeleteCollectionTrue] = useState(false)
//     const [deleteCollectionName, setDeleteCollectionName] = useState('')

//     useEffect(() => {
//         setLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "product_id": product_id
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "content/productInfo", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     setLoading(false)
//                     setStyleData(result.styles)
//                     setTypeData(result.types)
//                     setCollectionData(result.collections)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }, [product_id, deleteTrue, deleteTypeTrue, deleteCollectionTrue])

//     const handleDeleteItem = (deleteStyleId) => {
//         if (managerId) {
//             setDeleteTrue(false)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "_id": deleteStyleId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "content/deleteStyle", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     // alert(result.msg)
//                     if (result.status === 'success') {
//                         setDeleteTrue(true)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }

//     const handleDeleteTypeItem = (deleteTypeId) => {
//         if (managerId) {
//             setDeleteTypeTrue(false)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "_id": deleteTypeId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };
//             fetch(process.env.REACT_APP_URL + "content/deleteType", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     // alert(result.msg)
//                     if (result.status === 'success') {
//                         setDeleteTypeTrue(true)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }
//     const handleDeleteCollectionItem = (deleteCollectionId) => {
//         if (managerId) {
//             setDeleteCollectionTrue(false)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "_id": deleteCollectionId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "content/deleteCollection", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     // alert(result.msg)
//                     if (result.status === 'success') {
//                         setDeleteCollectionTrue(true)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }

//     // console.log('deleteStyleId', deleteStyleId)

//     return (
//         <React.Fragment>

//             <Modal
//                 show={showDeleteModal}
//                 close={setShowDeleteModal}
//                 closeBtn={true}
//                 heading={deleteStyleName&&deleteStyleName}
//                 content={
//                     <div className={styles.text_center}>
//                         <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteStyleName&&deleteStyleName}</p></div>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteModal(false)} />
//                             <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteItem(deleteStyleId); setShowDeleteModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showDeleteTypeModal}
//                 close={setShowDeleteTypeModal}
//                 closeBtn={true}
//                 heading={deleteTrueName&&deleteTrueName}
//                 content={
//                     <div className={styles.text_center}>
//                         <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteTrueName&&deleteTrueName}</p></div>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteTypeModal(false)} />
//                             <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteTypeItem(deleteTypeId); setShowDeleteTypeModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showDeleteCollectionModal}
//                 close={setShowDeleteCollectionModal}
//                 closeBtn={true}
//                 heading={deleteCollectionName&&deleteCollectionName}
//                 content={
//                     <div className={styles.text_center}>
//                         <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteCollectionName&&deleteCollectionName}</p></div>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteCollectionModal(false)} />
//                             <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteCollectionItem(deleteCollectionId); setShowDeleteCollectionModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <div className={styles.product_main_div}>
//                 <div className={styles.product_header}>
//                     <u onClick={() => navigate(-1)}>
//                         &lt; Go Back
//                     </u>
//                     <div>
//                         <GreenOutButton type="button" title="+ ADD / VIEW FAQ" css="successBtn mouse me_20" handleSubmit={() => navigate('/addfaq/' + product_name + '?product_id=' + product_id)} />
//                         <GreenOutButton type="button" title="+ ADD STYLE" css="successBtn mouse me_20" handleSubmit={() => navigate('/addstyle/' + product_name + '?product_id=' + product_id)} />
//                         <GreenOutButton type="button" title="+ ADD TYPE" css="successBtn mouse me_20" handleSubmit={() => navigate('/addtype/' + product_name + '?product_id=' + product_id)} />
//                         <GreenOutButton type="button" title="+ ADD COLLECTION" css="successBtn mouse" handleSubmit={() => navigate('/addcollection/' + product_name + '?product_id=' + product_id)} />
//                     </div>
//                 </div>

//                 {
//                     loading ? <div className={'loadingMain'}>
//                         <img src='/wallicon.gif' alt='walliconGIF' />
//                     </div> :
//                         <>
//                             <h1 className={styles.heading}>
//                                 {product_name}
//                             </h1>

//                             {url === "addfaq" ?
//                                 <AddFaq />
//                                 : url === "addstyle" ?
//                                     <AddStyle />
//                                     : url === "addtype" ?
//                                         <AddType />
//                                         : url === "addcollection" ?
//                                             <AddCollection />
//                                             : url === "product" ?

//                                                 <div className={styles.product_scroll_div}>

//                                                     <div className={styles.product_st_div}>
//                                                         <h2>Style</h2>
//                                                         <div className={styles.product_st_row}>

//                                                             {styleData && styleData.map((item, index) =>
//                                                                 <div className={styles.product_st_col} key={index}>
//                                                                     <div onClick={() => { navigate("/addstyle/" + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>

//                                                                         <div className={styles.product_st_status}>
//                                                                             {item.status === "Disable" || item.status === "Delete" ? <Ban color={item.status === "Delete" ? "#F93E3E" : "#FFFFFF"} css={styles.product_st_status_icon} /> : item.status === "Active" ? <Checked color="#1FFC33" css={styles.product_st_status_icon} /> : <Clock color="yellow" css={styles.product_st_status_icon} />}
//                                                                         </div>
//                                                                         <img src={item.logo ? process.env.REACT_APP_S3URL + item.logo : "/assets/img.png"} alt="style_Logo" width={72} height={66} />
//                                                                         <h6>
//                                                                             {item.style_name}
//                                                                         </h6>
//                                                                     </div>
//                                                                     <div className={styles.product_st_btn_div}>
//                                                                         <button className={styles.product_st_btn}>
//                                                                             <Edit color="#1FFC33" width={16}
//                                                                             //  onClick={(e) => { navigate('/editstyle'); setIds(item._id); localStorage.setItem("viewEdit", 1) }} 
//                                                                             />
//                                                                         </button>
//                                                                         <button className={styles.product_st_btn} onClick={() => { setShowDeleteModal(true); setDeleteStyleId(item._id); setDeleteStyleName(item.style_name) }}>
//                                                                             <DeleteFile color="#F93E3E" width={16}
//                                                                             // onClick={selectModal}
//                                                                             />
//                                                                         </button>
//                                                                     </div>
//                                                                 </div>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                     <div className={styles.product_st_div}>
//                                                         <h2>Type</h2>
//                                                         <div className={styles.product_st_row}>

//                                                             {typeData && typeData.map((item, index) =>
//                                                                 <div className={styles.product_st_col} key={index}>
//                                                                     <div onClick={() => { navigate("/addtype/" + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>

//                                                                         <div className={styles.product_st_status}>
//                                                                             {item.status === "Disable" || item.status === "Delete" ? <Ban color={item.status === "Delete" ? "#F93E3E" : "#FFFFFF"} css={styles.product_st_status_icon} /> : item.status === "Active" ? <Checked color="#1FFC33" css={styles.product_st_status_icon} /> : <Clock color="yellow" css={styles.product_st_status_icon} />}
//                                                                         </div>
//                                                                         <img src={item.logo ? process.env.REACT_APP_S3URL + item.logo : "/assets/img.png"} alt="style_Logo" width={72} height={66} />
//                                                                         <h6>
//                                                                             {item.type_name}
//                                                                         </h6>
//                                                                     </div>
//                                                                     <div className={styles.product_st_btn_div}>
//                                                                         <button className={styles.product_st_btn}>
//                                                                             <Edit color="#1FFC33" width={16}
//                                                                             //  onClick={(e) => { navigate('/editstyle'); setIds(item._id); localStorage.setItem("viewEdit", 1) }} 
//                                                                             />
//                                                                         </button>
//                                                                         <button className={styles.product_st_btn} onClick={() => { setShowDeleteTypeModal(true); setDeleteTypeId(item._id); setDeleteTrueName(item.type_name) }}>
//                                                                             <DeleteFile color="#F93E3E" width={16}
//                                                                             // onClick={selectModal}
//                                                                             />
//                                                                         </button>
//                                                                     </div>
//                                                                 </div>
//                                                             )}
//                                                         </div>
//                                                     </div>

//                                                     <h1 className={`${styles.text_center} ${styles.heading}`}>Collections</h1>
//                                                     {collectionData && collectionData.map((item, index) =>
//                                                         <div className={styles.productRow} key={index}>
//                                                             <div className={styles.w_100} onClick={() => navigate('/items/' + product_name + "/" + item.collection_name.replace(/\s/g, "-") + '?product_id=' + product_id + "&id=" + item._id)} >
//                                                                 <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
//                                                                 <h1>
//                                                                     {item.collection_name}
//                                                                 </h1>
//                                                             </div>
//                                                             <div className={styles.productBtns}>
//                                                                 <div className={styles.productsEVS} onClick={() => { setShowDeleteCollectionModal(true); setDeleteCollectionId(item._id); setDeleteCollectionName(item.collection_name) }}>
//                                                                     <div><DeleteIcon /></div>
//                                                                     <span>Delete</span>
//                                                                 </div>
//                                                                 <div className={styles.productsEVS} onClick={() => { navigate('/addcollection/' + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>
//                                                                     <div><Edit color="#ffffff" /></div>
//                                                                     Edit
//                                                                 </div>
//                                                                 <div className={styles.productsEVS} onClick={() => { navigate('/addcollection/' + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>
//                                                                     <div><View /></div>
//                                                                     View
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     )}
//                                                 </div>
//                                                 : ""
//                             }
//                         </>
//                 }
//             </div>
//         </React.Fragment>
//     )
// }

// export default ViewProduct












import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth'
import styles from "./Product.module.css"
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import Button from '../../components/updatedComponents/Dealers/Button'
import { AddStyle, DeleteTo, EditeTo, SettingTo, ViewTo } from '../../components/icons/Icon3'
import { useNavigate, useParams } from 'react-router-dom'
import StyleTypeCard from '../../components/updatedComponents/Product/StyleTypeCard'
import { TextColorCode } from '../../Utils/TextUtils'
import fetchData from '../../APIsControll/apiControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import ManagerModal from '../../components/updatedComponents/Manager/ManagerModal'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
const ViewProduct = () => {
    const { mode, managerId } = useAuth();
    const { product } = useParams();
    const { show } = useToast();
    const navi = useNavigate();
    const product_id = new URLSearchParams(window.location.search).get("product_id");

    const [data, setData] = useState();
    const [modeleContiner, setModelContiner] = useState(null);
    const [modelToggle, setModelToggle] = useState(false);
    const [loading, setLoading] = useState({
        productInfo: false,
        deleteProduct: false
    });



    const EditStyle = (id) => {
        console.log("EditStyle id", id);
    }
    const DeleteItem = async (id, type) => {
        setLoading({ ...loading, deleteProduct: true })
        try {
            const payload = {
                managerId: managerId,
                _id: id
            };

            let endpoint = '';
            if (type === "styles") {
                endpoint = 'content/deleteStyle';
            } else if (type === "types") {
                endpoint = 'content/deleteType';
            } else if (type === "collections") {
                endpoint = 'content/deleteCollection';
            }
            if (endpoint) {
                const res = await fetchData(endpoint, payload);
                if (res && res.status === "success") {
                    show(res.msg, "success");
                    getOrderInfo();
                    setModelToggle(false);
                    return;
                }
                if (res && res.status === "failed") {
                    show(res.msg || "Something went wrong", "error");
                    return;
                } else {
                    show("Unexpected response format", "error");
                    return;
                }
            } else {
                show("Invalid type provided", "error");
                return;
            }
        } catch (error) {
            show("An error occurred while processing your request.", "error");
        } finally {
            setLoading({ ...loading, deleteProduct: false })

        }
    };


    const handleSetModel = (item) => {
        setModelToggle(true);
        setModelContiner(item);
    }

    async function getOrderInfo() {
        setLoading({ ...loading, productInfo: true })

        try {
            const payload = {
                product_id: product_id
            }
            const res = await fetchData(`content/productInfo`, payload)
            if (res.status === "success") {
                setData(res)
                return
            }
            if (res.status === "failed") {
                show(res.msg, "error");
                return
            }
        } catch (error) {
            show('', "error");
        } finally {
            setLoading({ ...loading, deleteProduct: false })
        }
    }
    useEffect(() => {
        getOrderInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product_id]);






    return (
        <div className={styles.Main_Layout}  >
            <ModalWrapper isOpen={modelToggle} onClose={() => setModelToggle(!modelToggle)} >
                {modeleContiner}
            </ModalWrapper>
            <div className={styles.Layout} style={{ backgroundColor: mode ? "#2B2D31" : "#F8F9FC", color: mode ? "#fff" : "#000" }} >
                <div className={styles.Top_bar_css}>
                    <div>
                        <TopTabBar text={`Products/${product}`} />
                    </div>
                    <div className={styles.btn_Continer}>
                        <div>
                            <Button
                                buttonBgColor={mode ? "#232529" : "#fff"}
                                buttonIcon={<AddStyle mode={mode} />}
                                buttonText={"Add View / FAQ"}
                                buttonTextColor={mode ? "#fff" : "#646B88"}
                            />
                        </div>
                        <div>
                            <Button
                                buttonBgColor={mode ? "#232529" : "#fff"}
                                buttonIcon={<AddStyle mode={mode} />}
                                buttonText={"Add Style"}
                                buttonTextColor={mode ? "#fff" : "#646B88"}
                            />
                        </div>
                        <div>
                            <Button
                                buttonBgColor={mode ? "#232529" : "#fff"}
                                buttonIcon={<AddStyle mode={mode} />}
                                buttonText={"Add Type"}
                                buttonTextColor={mode ? "#fff" : "#646B88"}
                            />
                        </div>
                        <div>
                            <Button
                                buttonBgColor={mode ? "#232529" : "#fff"}
                                buttonIcon={<AddStyle mode={mode} />}
                                buttonText={"Add Collection"}
                                buttonTextColor={mode ? "#fff" : "#646B88"}
                            />
                        </div>
                    </div>

                </div>
                <div className={styles.Scroll_con}>
                    {/* style */}
                    <div className={styles.Style_continer} >
                        <div className={styles.Style_continer_text} style={{ color: mode ? "#fff" : "#646B88" }}>Style</div>
                        <div className={styles.Style_Card_continer}>
                            {
                                loading.productInfo ? Array.from({ length: 4 }).map((_, index) => (
                                    <div key={index} style={{ width: "100%" }}>
                                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                            <div style={{ width: "100%" }}>
                                                <Skeleton thickness={6} shape="circle" width="50px" height="50px" />
                                                <div style={{ width: "75%" }}>
                                                    <Skeleton lines={3} thickness={1} />
                                                </div>
                                                <Skeleton lines={1} thickness={3} />
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    // data.styles.length === 0 ? <NoDataFound icon={<TemplateNotFound />} /> :
                                        data?.styles?.map((item, i) =>
                                            <StyleTypeCard
                                                Status={item?.status}
                                                Text={item?.style_name}
                                                ststusIcon={item?.status}
                                                imgUrl={item?.logo}
                                                DeleteClick={() =>
                                                    handleSetModel(<>
                                                        <ManagerModal
                                                            heading={"Delete Product"}
                                                            centerHeading={"Are you sure you want to Delete?"}
                                                            subHeading={
                                                                "To confirm! type delete in the text field."
                                                            }
                                                            targetWord={"delete"}
                                                            handleSubmit={() => {
                                                                DeleteItem(item._id, "styles");
                                                            }}
                                                            handleCloseModal={() => setModelToggle(!modelToggle)}
                                                        />
                                                    </>)}
                                                EditeClick={() => EditStyle(i)}
                                                 key={i}
                                            />
                                        )}
                        </div>
                    </div>

                    {/* Type */}
                    <div className={styles.Style_continer} >
                        <div className={styles.Style_continer_text} style={{ color: mode ? "#fff" : "#646B88" }}>Type</div>
                        <div className={styles.Style_Card_continer}>
                            {
                                loading.productInfo ? Array.from({ length: 4 }).map((_, index) => (
                                    <div key={index} style={{ width: "100%" }}>
                                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                            <div style={{ width: "100%" }}>
                                                <Skeleton thickness={6} shape="circle" width="50px" height="50px" />
                                                <div style={{ width: "75%" }}>
                                                    <Skeleton lines={3} thickness={1} />
                                                </div>
                                                <Skeleton lines={1} thickness={3} />
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    // data.types.length === 0 ? <NoDataFound icon={<TemplateNotFound />} /> :
                                        data?.types?.map((item, i) =>
                                            <StyleTypeCard
                                                Status={item?.status}
                                                Text={item?.type_name}
                                                ststusIcon={item?.status}
                                                imgUrl={item?.logo}
                                                DeleteClick={() =>
                                                    handleSetModel(<>
                                                        <ManagerModal
                                                            heading={"Delete Product"}
                                                            centerHeading={"Are you sure you want to Delete?"}
                                                            subHeading={
                                                                "To confirm! type delete in the text field."
                                                            }
                                                            targetWord={"delete"}
                                                            handleSubmit={() => {
                                                                DeleteItem(item._id, "types");
                                                            }}
                                                            handleCloseModal={() => setModelToggle(!modelToggle)}
                                                        />
                                                    </>)}
                                                EditeClick={() => EditStyle(i)}
                                                key={i}
                                            />
                                        )}
                        </div>
                    </div>
                    <div className={styles.Style_continer}  >
                        <div className={styles.Style_continer_text} style={{ color: mode ? "#fff" : "#646B88" }}>Collection</div>
                        <div className={styles.container} style={{ marginBottom: "-10px" }}>
                            <ul className={styles.responsiveTable} >
                                <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" } : { backgroundColor: "#D9DCE5" }} >
                                    <div className={styles.col} >Status</div>
                                    <div className={styles.col} >Product Name</div>
                                    <div className={styles.col} >Delete</div>
                                    <div className={styles.col} >Setting</div>
                                    <div className={styles.col} >Edit</div>
                                    <div className={styles.col} >View</div>
                                </li>
                                <div className={styles.Table_row}>
                                    {
                                        loading.productInfo ? <Skeleton lines={5} thickness={5} /> :
                                            // data.collections.length === 0 ? <NoDataFound icon={<TemplateNotFound />} /> :
                                                data?.collections.map((item) =>
                                                    <li className={styles.tablerow}
                                                        style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa", } :
                                                            { backgroundColor: "#ffff", }}
                                                        onClick={() => navi(`/products/items/${product}/${item?.collection_name}?productId=${item?._id}&collectionId=${product_id}`)}
                                                        key={item?._id}
                                                    >
                                                        <div className={styles.col} data-label="Status" style={{ color: TextColorCode(item?.status) }}>
                                                            {item?.status} </div>
                                                        <div className={styles.col} data-label="Product Name" style={{ color: mode ? "#fff" : "#000" }} >
                                                            {item?.collection_name}</div>
                                                        <div className={styles.col} data-label="Delete" style={{ color: mode ? "#fff" : "#000" }} >
                                                            <span
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSetModel(<>
                                                                        <ManagerModal
                                                                            heading={"Delete Product"}
                                                                            centerHeading={"Are you sure you want to Delete?"}
                                                                            subHeading={
                                                                                "To confirm! type delete in the text field."
                                                                            }
                                                                            targetWord={"delete"}
                                                                            handleSubmit={() => {
                                                                                DeleteItem(item._id, "collections");
                                                                            }}
                                                                            handleCloseModal={() => setModelToggle(!modelToggle)}
                                                                        />
                                                                    </>)
                                                                }}
                                                            > <DeleteTo /> </span>
                                                        </div>
                                                        <div className={styles.col} data-label="Setting" style={{ color: mode ? "#fff" : "#000" }} >
                                                            <SettingTo mode={mode} />
                                                        </div>
                                                        <div className={styles.col} data-label="Edit" style={{ color: mode ? "#fff" : "#000" }} >
                                                            <EditeTo />
                                                        </div>
                                                        <div className={styles.col} data-label="View" style={{ color: mode ? "#fff" : "#000" }} >
                                                            <ViewTo />
                                                        </div>
                                                    </li>
                                                )}

                                </div>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewProduct




















