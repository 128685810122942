import { Spinner } from "../../icons/Icon";
import styles from "./DealerStyles.module.css";

const FileUploadButton = ({
  buttonIcon,
  buttonText,
  buttonBgColor,
  disabledButtonBgColor,
  buttonTextColor,
  disabledButtonTextColor,
  buttonClick,
  buttonBorder,
  buttonFontWeight,
  loading,
  disabled,
  tabIndex,
  buttonType,
  onFileChange,
  accept,
  buttonWidth,
  buttonHeight,
}) => {
  return (
    <label
      className={styles.button}
      style={{
        backgroundColor: disabled ? disabledButtonBgColor : buttonBgColor,
        border: buttonBorder,
        cursor: disabled ? "no-drop" : "pointer",
      }}
    >
      {loading ? <Spinner size="20" /> : buttonIcon}
      {buttonText && (
        <p
          style={{
            color: disabled ? disabledButtonTextColor : buttonTextColor,
            fontWeight: buttonFontWeight,
            buttonWidth: buttonWidth,
            buttonHeight: buttonHeight,
          }}
          className={styles.text}
        >
          {buttonText}
        </p>
      )}
      <input
    
        type="file"
        style={{ display: "none" }}
        onChange={onFileChange}
        disabled={disabled}
        tabIndex={tabIndex && tabIndex}
        accept={accept}
      />
    </label>
  );
};

export default FileUploadButton;

