import { useEffect } from "react";
import styles from "./Wrapper.module.css";

const ModalWrapper = ({ children, isOpen, onClose, style }) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        // console.log("escape is pressed ")
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose]);

  return (
    <div
      className={`${styles.modalOverlay} ${
        isOpen ? styles.open : styles.close
      }`}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className={styles.modalContent} style={style}>
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
