// import React, { useEffect, useState } from 'react'
// import { useAuth } from '../../components/context/Auth'
// import { DownloadIcon, ReloadIcon, Search, TestTopBackArrowIcon, TestTopDotIcon } from '../../components/icons/Icon'
// import styles from "./Admin.module.css"

// import Paginantion from '../../components/Paginantion'
// import { useNavigate, useSearchParams } from 'react-router-dom'
// import DealerStatusCard from '../../components/updatedComponents/Dealers/DealerStatusCard'
// import SlidingFilter from '../../components/updatedComponents/Dealers/SlidingFilter'
// import Button from '../../components/updatedComponents/Dealers/Button'
// import { Download } from '../../components/icons/Icon4'
// import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate'
// import { mode } from 'crypto-js'

// const DispatchDasboard = () => {
//   const slidingFilter2 = [
//     "All",
//     "Sales",
//     "Purchase",
//     "Adjustment",
//     "Transfer",
//     "Production",
//     "Production & Sale",
//     "Production & Transfer",
//   ];
//   const itemList = [" All Dealer", "Today", "Yesterday", "This Month", "Last 30 Days"];

//   const navigate = useNavigate();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
//   const { managerId, designation } = useAuth();
//   const [data, setData] = useState([]);
//   const [daysTab, setDaysTab] = useState('today')
//   const [shipingTab, setShipingTab] = useState('all')
//   const [cartTab, setCartTab] = useState('all')
//   const [orders, setOrders] = useState([])
//   const [keyword, setKeyword] = useState('')
//   const [sortKey, setSortKey] = useState('')
//   const [loading, setLoading] = useState(false)
//   const [orderStatus, setOrderStatus] = useState('')
//   const [warehouses, setWarehouses] = useState([])
//   const [warehouseName, setWarehouseName] = useState('')
//   const [managers, setManagers] = useState([])
//   const [managerName, setManagerName] = useState('')
//   const [startDate, setStartDate] = useState('')
//   const [endDate, setEndDate] = useState('')
//   const [state, setState] = React.useState({
//     totalPages: "",
//     currentPage: 1
//   });
//   const [searchDealerData, setSearchDealerData] = useState({
//     searchDealerName: '',
//     sortByStatus: '',
//     sortKey: '',
//     dateFilter: '',
//     district: '',
//     status: '',
//   });
//   const updateDateFilter = (selectedDate) => {
//     setSearchDealerData((prevData) => ({
//       ...prevData,
//       dateFilter: selectedDate,
//     }));
//   };

//   const { totalPages, } = state;

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true)
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         "managerId": parseInt(managerId),
//         "keyword": keyword,
//         "orderStatus": orderStatus,
//         "sortKey": sortKey,
//         "shippingType": shipingTab,
//         "cartType": cartTab,
//         "warehousename": warehouseName,
//         "disPatchManagerId": managerName,
//         "day": daysTab,
//         "startDate": startDate,
//         "endDate": endDate
//       });

//       var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow'
//       };

//       fetch(`${process.env.REACT_APP_URL}dispatch/allOrders?page=${page}&limit=8`, requestOptions)
//         // fetch(`http://192.168.0.117:5055/dispatch/allOrders?page=${page}&limit=8`, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//           if (result.status === 'success') {
//             setLoading(false)
//             setData(result.data)
//             setOrders(result.orders)
//             if (result.warehouses) {
//               setWarehouses(result.warehouses)
//             }
//             if (result.managers) {
//               setManagers(result.managers)
//             }
//           }
//           if (result.pages) {
//             setState(prevState => ({
//               ...prevState,
//               totalPages: result.pages ? result.pages : "",
//             }))
//           }
//         })
//         .catch(error => console.log('error', error));
//     }
//   }, [managerId, sortKey, keyword, page, orderStatus, shipingTab, cartTab, warehouseName, managerName, daysTab, startDate, endDate])

//   console.log(data)
//   return (
//     <React.Fragment>

//       <div>
//         <div className={styles.right_main}>
//         <div className={styles.dispatchDashboard_header} style={{ border: "1px solid", width: "100%" }}>

//           <div
//   style={{
//     display: "flex",

//     alignItems: "center",
//     gap: "10px",
//   }}
// >

//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       cursor: "pointer",
//     }}
//     onClick={() => navigate(-1)}
//   >

//     <TestTopBackArrowIcon style={{ color: "#0100E4" }} />
//     <p
//       style={{
//         color: "#0100E4",
//         marginLeft: "100px",
//         textAlign:'left',
//       }}
//     >
// <p  style={{

//         marginLeft: "10px",
//         textAlign:'left',
//       }}>      Go Back
// </p>    </p>
//   </div>

//   <TestTopDotIcon style={{ color: "#0100E4" }} />

//   <p
//     style={{
//       color: "#646B88",
//       marginLeft: "5px",
//     }}
//   >
//     {designation === "superadmin" ? "Dispatch Orders" : "Dashboard"}
//   </p>
// </div>   <div       >

//             <div className={styles.dashboard_header_select_week}>
// <div className={styles.dashboard_header_select_week}>
//   <div
//     className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.dashboard_days_select_active : ''}`}
//     onClick={() => setDaysTab('today')}
//   >
//     Today
//   </div>
//   <div
//     className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.dashboard_days_select_active : ''}`}
//     onClick={() => setDaysTab('yesterday')}
//   >
//     Yesterday
//   </div>
//   <div
//     className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.dashboard_days_select_active : ''}`}
//     onClick={() => setDaysTab('month')}
//   >
//     This Month
//   </div>
// </div>

//       </div>

//           </div>

//           <div style={{display:"flex", gap:"2px",}}>
//             <div>
//               <InputTypedate text={"From"}  onChange={(e) => {
//                   setStartDate(e.target.value);
//                   setDaysTab('');
//                 }}/>
//             </div>
//             <div>
//               <InputTypedate text={"To"} onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}/>
//             </div>
//           </div>

//           <div
//             style={{
//               display: "flex",
//               // width: "20%",
//               alignItems: "center",
//               gap: "10px",
//             }}
//           >
//             {/* Reload Button */}
//             <button
//               style={{
//                 backgroundColor: "black",
//                 color: "white",
//                 padding: "10px 20px",
//                 border: "none",
//                 borderRadius: "10px",
//                 display: "flex",
//                 alignItems: "center", // Proper alignment for icon and text
//                 cursor: "pointer",
//               }}
//               onClick={() => window.location.reload()} // Reload functionality
//             >
//               <span
//                 style={{
//                   marginRight: "3px", // Adds spacing between icon and text
//                 }}
//               >
//                 <ReloadIcon />
//               </span>
//               <span className={styles.reload_txt}>Reload</span>
//             </button>

//             <Button
//               buttonBgColor={"#4164E3"}
//               buttonIcon={<Download />}
//               buttonText={"Download"}
//               buttonTextColor={"#fff"}
//             />
//           </div>

//         </div>

//         <div className={styles.dashboard_main_container}>
//           <div className={styles.dashboard_main_div}>

//             <div className={styles.statusCard_div}>
//               {orders && orders.map((item, index) =>
//                 // <DealerStatusCard dealerStatus={item.status} statusItemCount={item.count} statusText={item.status}
//                 <div key={index} className={`${styles.statusCard} ${orderStatus === item.status && item.status === 'New Order' ? styles.newOrder_active :
//                                     orderStatus === item.status && item.status === 'In Progress' ? styles.inProgress_active :
//                                       orderStatus === item.status && item.status === 'Packed' ? styles.packed_active :
//                                         orderStatus === item.status && item.status === 'Pending' ? styles.Pending_active :
//                                           orderStatus === item.status && item.status === 'Dispatched' ? styles.Dispatches_active :
//                                             orderStatus === item.status && item.status === 'All' ? styles.orange_active :
//                                               orderStatus === item.status && item.status === 'Cancelled' ? styles.cancel_status :
//                                                 ''}`}

//               //     onClick={() => {
//               //       setOrderStatus(item.status);
//               //       if (searchParams.has("page")) {
//               //         const token = searchParams.get("page");
//               //         if (token) {
//               //           searchParams.delete("page");
//               //           setSearchParams(searchParams);
//               //         }
//               //       }
//               //     }}

//               //    />

//               // )}

//                   onClick={() => {
//                     setOrderStatus(item.status);
//                     if (searchParams.has("page")) {
//                       const token = searchParams.get("page");
//                       if (token) {
//                         searchParams.delete("page");
//                         setSearchParams(searchParams);
//                       }
//                     }
//                   }}>
//                   <span className={
//                     item.status === 'New Order' ? styles.statusNew :
//                       item.status === 'In Progress' ? styles.statusYellow :
//                         item.status === 'Packed' ? styles.statusBlue :
//                           item.status === 'All' ? styles.statusAll :
//                             item.status === 'Pending' ? styles.statusRed :
//                               item.status === 'Dispatched' ? styles.statusGreen :
//                                 item.status === 'Cancelled' ? styles.statusWhite :
//                                   ''}>
//                     {item.count}
//                   </span>
//                   <span className={styles.statusTxt}>{item.status}</span>
//                 </div>
//               )}
//             </div>{/* <div className={styles.statusCard_div}>
//     </div>
//   ))}
// </div> */}
//             <div className={styles.dispatchDashboard_header}>
//               <div className={styles.dashboard_search_div}>
//               {/* <input
//                         type="search"
//                         placeholder='Search'
//                         className={styles.dealer_search}
//                         value={keyword}
//                         onChange={(e) => setKeyword(e.target.value)}
//                     />
//                     <Search color="#ffffff" css={styles.dealer_search_icon} /> */}
//                     <input
//   type="search"
//   placeholder="Search"
//   className={styles.dealer_search}
//   value={keyword}
//   onChange={(e) => setKeyword(e.target.value)}
//   style={{
//     border: "0.5px solid black",
//     padding: "5px ",
//     borderRadius: "15px",
//     outline: "none",
//     color: keyword ? "black" : "gray",
//     width: "5px",
//   }}
// />
// <Search color="#ffffff" css={styles.dealer_search_icon} />
// <div className={styles} style={{ marginLeft: '20px' }}>
//   <SlidingFilter itemList={slidingFilter2} />
// </div>

//               </div>

//               <div className={styles.dashboard_header_select_week} >
//                 {/* <div className={`${styles.dashboard_days_select} ${shipingTab === 'all' ? styles.days_active : ''}`} onClick={() => setShipingTab('all')}>All</div>
//                 <div className={`${styles.dashboard_days_select} ${shipingTab === 'Standard Shipping' ? styles.days_active : ''}`} onClick={() => setShipingTab('Standard Shipping')}>Standard</div>
//                 <div className={`${styles.dashboard_days_select} ${shipingTab === 'Express' ? styles.days_active : ''}`} onClick={() => setShipingTab('Express')}>Express</div> */}
//                 {/* <div
//   style={{
//     padding: "10px 20px",
//     cursor: "pointer",
//     color: shipingTab === "all" ? "white" : "black",
//     backgroundColor: shipingTab === "all" ? "black" : "transparent",
//     border: shipingTab === "all" ? "1px solid black" : "1px solid transparent",
//   }}
//   onClick={() => setShipingTab("all")}
// >
//   All
// </div>
// <div
//   style={{
//     padding: "10px 20px",
//     cursor: "pointer",
//     color: shipingTab === "Standard Shipping" ? "white" : "black",
//     backgroundColor: shipingTab === "Standard Shipping" ? "black" : "transparent",
//     border: shipingTab === "Standard Shipping" ? "1px solid black" : "1px solid transparent",
//   }}
//   onClick={() => setShipingTab("Standard Shipping")}
// >
//   Standard
// </div>
// <div
//   style={{
//     padding: "10px 20px",
//     cursor: "pointer",
//     color: shipingTab === "Express" ? "white" : "black",
//     backgroundColor: shipingTab === "Express" ? "black" : "transparent",
//     border: shipingTab === "Express" ? "1px solid black" : "1px solid transparent",
//   }}
//   onClick={() => setShipingTab("Express")}
// >
//   Express
// </div> */}
//                 {/* <div
//                   style={{
//                     padding: "10px 20px",
//                     cursor: "pointer",
//                     color: shipingTab === "all" ? "white" : "black",
//                     backgroundColor: shipingTab === "all" ? "black" : "transparent",
//                     border: shipingTab === "all" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                     marginTop: "30px" ,

//                   }}
//                   onClick={() => setShipingTab("all")}
//                 >
//                   All
//                 </div> */}
//                 {/* <div
//                   style={{
//                     padding: "10px 20px",
//                     cursor: "pointer",
//                     color: shipingTab === "Standard Shipping" ? "white" : "black",
//                     backgroundColor: shipingTab === "Standard Shipping" ? "black" : "transparent",
//                     border: shipingTab === "Standard Shipping" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                     marginTop: "30px" ,

//                   }}
//                   onClick={() => setShipingTab("Standard Shipping")}
//                 >
//                   Standard
//                 </div> */}
//                 {/* <div
//                   style={{
//                     padding: "10px 20px",
//                     marginTop: "30px" ,

//                     cursor: "pointer",
//                     color: shipingTab === "Express" ? "white" : "black",
//                     backgroundColor: shipingTab === "Express" ? "black" : "transparent",
//                     border: shipingTab === "Express" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                   }}
//                   onClick={() => setShipingTab("Express")}
//                 >
//                   Express
//                 </div> */}

//               </div>

//               <div className={styles.dashboard_header_select_week}>
//                 {/* <div className={`${styles.dashboard_days_select} ${cartTab === 'all' ? styles.days_active : ''}`} onClick={() => setCartTab('all')}>All</div>
//                 <div className={`${styles.dashboard_days_select} ${cartTab === 'Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Cart')}>Cart</div>
//                 <div className={`${styles.dashboard_days_select} ${cartTab === 'Partner Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Partner Cart')}>Partner Cart</div> */}
//                 {/* <div
//                   style={{
//                     padding: "5px 10px",
//                     marginTop: "30px" ,
//                     marginLeft: "87px" ,

//                     cursor: "pointer",
//                     color: cartTab === "all" ? "white" : "black",
//                     backgroundColor: cartTab === "all" ? "black" : "transparent",
//                     border: cartTab === "all" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                     transition: "all 0.3s ease",
//                   }}
//                   onClick={() => setCartTab("all")}
//                 >
//                   All
//                 </div> */}
//                 {/* <div
//                   style={{
//                     padding: "10px 20px",
//                     cursor: "pointer",
//                     color: cartTab === "Cart" ? "white" : "black",
//                     backgroundColor: cartTab === "Cart" ? "black" : "transparent",
//                     border: cartTab === "Cart" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                     marginTop: "30px" ,

//                     transition: "all 0.3s ease",
//                   }}
//                   onClick={() => setCartTab("Cart")}
//                 >
//                   Cart
//                 </div> */}
//                 {/* <div
//                   style={{
//                     padding: "10px 20px",
//                     cursor: "pointer",
//                     color: cartTab === "Partner Cart" ? "white" : "black",
//                     backgroundColor: cartTab === "Partner Cart" ? "black" : "transparent",
//                     border: cartTab === "Partner Cart" ? "1px solid black" : "1px solid transparent",
//                     borderRadius: "5px",
//                     marginTop: "30px" ,

//                     transition: "all 0.3s ease",
//                   }}
//                   onClick={() => setCartTab("Partner Cart")}
//                 >
//                   Partner Cart
//                 </div> */}

//               </div>

//               {/* {designation === 'superadmin' &&
//                 <div className={styles.dashboard_Sort_By}>
//                   <label className={styles.dashboard_sortBy_txt}>Warehouse</label>
//                   <select
//                     className={styles.dashboard_sortBy_select}
//                     value={warehouseName}
//                     onChange={(e) => setWarehouseName(e.target.value)}
//                   >
//                     <option value='All'>View All</option>
//                     {
//                       warehouses && warehouses.map((item, index) =>
//                         <option key={index} value={item.warehousename}>{item.warehousename}</option>
//                       )
//                     }
//                   </select>
//                 </div>
//               } */}
//               {designation === 'superadmin' &&
//   <div className={styles.dashboard_Sort_By}>
//     <label
//       className={styles.dashboard_sortBy_txt}
//       style={{ marginBottom: '10px'   ,  marginTop: "30px" ,
//       }} // Adds a gap between the label and the select dropdown
//     >
//       Warehouse
//     </label>
//     <select
//       className={styles.dashboard_sortBy_select}
//       value={warehouseName}
//       onChange={(e) => setWarehouseName(e.target.value)}
//       style={{ marginTop: '5px' }} // Adds a gap above the select dropdown
//     >
//       <option   value="All">View All</option>
//       {warehouses && warehouses.map((item, index) =>
//         <option key={index} value={item.warehousename}>{item.warehousename}</option>
//       )}
//     </select>
//   </div>
// }

// {designation === 'superadmin' &&
//                 <div className={styles.dashboard_Sort_By}>
//                   <label className={styles.dashboard_sortBy_txt}>Warehouse</label>
//                   <select
//                     className={styles.dashboard_sortBy_select}
//                     value={warehouseName}
//                     onChange={(e) => setWarehouseName(e.target.value)}
//                   >
//                     <option value='All'>View All</option>
//                     {
//                       warehouses && warehouses.map((item, index) =>
//                         <option key={index} value={item.warehousename}>{item.warehousename}</option>
//                       )
//                     }
//                   </select>
//                 </div>
//               }

//               <div className={styles.dashboard_Sort_By}>
//                 <label className={styles.dashboard_sortBy_txt}>Sort By </label>
//                 <select
//                   className={styles.dashboard_sortBy_select}
//                   value={sortKey || orderStatus}
//                   onChange={(e) => {
//                     if (e.target.value === 'Ascending' || e.target.value === 'Descending' || e.target.value === 'lastAction') {
//                       setSortKey(e.target.value)
//                     } else {
//                       setOrderStatus(e.target.value)
//                       setSortKey("")
//                     }
//                   }}
//                 >
//                   <option value="All">View All</option>
//                   {/* <option value="All">All</option> */}
//                   <option value="New Order">New Order</option>
//                   <option value="Cancelled">Cancelled</option>
//                   <option value="In Progress">In Progress</option>
//                   <option value="Packed">Packed</option>
//                   <option value="Pending">Pending</option>
//                   <option value="Dispatched">Dispatched</option>
//                   <option value="Ascending">Ascending</option>
//                   <option value="Descending">Descending</option>
//                   <option value="lastAction">Last Action</option>
//                 </select>
//               </div>

//             </div>

//             {loading ? <div className={'order_loadingMain'}>
//               <img src='/wallicon.gif' alt='walliconGIF' />
//             </div> :
//               <>

//                 {data.length !== 0 ?
//                   <div className={styles.dispatchOrders_height}>
//                     <table className={styles.itemTable}>
//                       <thead>
//                         <tr className={styles.item}>
//                           <th>Name & City</th>
//                           <th>Cart Type</th>
//                           <th>Date</th>
//                           <th>Order Id</th>
//                           <th>Packer</th>
//                           <th>Order Manager</th>
//                           <th>Source</th>
//                           <th>Sub-Method</th>
//                           <th>Delivery Partner</th>
//                           <th>Status</th>
//                           <th>Last Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {data && data.map((val, index) =>
//                           <tr key={index}
//                             className={`${styles.item} ${val.reqStatus === "Pending" ? '' : ""}`}
//                             onClick={() => navigate('/dispatchorders/' + val.orderId)}
//                             title={val.reqStatus === "Pending" ? val.reqIssue : ""}
//                           >
//                             <td className={styles.ordersName_truncate}
//                               title={val.storeName + ", " + val.city}
//                             >
//                               <span className={styles.hide}> {val.storeName + ", " + val.city}</span></td>
//                             <td>{val.cartType} </td>
//                             <td>{val.orderDate} </td>
//                             <td>{val.orderId} </td>
//                             <td>{val.packinghManager} </td>
//                             <td>{val.ordermanager} </td>
//                             <td>{val.orderType} </td>
//                             <td>{val.methodName} </td>
//                             <td>{val.partnerName} </td>
//                             <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
//                               styles.table_txt_green : val.status === 'Not Packed' || val.status === 'Request For Edit' || val.status === 'Request For Cancel' ?
//                                 styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending') ?
//                                   styles.table_txt_yellow : val.status === 'New Order' ?
//                                     styles.table_txt_blue : ''}`}>
//                               {val.status}
//                             </td>
//                             <td>{val.lastAction} </td>
//                           </tr>
//                         )}
//                       </tbody>
//                     </table>
//                   </div>
//                   :
//                   <div className={styles.noData}>
//                     No Data Found !
//                   </div>}
//               </>
//             }

//             {data.length !== 0 ?
//               <Paginantion
//                 total={totalPages}
//                 current={page}
//               />
//               :
//               ''
//             }
//           </div>
//         </div>
//       </div>
//       </div>
//     </React.Fragment>
//   )
// }

// export default DispatchDasboard

import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/Auth";
import {
  ReloadIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Admin.module.css";
import styles1 from "../../components/updatedComponents/Test/Test.module.css";

import PaginationComponent from "../../components/updatedComponents/Pagnation/Pagenation";
import { useNavigate, useSearchParams } from "react-router-dom";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import Button from "../../components/updatedComponents/Dealers/Button";
import { Download } from "../../components/icons/Icon4";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import { TemplateNotFound } from "../../components/icons/Icon2";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
const DispatchDasboard = () => {
  const slidingFilter2 = [
    "All",
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const itemList = [
    " All Dealer",
    "Today",
    "Yesterday",
    "This Month",
    "Last 30 Days",
  ];

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { managerId, designation, mode } = useAuth();
  const [data, setData] = useState([]);
  const [daysTab, setDaysTab] = useState("today");
  const [shipingTab, setShipingTab] = useState("all");
  const [cartTab, setCartTab] = useState("all");
  const [orders, setOrders] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [managers, setManagers] = useState([]);                     
  const [selectedManagerID, setSelectedManagerID] = useState("");
  const [selectorderStatus, setSelectOrderStatus] = useState("");

  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1,
  });

  const options = managers.map(manager => ({
    label: manager.name,
    value: manager.managerId
  }));
  const optionsWhaer = warehouses?.map(Whaer => ({
    label: Whaer.warehousename,
  }));


  const dayOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "previousyear",
      label: "Previous Year",
    },
  ];
  const orderHeader = [
    "Name & City",
    "Type",
    "Date",
    "Order ID",
    "Packer",
    "Order Manager",
    "Source",
    "Sub- Method",
    "Delivery Parther",
    "Status",
    "Last Action",
];
  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setDaysTab(selectedFilter?.value || "today");
  };

  const handleWarehouseSelection = (option) => {
    setSelectedWarehouse(option.label);
  };

  const handleWarehouseRejection = () => {
    setSelectedWarehouse(null);
  };



  const handleOrderSelection = (option) => {
    setSelectOrderStatus(option.label);
  };
  const handleOrderRejection = () => {
    setSelectOrderStatus(null);
  };

  const handleManagerSelection = (optionis) => {
    setSelectedManagerID(optionis.value);
  };

  const handleManagerRejection = () => {
    setSelectedManagerID(null);
  };


 

  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: parseInt(managerId),
        keyword: keyword,
        orderStatus: selectorderStatus,
        sortKey: sortKey,
        shippingType: shipingTab,
        cartType: cartTab,
        warehousename: selectedWarehouse,
        disPatchManagerId: selectedManagerID,
        day: daysTab,
        startDate: startDate,
        endDate: endDate,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_URL}dispatch/allOrders?page=${page}&limit=8`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setPage(result?.page);
            setPages(result?.pages);
            setLoading(false);
            setData(result.data);
            setOrders(result.orders);
            if (result.warehouses) {
              setWarehouses(result.warehouses);
            }
            if (result.managers) {
              setManagers(result.managers);
            }
          }
          if (result.pages) {
            setState((prevState) => ({
              ...prevState,
              totalPages: result.pages ? result.pages : "",
            }));
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [
    managerId,
    sortKey,
    keyword,
    page,
    orderStatus,
    shipingTab,
    cartTab,
    warehouseName,
    managerName,
    daysTab,
    startDate,
    endDate,
    selectedManagerID,
    selectedWarehouse,
    selectorderStatus


  ]);

  console.log(data);

  return (

    <React.Fragment>
      <div
        className={styles.mainContiner} >
        <div
          className={`${styles.container3} ${mode ? styles.darkMode : styles.lightMode}`}

          style={{

          }}
        >
          <div className={styles.dispatchTopContainer}>

            <div
              className={styles1.dispatch_top_left}
              style={{

              }}
            >
              <div
                className={styles1.dispatch_top_left_go_back}
                onClick={() => navigate(-1)}
              >
                <TestTopBackArrowIcon
                  className={mode ? styles1.iconDarkMode : styles1.iconLightMode}
                />
                <p
                  className={`${styles1.dispatch_go_back_text} ${mode ? styles1.darkMode : styles1.lightMode}`}          >
                  Go Back
                </p>
              </div>
              <TestTopDotIcon color={mode ? "#E4E7EB" : "#E4E7EB"} />
              <p style={{marginTop:"3px"}}
                className={`${styles1.dispatch_top_left_path_text} ${mode ? styles1.darkMode : styles1.lightMode}`}
              >
                {designation === "superadmin" ? "Dispatch Orders" : "Dashboard"}
              </p>
            </div>
            <div className={styles.slidingFilterDiv}>
              <div className={styles.SlidingFilterdiv1}>
                <SlidingFilter2
                  itemList={dayOptions.map((item) => item.label)}
                  setFilter={handleDayChange}
                  selectedItem={dayOptions.find((item) => item.value === daysTab)?.label}
                />
                <div className={styles.dateInputsDiv}>
                  <div>
                    <InputTypedate
                      text={"From"}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDaysTab("");
                      }}
                    />
                  </div>
                  <div>
                    <InputTypedate
                      text={"To"}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDaysTab("");
                      }}
                    />
                  </div>
                </div>
                <div className={styles.reloadAndDownloadButtonDiv}>
                  <div className={styles.reload_anddownloadbuttondiv}             >
                    <button className={styles.reload_button}
                      onClick={() => window.location.reload()}                    >
                      <span className={styles.reloadTextWrapper}       >
                        <ReloadIcon
                          className={styles.reloadIcon2} />
                      </span>
                      <span className={styles.reload_txt}>Reload</span>
                    </button>
                    <div>
                      {" "}
                      <Button
                        style={{
                          padding: "8px 20px",
                          alignItems: "center",
                        }}
                        buttonBgColor={"#4164E3"}
                        buttonIcon={<Download />}
                        buttonText={"Download"}
                        buttonTextColor={"#fff"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles}>
            <div
              className={styles.statusCard_div}
              style={{
                backgroundColor: mode ? "#232529" : "white",
              }}
            >
              {orders &&
                orders.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.order_filter} ${mode ? styles.order_filter_dark : ""
                      } ${item.status === selectorderStatus ? styles.order_filter_active : ""
                      }`}
                    onClick={() => {
                      setSelectOrderStatus(item.status);
                      if (searchParams.has("page")) {
                        const token = searchParams.get("page");
                        if (token) {
                          searchParams.delete("page");
                          setSearchParams(searchParams);
                        }
                      }
                    }}
                    style={{ minWidth: "140px" }}>
                    <DealerStatusCard
                      dealerStatus={item.status}
                      statusItemCount={item.count}
                      statusText={item.status} />
                  </div>
                ))}
              <span
                className={
                  itemList.status === "New Order"
                    ? styles.statusNew
                    : itemList.status === "In Progress"
                      ? styles.statusYellow
                      : itemList.status === "Packed"
                        ? styles.statusBlue
                        : itemList.status === "All"
                          ? styles.statusAll
                          : itemList.status === "Pending"
                            ? styles.statusRed
                            : itemList.status === "Dispatched"
                              ? styles.statusGreen
                              : itemList.status === "Cancelled"
                                ? styles.statusWhite
                                : ""
                }   >
                {itemList.count}
              </span>
              <span className={styles.statusTxt}>{itemList.status}</span>
            </div>
            <div className={styles.search}
              style={{

              }} >
              <div style={{
                width: "25vh",
              }} >
                <SearchBar
                  placeholder={"Search by Order ID"}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />

              </div>
              <div
                className={styles}
                style={{
                  display: "flex",
                  gap:"5px",
                  // justifyContent: "center",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  // paddingLeft: "5px",
                }}
              >
                <SlidingFilter
                  itemList={slidingFilter2}
                />
                   <div  style={{
                  display: "flex",
                  gap:"5px"
                  // justifyContent: "center",
                  // alignItems: "center",
                  // whiteSpace: "nowrap",
                  // paddingLeft: "5px",
                }}>  <DropDown
                label={"Warehouse"}
                listLabel={"All"}
                options={optionsWhaer}
                onSelect={handleWarehouseSelection}
                onReject={handleWarehouseRejection}
              />
              <DropDown
                label={"Manager"}
                listLabel={"All"}
                options={options}
                onSelect={handleManagerSelection}
                onReject={handleManagerRejection}
              />
              <DropDown
                label={"Sort By"}
                listLabel={"View All"}
                options={orders.map((o) => ({
                  label: o.status,
                }))}
                onSelect={handleOrderSelection}
                onReject={handleOrderRejection}
              /></div>
              </div>
         
            </div>
                      <div className={styles.tablemain}><ul className={styles.order_data_list}>
                        <li
                            className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                                }`}
                        >

                            {orderHeader.map((h) => (
                                <div className={styles.entry} key={h}>
                                    {h}
                                </div>
                            ))}

                        </li>






                        <div className={styles.order_data_list_container}>
                            {loading ?
                                <div style={{ margin: "1rem auto" }}>
                                    <Spinner height={80} width={80} />
                                </div>
                                :
                                data?.length === 0 ? (
                                    <img
                                        src="/assets/updatedAssets/noDATA.png"
                                        alt="img"
                                        style={{
                                            height: "12rem",
                                            width: "16rem",
                                            margin: "1rem auto",
                                        }}
                                    />
                                ) :

                                    <>

{data.map((d, i) => (
                                            <li
                                                key={i}
                                                className={`${styles.order_data_list_row} ${mode ? styles.order_data_list_row_dark : ""
                                                    }`}
                                                    onClick={() => navigate("/dispatchorders/" + d.orderId)}
                                            >
                                                {/* <div className={styles.entry}>
                                                    {i + 1}
                                                </div> */}
                                                <div className={styles.entry}>    {d.storeName + ", " + d.city}</div>
                                                <div className={styles.entry}>Purchase</div>
                                                <div className={styles.entry}>{d.orderDate}</div>
                                                <div className={styles.entry}>{d.orderId}</div>

                                                <div className={styles.entry}>Created by</div>
                                                <div className={styles.entry}>{d.packinghManager}</div>
                                                <div className={styles.entry}>{d.orderType}</div>
                                                <div className={styles.entry}>{d.methodName}</div>
                                                <div className={styles.entry}>{d.partnerName}</div>
                                              
                                                <div
  className={`${styles.entry} ${  
    d.status === "Dispatched"
      ? styles.table_txt_green
      : d.status === "Packed"
      ? styles.table_txt_blue1
      : ["Not Packed", "Request For Edit", "Request For Cancel"].includes(d.status)
      ? styles.table_txt_red
      : d.status === "In Progress"
      ? styles.table_txt_yellow
      : d.status === "Pending"
      ? styles.table_txt_Pending
      : d.status === "New Order"
      ? styles.table_txt_blue
      : d.status === "Cancelled"
      ? styles.table_txt_red
      : ""
  }`}
  style={{ padding: "10px" }}
>
  {d.status}
                                                </div>
                                                <div className={styles.entry}>{d.lastAction}</div>
                                            </li>
                                        ))}
                                    </>
                            }
                        </div>


                    </ul></div>
          </div>
        </div>
        {data.length !== 0 ? (
          <div className={styles.paginationdiv}
            style={{
              backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
            }}    >
            <div className={styles.page1}
            >
              <GoToPage
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
            <div className={styles.page2}>
              <PaginationComponent
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"2"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>

  );
};

export default DispatchDasboard;


