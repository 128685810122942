import React, { useEffect, useRef, useState } from 'react'
import styles from './LocationFilter.module.css'
import { useAuth } from '../../context/Auth'
import { AddBtnicon } from '../../icons/Icon2';
import { truncateText } from '../../../Utils/TextUtils';
const LocationSarchFilter = ({
    list,
    selectFilter,
    setSelectFilter,
    search,
    setSearch,
    text
}) => {
    const { mode } = useAuth();
    // const list = ["KHERI", "LUCKNOW", "Lucknow", "PALI", "TIRUVALLUR",];
    const [toggle, setToggle] = useState(false);
    // const [selectFilter, setSelectFilter] = useState([]);
    // const [search, setSearch] = useState("")

    const IsDropDwon = useRef(null);
    const handleCheck = (event, loction) => {
        if (event.target.checked) {
            setSelectFilter([...selectFilter, loction])
        } else {
            setSelectFilter(selectFilter?.filter((iten) => iten !== loction))
        }

    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (IsDropDwon.current && !IsDropDwon.current.contains(event.target)) {
                setToggle(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.addEventListener("mousedown", handleClickOutside);
        }
    }, [])

    const filteredList = list?.filter((item) =>
        item?.toLowerCase().includes(search?.toLowerCase())
    );


    return (
        <div className={styles.Location_Main_Con} ref={IsDropDwon}>
            <div className={styles.location_drop}>
                <div>
                    <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > {text} </span>
                </div>
                <div className={styles.Who_Select3} style={{
                    background: mode ? "#1B1D21" : "#fff",
                    border: mode ? "none" : "1px solid #E4E7EB",
                }}
                    onClick={() => setToggle(!toggle)}
                >
                    <div  > <span style={{ fontSize: "12px", color: mode ? "#fff" : "#000" }}>
                        {selectFilter?.length === 0 ? "Select" : truncateText(selectFilter[0], 6)}  </span>  </div>
                    <div> <span> <AddBtnicon mode={mode} rotate={toggle ? 180 : 0} />  </span>  </div>
                </div>
            </div>
            {
                toggle &&
                <div className={styles.Location_list1} style={{
                    border: mode ? "none" : "1px solid #E4E7EB",
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#fff" : "#000"
                }} >
                    <div >
                        <input
                            type="text"
                            placeholder="Search..."
                            className={styles.sarchInput1}
                            style={{
                                border: mode ? "none" : "1px solid #E4E7EB",
                                backgroundColor: mode ? "#000" : "#fff",
                                color: mode ? "#fff" : "#000"
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div className={styles.loction_scroll} style={{
                        backgroundColor: mode ? "#232529" : "#fff",
                        color: mode ? "#fff" : "#000"
                    }} >
                        {
                            filteredList?.length === 0 ? <div className={styles.noDatFound}><span>No data Found</span></div> :
                                filteredList?.map((item, index) => {
                                    return (
                                        <div key={index} className={mode ? styles.chack_Loction : styles.chack_Loction_light} >
                                            <div>
                                                <label className={styles.cl_checkbox}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(event) => handleCheck(event, item)}
                                                        checked={selectFilter?.includes(item)}
                                                    />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <div className={styles.text_toi}>
                                                <span> {truncateText(item.toUpperCase(), 10)} </span>
                                            </div>
                                        </div>)
                                })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default LocationSarchFilter