
import React from "react";

import { useAuth } from "../context/Auth";
import { Labelpic } from "../icons/Icon4";
export const FileHandler2 = ({ isImage, setisImage, error }) => {
  const { mode } = useAuth();
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  // Handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // Triggered when a file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      setisImage([files[0]]);
    }
  };
  // console.log("setisImage")

  // Triggered when file is selected via file input
  const handleChange = (e) => {
    e.preventDefault();

    const files = e.target.files;
    if (files && files[0]) {
      // Set the state to contain only the first selected file
      setisImage([files[0]]);
    }
  };

  // Trigger the file input dialog when the button is clicked
  const onButtonClick = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the label
    inputRef.current.click(); // Open the file input dialog
  };

  // Handle file input change
  const handleFileInputChange = (e) => {
    handleChange(e); // Handle the selected file
  };

  return (
    <div  style={{paddingTop:"20px",padding:"20px"}}
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleFileInputChange} // Handle file input change
        style={{ display: "none" }} // Hide the file input field
        accept="image/*" // Only allow image files
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {isImage && isImage.length > 0 ? (
          // If an image is selected, display the preview of the first image
          <img
            src={URL.createObjectURL(isImage[0])} // Preview the first image
            alt="item"
            className="packingImage"
          />
        ) : (
          <>
       <div >     <div 
              style={{
                backgroundColor: mode ? "#1B1D21" : "#F8F9FC",
                borderRadius: "15px",
                // width:"27.5vw",
                
              }}
              className="drag_div"
            >
              <div style={{ width: "50%" }}>
                <Labelpic />
              </div>
              <p
                style={{
                  color: mode ? "#9C9797" : "#646B88",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
                className={
                  error && error.receiptImage ? "alert_drag_txt" : "drag_txt1"
                }
              >
                Drag and drop <br />any file.
              </p>

              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>


                <p>  <p style={{
                  margin: 0,
                  color: mode ? "white" : "black",
                }}>or</p>
                  <button
                    type="button"
                    className={error && error.receiptImage ? 'alert_browseFiles_btn' : 'browseFiles_btn'}
                    onClick={onButtonClick}
                    style={{
                      background: 'none',             // No background
                      border: 'none',                 // No border
                      // color: 'pink',                  // Custom text color
                      color: mode ? "#4164E3" : "#4164E3",

                      textDecoration: 'none',         // Remove any underline
                      padding: 0,                     // Remove default padding
                      font: 'inherit',                // Use the same font as the parent
                      cursor: 'pointer',             // Pointer cursor to indicate it's clickable
                      outline: 'none',                // Remove the default focus outline
                      marginBottom: "0px",            // Remove bottom margin
                      marginLeft: "10px",             // Optional: add space between the button and text
                      marginRight: "10px",            // Optional: add space between the button and text
                    }}
                  >
                    Browse Photos
                  </button>  <p style={{
                    margin: 0,
                    color: mode ? "white" : "black",
                  }}>On your computer</p>
                </p>

              </div>
            </div></div>
          </>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      {error && error.receiptImage && (
        <div className="text-danger d-flex">{error && error.receiptImage}</div>
      )}
    </div>
  );
};








