import React, { useEffect, useRef, useState } from "react";
import styles from "./newdispatch.module.css";
// import styles from './ViewDispatchDetails.module.css

import {  useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import  { useReactToPrint } from "react-to-print";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
} from "../../components/Buttons";
import {
  DeleteFile,
  DeleteIcon,
  DispatchReloadIcon,
  DownArrowCircle,
 
  ShareIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
  UpArrowCircle,
} from "../../components/icons/Icon";
import { FileHandler } from "../../components/DragAndDrop";
import Modal from "../../components/Modal";
import Button from "../../components/updatedComponents/Dealers/Button";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ShareLinkModal from "../../components/updatedComponents/OrderManager/ShareLinkModal";
import { Back, Statusicon } from "../../components/icons/Icon4";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";

const Newviewdispatchdetails = () => {
  const navigate = useNavigate();
  const { switchDisplay, managerId, mode } = useAuth();

  const params = useParams();
  const orderId = params.orderId.includes("saleorder")
    ? params.orderId.split("&")[0]
    : params.orderId;
  const [loading, setLoading] = useState(true);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");


  const [show, setShow] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const [packer, setPacker] = useState(managerId);
  const [couriers, setCouriers] = useState([]);
  const [partners, setPartners] = useState("");
  const [data, setData] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ShareLinkModalSate, setShareLinkModalSate] = useState(false);
  const [isImage, setisImage] = React.useState("");

  const [delivery, setDelivery] = useState({
    courierName: "",
    partnerName: "",
  });
  const [printLabel, setPrintLabel] = useState({
    qtyPacks: "",
    delPerName: "",
    delPerMobile: "",
    delTrackingNo: "",
    delTrackingUrl: "",
    courierSlip: "",
  });
  const [courierSlip, setCourierSlip] = useState();
  // const [detailShow, setDetailShow] = useState(false);
  const [showPackedModal, setShowPackedModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
        courierName: delivery.courierName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
            setPacker(result.data.order.pmId);
            setCouriers(result.data.couriers);
            setPartners(result.data.courierPartners);
            setPrintLabel((prevState) => ({
              ...prevState,
              qtyPacks: result.data.order.qtyPacks,
              delPerName: result.data.order.delPerName,
              delPerMobile: result.data.order.delPerMobile,
              delTrackingNo: result.data.order.delTrackingNo,
              delTrackingUrl: result.data.order.delTrackingUrl,
              courierSlip: result.data.order.courierSlip,
            }));
            if (!delivery.courierName) {
              setDelivery((prevState) => ({
                ...prevState,
                courierName: result.data.order.courierName,
                partnerName: result.data.order.partnerName,
                shippingType: result.data.order.shippingType,
              }));
            }
          }
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [orderId, managerId, delivery.courierName, isReloaded]);

  // -----------------Courier---------//
  const handleDenyEdit = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/editOrderDeny", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };

  const handleAcceptOrder = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      pmId: packer ? packer : managerId,
      courierName: delivery.courierName,
      partnerName: delivery.partnerName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
      
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
              ? 1
              : packs === "2"
              ? 2
              : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  const addFormFields = () => {
    point.forEach((element) => {
      setImages((prevState) => [...prevState, {}]);
    });
  };


  const handlePrintLabel = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      qtyPacks: printLabel.qtyPacks,
      delPerName: printLabel.delPerName,
      delPerMobile: printLabel.delPerMobile,
      delTrackingNo: printLabel.delTrackingNo,
      delTrackingUrl: printLabel.delTrackingUrl,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handlePrint();
          setShowLabelModal(false);
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlePacked = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("packingQty", packs);
    formdata.append("images", images);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
        "packing/orderPacked/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          window.location.reload();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const labelRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
  });

  const handleDispatched = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("shippingType", delivery.shippingType);
    formdata.append("courierName", delivery.courierName);
    formdata.append("partnerName", delivery.partnerName);

    formdata.append("delPerName", printLabel.delPerName);
    formdata.append("delPerMobile", printLabel.delPerMobile);
    formdata.append("delTrackingNo", printLabel.delTrackingNo);
    formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
    formdata.append("image", courierSlip[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_URL +
        "dispatch/deliveryDetails/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDeliveryModal(false);
          setIsReloaded(Math.random());
        }
        alert(result.msg);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isImage) {
      setImages();
    }
  }, [isImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleDispatched();
    }
  };

  const filterItem =
    data.order &&
    data.order.items &&
    data.order.items.length > 0 &&
    data.order.items.filter((item) => item.qty > 0);
  const handleCancelOrder = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      cancelReason: cancelReason,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowCancelOrderModal(false);
          window.location.reload();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.error(error));
  };
  const handleDenyCancel = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/canceleOrderDeny",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <React.Fragment>
      <Modal
        show={showPackedModal}
        close={setShowPackedModal}
        closeBtn={true}
        heading="PACKING STATUS"
        content={
          <form onSubmit={handlePacked} className={styles.text_center}>
            <div className={styles.main_div}>
              <div>
                <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                <input
                  type="number"
                  name="packs"
                  value={packs}
                  // setPacks
                  onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                  onChange={(e) => {
                    setPacks(e.target.value);

                    if (
                      check &&
                      e.target.value &&
                      images.length > parseInt(e.target.value)
                    ) {
                      const values = [...images];
                      values.splice(
                        point,
                        images.length -
                          (parseInt(e.target.value) === 0
                            ? 1
                            : parseInt(e.target.value))
                      );
                      setImages(values);
                    }
                  }}
                  className={styles.enterPackets}
                  required
                  autoFocus={true}
                />
              </div>

              <div className={styles.checkBox_addImage}>
                <input
                  type="checkbox"
                  name="vehicle1"
                  value="check"
                  className={styles.checkbox}
                  checked={check ? true : false}
                  onChange={(e) => {
                    if (e.target.checked && packs) {
                      addFormFields();
                      setCheck(true);
                    } else {
                      const values = [...images];
                      values.splice(point, point.length);
                      setImages(values);
                      setCheck(false);
                    }
                  }}
                />
                <label htmlFor="vehicle1">
                  &nbsp;&nbsp;Add image of every pack
                </label>
              </div>

              <div className={styles.dragImage_div}>
                <FileHandler setisImage={setisImage} styles={styles} />
              </div>

              <div>
                {images.map((item, index) => (
                  <div key={index} className={styles.flexdiv}>
                    <span>Pack {index + 1}</span>

                    {item.name ? (
                      <img
                        src={URL.createObjectURL(item)}
                        alt=""
                        className={styles.fileimg}
                        onClick={() =>
                          window.open(
                            URL.createObjectURL(item),
                            "mozillaWindow",
                            "popup"
                          )
                        }
                      />
                    ) : (
                      <img
                        src="/assets/packingImage.png"
                        alt="packing_order_image"
                        className={styles.packingImage}
                      />
                    )}

                    <div
                      className={styles.view_packingImage}
                      onClick={() =>
                        window.open(
                          URL.createObjectURL(item),
                          "mozillaWindow",
                          "popup"
                        )
                      }
                    >
                      View
                    </div>

                    <div
                      className={styles.deletediv}
                      onClick={(e) => {
                        let data = [...images];
                        data[index] = {};
                        setImages(data);
                      }}
                    >
                      <DeleteFile color={"red"} />
                      <p className={styles.deleteicon}>Delete</p>
                    </div>
                    {images.length !== 1 && (
                      <div
                        className={styles.deletediv}
                        onClick={(e) => {
                          setPacks(parseInt(packs) - 1);
                          const list = [...images];
                          list.splice(index, 1);
                          setImages(list);
                        }}
                      >
                        <span className={styles.crossIcon}> &#10006;</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.packingModal_btn}>
              <RedOutButton
                btnType="button"
                title="CANCEL"
                handleSubmit={() => setShowPackedModal(false)}
              />
              <GreenButton btnType="submit" title="OK" />
            </div>
          </form>
        }
      />
      <div>
        <ModalWrapper
          isOpen={showLabelModal}
          onClose={() => setShowLabelModal(!showLabelModal)}
          style={{
            borderRadius: "10px", 
            overflow: "hidden", 
          }}
        >
          <div
            className={styles.text_center}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#fff",
              color: mode ? "#fff" : "#000",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "20px",
                color: mode ? "#fff" : "#000",
              }}
              onClick={() => setShowLabelModal(false)}
              title="Close"
            >
              &times;
            </div>
            <p
              style={{
                fontSize: "25px",
                color: "#646B88",
                fontWeight: "bold", 
              }}
            >
              Print Label
            </p>
            <p className={styles.sliptext}>
              Are you sure you want to Print Label & Marked as Packed?
            </p>
            <div className={styles.modal_div}>
              <p className={styles.sliptext}>Enter Number Of Packs</p>
              <input
                name="name"
                type="number"
                className={`${
                  switchDisplay ? styles.inputcolorpack : styles.inputpack
                }`}
                value={printLabel.qtyPacks}
                onChange={(e) =>
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    qtyPacks: e.target.value,
                  }))
                }
                style={{
                  backgroundColor: "#fff", 
                  color: "#000", 
                  borderRadius: "5px", 
                  padding: "10px",
                  width: "10%", 
                  boxSizing: "border-box",
                  border: "1px solid #ccc", 
                }}
              />
            </div>

            {show && (
              <div>
                <div className={styles.modal_div}>
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    className={`${
                      switchDisplay ? styles.inputcolor : styles.input
                    }`}
                    value={printLabel.delPerName}
                    onChange={(e) =>
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerName: e.target.value,
                      }))
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "5px",
                      padding: "10px",
                      width: "100%",
                      marginBottom: "10px", 
                    }}
                  />
                  <input
                    name="mobile"
                    type="number"
                    placeholder="Mobile Number"
                    className={`${
                      switchDisplay ? styles.inputcolor : styles.input
                    }`}
                    value={printLabel.delPerMobile}
                    onChange={(e) =>
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerMobile: e.target.value,
                      }))
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "5px",
                      padding: "10px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  />
                </div>
                <div className={styles.modal_div}>
                  <input
                    name="name"
                    type="text"
                    placeholder="Tracking Number"
                    className={`${
                      switchDisplay ? styles.inputcolor : styles.input
                    }`}
                    value={printLabel.delTrackingNo}
                    onChange={(e) =>
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingNo: e.target.value,
                      }))
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "5px",
                      padding: "10px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  />
                  <input
                    name="name"
                    type="text"
                    placeholder="Tracking URL"
                    className={`${
                      switchDisplay ? styles.inputcolor : styles.input
                    }`}
                    value={printLabel.delTrackingUrl}
                    onChange={(e) =>
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingUrl: e.target.value,
                      }))
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "5px",
                      padding: "10px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              </div>
            )}

            <div
              className={styles.modalBtn_div}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Button
                buttonText={"Cancel"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowLabelModal(false)}
                style={{
                  width: "150px",
                  height: "50px",
                  borderRadius: "5px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              />
              <div>
                <Button
                  css={!printLabel.qtyPacks ? styles.disableBtn : ""}
                  buttonTextColor={"white"}
                  buttonBgColor={"blue"}
                  buttonText={"PRINT"}
                  disabled={!printLabel.qtyPacks}
                  buttonClick={() => handlePrintLabel()}
                  style={{
                    width: "150px",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                />
              </div>
            </div>

            {/* Toggle Delivery Details */}
            <div
              className={styles.modal_div}
              onClick={() => setShow(!show)}
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              {show ? (
                <p className={styles.sliptext}>Add Delivery Details</p>
              ) : (
                <p className={styles.sliptext}>Hide Delivery Details</p>
              )}
              <div>
                {show ? (
                  <DownArrowCircle
                    color={switchDisplay ? "#000000" : "#FFFFFF"}
                  />
                ) : (
                  <UpArrowCircle
                    color={switchDisplay ? "#000000" : "#FFFFFF"}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalWrapper>
      </div>
    
      <ModalWrapper isOpen={ShareLinkModalSate} onClose={()=>setShareLinkModalSate(!ShareLinkModalSate)}>

      <ShareLinkModal  managerId={2} isModalOpen={true} mode={mode} generatedLink={"UTFUTFDUTDYTREYR"} mobileNo={data?.order?.mobile} email={data?.order?.email}/>
      </ModalWrapper>
      <Modal
        show={showDenyModal}
        close={setShowDenyModal}
        closeBtn={true}
        heading={
          data.order &&
          (data.order.reqType === "Request For Cancel"
            ? "DENY CANCEL ORDER REQUEST"
            : "DENY EDIT ORDER REQUEST")
        }
        content={
          <div className={styles.text_center}>
            <p className={styles.message}>
              Are you sure you want to Deny this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="NO"
                handleSubmit={() => setShowDenyModal(false)}
              />
              <GreenButton
                title="YES"
                handleSubmit={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleDenyCancel();
                  } else {
                    handleDenyEdit();
                  }
                }}
              />
            </div>
          </div>
        }
      />
      <div
        className={styles.text_center}
        style={{
          backgroundColor: mode ? "#2C2E33" : "#fff",
          color: mode ? "#fff" : "#000",
        }}
      ></div>
      <ModalWrapper
        isOpen={showCancelOrderModal}
        onClose={() => setShowCancelOrderModal(!showCancelOrderModal)}
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {/* Close Button */}
        <button
          onClick={() => setShowCancelOrderModal(false)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            color: mode ? "#fff" : "#000",
            fontSize: "16px",
            cursor: "pointer",
          }}
          aria-label="Close"
        >
          &times; {/* Unicode for "X" */}
        </button>

        <div
          style={{
            backgroundColor: mode ? "#2C2E33" : "#fff",
            color: mode ? "#fff" : "#000",
            borderRadius: "10px",
            padding: "20px",
          }}
          className={styles.text_center}
        >
          <p style={{ fontWeight: "bold" }}> CANCEL ORDER</p>
          <p className={styles.message}>
            Are You Sure Want To Cancel This Order?
          </p>
          <textarea
            type="text"
            placeholder="Enter Cancel Reason"
            className={styles.reqIssue}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
              boxSizing: "border-box",
              textAlign: "center",
              lineHeight: "2",
            }}
          />
          <div
            className={styles.massageModal_btn}
            style={{ display: "flex", justifyContent: "center", gap: "50px" }}
          >
            <div style={{ width: "80px" }}>
              <Button
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowCancelOrderModal(false)}
              />
            </div>

            <div style={{ width: "80px" }}>
              <Button
                buttonText={"Yes"}
                buttonTextColor={"white"}
                buttonBgColor={"blue"}
                buttonClick={() => {
                  handleCancelOrder();
                }}
                style={{
                  backgroundColor: "blue",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>

        <Modal
          show={showPackedModal}
          close={setShowPackedModal}
          closeBtn={true}
          heading="PACKING STATUS"
          content={
            <form onSubmit={handlePacked} className={styles.text_center}>
              <div className={styles.main_div}>
                <div>
                  <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                  <input
                    type="number"
                    name="packs"
                    value={packs}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 4))
                    }
                    onChange={(e) => {
                      setPacks(e.target.value);

                      if (
                        check &&
                        e.target.value &&
                        images.length > parseInt(e.target.value)
                      ) {
                        const values = [...images];
                        values.splice(
                          point,
                          images.length -
                            (parseInt(e.target.value) === 0
                              ? 1
                              : parseInt(e.target.value))
                        );
                        setImages(values);
                      }
                    }}
                    className={styles.enterPackets}
                    required
                    autoFocus={true}
                  />
                </div>

                <div className={styles.checkBox_addImage}>
                  <input
                    type="checkbox"
                    name="vehicle1"
                    value="check"
                    className={styles.checkbox}
                    checked={check ? true : false}
                    onChange={(e) => {
                      if (e.target.checked && packs) {
                        addFormFields();
                        setCheck(true);
                      } else {
                        const values = [...images];
                        values.splice(point, point.length);
                        setImages(values);
                        setCheck(false);
                      }
                    }}
                  />
                  <label htmlFor="vehicle1">
                    &nbsp;&nbsp;Add image of every pack
                  </label>
                </div>

                <div className={styles.dragImage_div}>
                  <FileHandler setisImage={setisImage} styles={styles} />
                </div>

                <div>
                  {images.map((item, index) => (
                    <div key={index} className={styles.flexdiv}>
                      <span>Pack {index + 1}</span>

                      {item.name ? (
                        <img
                          src={URL.createObjectURL(item)}
                          alt=""
                          className={styles.fileimg}
                          onClick={() =>
                            window.open(
                              URL.createObjectURL(item),
                              "mozillaWindow",
                              "popup"
                            )
                          }
                        />
                      ) : (
                        <img
                          src="/assets/packingImage.png"
                          alt="packing_order_image"
                          className={styles.packingImage}
                        />
                      )}

                      <div
                        className={styles.view_packingImage}
                        onClick={() =>
                          window.open(
                            URL.createObjectURL(item),
                            "mozillaWindow",
                            "popup"
                          )
                        }
                      >
                        View
                      </div>

                      <div
                        className={styles.deletediv}
                        onClick={(e) => {
                          let data = [...images];
                          data[index] = {};
                          setImages(data);
                        }}
                      >
                        <DeleteFile color={"red"} />
                        <p className={styles.deleteicon}>Delete</p>
                      </div>
                      {images.length !== 1 && (
                        <div
                          className={styles.deletediv}
                          onClick={(e) => {
                            setPacks(parseInt(packs) - 1);
                            const list = [...images];
                            list.splice(index, 1);
                            setImages(list);
                          }}
                        >
                          <span className={styles.crossIcon}> &#10006;</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.packingModal_btn}>
                <RedOutButton
                  btnType="button"
                  title="CANCEL"
                  handleSubmit={() => setShowPackedModal(false)}
                />
                <GreenButton btnType="submit" title="OK" />
              </div>
            </form>
          }
        />
      </ModalWrapper>

      <Modal
        show={showDeliveryModal}
        close={setShowDeliveryModal}
        closeBtn={true}
        heading={
          data.order && data.order.dispatchStatus === "Dispatched"
            ? "UPDATE DELIVERY DETAILS"
            : "DELIVERY DETAILS"
        }
        content={
          <div className={styles.text_center}>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_div}>
                <select
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={delivery.shippingType}
                  onChange={(e) =>
                    setDelivery((prevState) => ({
                      ...prevState,
                      shippingType: e.target.value,
                    }))
                  }
                  required
                >
                  <option value="" hidden>
                    Delivery Type
                  </option>
                  <option>Standard</option>
                  <option>Express</option>
                </select>
                <select
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={delivery.courierName}
                  onChange={(e) =>
                    setDelivery((prevState) => ({
                      ...prevState,
                      courierName: e.target.value,
                    }))
                  }
                  required
                >
                  <option value="" hidden>
                    Delivery Method
                  </option>
                  {couriers &&
                    couriers.map((obj, index) => (
                      <option key={index} value={obj.courierName}>
                        {obj.courierName}
                      </option>
                    ))}
                </select>
                <select
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={delivery.partnerName}
                  onChange={(e) => {
                    setDelivery((prevState) => ({
                      ...prevState,
                      partnerName: e.target.value,
                    }));
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === e.target.value
                        )?.partnerName,
                      delPerMobile:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === e.target.value
                        )?.contactNumber,
                      delTrackingNo:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === e.target.value
                        )?.trackingNumber,
                      delTrackingUrl:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === e.target.value
                        )?.trackingUrl,
                    }));
                  }}
                  required
                >
                  <option value="" hidden>
                    Delivery Partner
                  </option>
                  {partners.partners &&
                    partners.partners.map((obj, index) => (
                      <option key={index} value={obj.partnerName}>
                        {obj.partnerName}
                      </option>
                    ))}
                </select>
              </div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={printLabel.delPerName}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName: e.target.value,
                    }));
                  }}
                />
                <input
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={printLabel.delPerMobile}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerMobile: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking Number"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={printLabel.delTrackingNo}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingNo: e.target.value,
                    }));
                  }}
                />
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking URL"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  value={printLabel.delTrackingUrl}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingUrl: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className={styles.dragImage_div}>
                <FileHandler setisImage={setCourierSlip} styles={styles} />
              </div>

              <div className={styles.uploadImage_div}>
                <div className={styles.demoImage_div}>
                  {courierSlip || printLabel.courierSlip ? (
                    <img
                      src={
                        courierSlip
                          ? URL.createObjectURL(courierSlip[0])
                          : process.env.REACT_APP_S3URL + printLabel.courierSlip
                      }
                      className={styles.modalItem_Image}
                      alt="courierSlip"
                    />
                  ) : (
                    <img
                      src="/assets/packingImage.png"
                      className={styles.modalItem_Image}
                      alt="courier"
                    />
                  )}
                </div>
                <label className={styles.uploadImage_btn}>
               
                </label>
                <div
                  className={styles.delete_div}
                  onClick={() => {
                    setCourierSlip("");
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      courierSlip: "",
                    }));
                  }}
                >
                  <span>
                    <DeleteIcon />
                  </span>
                  <span>Delete</span>
                </div>
              </div>

              <div className={styles.modalBtn_div}>
                <RedOutButton
                  btnType="button"
                  title={"CANCEL"}
                  handleSubmit={() => {
                    setShowDeliveryModal(false);
                  }}
                />
                <GreenOutButton
                  title={
                    data.order && data.order.dispatchStatus === "Dispatched"
                      ? "UPDATE DISPATCHED"
                      : "DISPATCHED"
                  }
                />
              </div>
            </form>
          </div>
        }
      />
      {loading ? (
       <div style={{ width: "100%", height: "100%", padding: "10px", }}>
       <div style={{ padding: "10px", backgroundColor: mode ? "" : "#fff", width: "100%", height: "100%", position: "relative" }}>
         <div style={{ display: "flex", justifyContent: "space-between", }}>
           <div style={{ width: "100%" }}>  <div style={{ width: "200px" }}><Skeleton lines={1} thickness={4} /></div></div>
           <div style={{ width: "100%" }}>  <div style={{ width: "200px", float: "right" }}><Skeleton lines={1} thickness={4} /></div></div>
         </div>
         <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
           <div style={{ width: "100%" }}>  <div style={{ width: "80%" }}><Skeleton lines={8} thickness={2} /></div></div>
           <div style={{ width: "100%" }}>  <div style={{ width: "80%", float: "right" }}><Skeleton lines={8} thickness={2} /></div></div>
         </div>
         <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
           <div style={{ width: "100%" }}>  <div style={{ width: "100%" }}><Skeleton lines={8} thickness={3} /></div></div>
         </div>
       
       </div>

     </div>
      ) : (
        
        <div className={styles.mainCon}>
          <form
            style={{ backgroundColor:mode ?"#2C2E33":"#F8F9FC"}}
            onSubmit={handleAcceptOrder}
            className={styles.mainContainer}
          >
      
                <div
                className={styles.dispatch_top_left}
                style={{
                  position: "relative",
                  top: "-5px",
                  // marginTop: "1px",
                  paddingTop: "0",
                  width: "100%",
                  display: "flex",

                  justifyContent: "space-between",
                  backgroundColor: mode ? "#2C2E33" : "#F8F9FC",

                  padding: "10px",
                }}
              >
             

                <div className={styles.mgx_top}>
                  <div className={styles.mgx_top_left}>
                    <div
                      className={styles.mgx_top_left_go_back}
                      onClick={() => navigate(-1)}
                    >
                      <TestTopBackArrowIcon
                        color={mode ? "#749AFF" : "#4164E3"}
                      />
                      <p
                        className={styles.mgx_top_left_go_back_text}
                        style={{
                          color: mode ? "#749AFF" : "#4164E3",
                        }}
                      >
                        Go Back
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "7px",
                      }}
                    >
                      {" "}
                      <div style={{
                        marginTop:"1px",
                      }}>
                        <TestTopDotIcon color={mode ? "#E4E7EB" : "#E4E7EB"} />
                      </div>
                      <div>
                        <p
                          className={styles.mgx_top_left_path_text}
                          style={{
                            color: mode ? "#9C9797" : "#646B88",
                            marginTop:"5px"
                          }}
                        >
                          Packing Voucher
                         
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    data.order.dispatchStatus === "New Order"
                      ? styles.status_blue
                      : data.order.dispatchStatus === "Packed" ||
                        data.order.dispatchStatus === "Dispatched"
                      ? styles.statuscolor3
                      : data.order.dispatchStatus === "Not Packed"
                      ? styles.statuscolor1
                      : data.order.dispatchStatus === "In Progress" ||
                        data.order.dispatchStatus === "Pending"
                      ? styles.status
                      : ""
                  }`}
                  style={{
                 
                    padding: "5px 8px",
                    backgroundColor: mode ? "#1B1D21" : "white",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    width: "15vh",
                    justifyContent: "center",
                    height: "5vh",
                  }}
                >
                  <div
                    style={{
                    }}
                  >
                    {" "}
                    <Statusicon status={data?.order?.dispatchStatus}/>
                  </div>{" "}
                  {data.order.dispatchStatus}
                </div>
              </div>

          <div style={{
            paddingTop: "0",  
            paddingLeft: "10px",  
            paddingRight: "10px",  
            paddingBottom: "10px",  
          }}> 

           <div
              style={{
                backgroundColor: mode ? "#232529":"white",
                borderRadius: "20px",
                padding: "5px 0px",
                color:mode? "white":"black",
                // border:"19px solid red"
               
              // marginBottom:"20px",
              }}
            >
         
              <div style={{
                display:"flex",
                justifyContent:"space-between",
                padding:"3px",
              
              }}>
                <div style={{  gap:"3px",}} className={styles.voucherFlexDirection}>
                  <h2 className={styles.slipheading_div}>
                    {data.order && data.order.storeName}
                  </h2>
                  <span style={{                color:mode? "white":"black",
}}  className={styles.sliptext}>
                    Ship To:&nbsp;
                    <b>
                      {data.order.shippingInfo && data.order.shippingInfo.name}
                    </b>
                  </span>
                  <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                    Address:&nbsp;
                    <b>
                      {data.order.shippingInfo &&
                        data.order.shippingInfo.street +
                          ", " +
                          data.order.shippingInfo.city +
                          ", " +
                          data.order.shippingInfo.state +
                          ", " +
                          data.order.shippingInfo.pincode}
                    </b>
                  </span>
                  <span style={{                color:mode? "white":"black",
}} className={styles.sliptextGustin}>
                    GSTUIN:&nbsp;<b>{data.order && data.order.gstuin}</b>
                  </span>
                  <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                    Contact:&nbsp;
                    {data.order.shippingInfo && data.order.shippingInfo.mobile}
                  </span>
                  <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                    Order Manager:&nbsp;{data.order && data.order.ordermanager}
                  </span>
                  <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                    Packing Manager:&nbsp;
                    {data.order && data.order.packermanager}
                  </span>
                  
                </div>
                <div style={{
                  alignItems:"end",
                }} className={styles.packingVoucher_left}>
                  <div className={styles.voucher_flex}>
                  
                      <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                      Voucher Number:&nbsp;
                    {data.order && data.order.orderId}
                  </span>
                  </div>
                  <div className={styles.voucher_flex}>
                  
                      <span style={{                color:mode? "white":"black",
}} className={styles.sliptext}>
                      Voucher Date:&nbsp;
                    {data.order && data.order.orderDate}
                  </span>
                    {/* </span> */}
                  </div>
                  <div className={styles.voucher_flex}>
                  
                    <span  style={{                color:mode? "white":"black",
}}className={styles.sliptext}>
                    Delivery Type:&nbsp;
                    {data.order && data.order.shippingType}
                  </span>
                   
                  </div>
                  <div className={styles.voucher_flex}>
                    
                    <span style={{                color:mode? "white":"black",
}}className={styles.sliptext}>
                    Sub Method:&nbsp;
                    {data.order && data.order.partnerName}
                  </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    
                    <span  style={{                color:mode? "white":"black",
}}className={styles.sliptext}>
                    Delivery Method:&nbsp;
                    {data.order && data.order.courierName}
                  </span>
                  
                  </div>
                  <div className={styles.voucher_flex}>
                  
                    <span  style={{                color:mode? "white":"black",
}}className={styles.sliptext}>
                    Order Manager:&nbsp;
                    {data.order && data.order.managercontact}
                  </span>
                   
                  </div>
                 
                  <div className={styles.voucher_flex}>
                  
                    <span style={{                color:mode? "white":"black",
}}className={styles.sliptext}>
                    Paking Manager:&nbsp;
                    {data.order && data.order.pmcontact}
                  </span>
                  </div>
                </div>
              </div>
            </div></div>

            <div style={{
              padding:"5px",
            }}>
              <div
                style={{
                  backgroundColor:mode? "#2C2E33":"#F8F9FC",
                 
                  borderRadius: "20px", 
                }}
                className={styles.mainPack_delivery_image}
              >
               <div style={{
                backgroundColor:mode? "#232529":"white",

                borderRadius:"20px",
               }}> <div style={{
                padding:"10px",
               }} className={styles.pack_image_div}>
                  <span style={{                color: mode ?"white":"#626884",
}} className={styles.DeliveryDetailsTxt}>
                    Delivery Detail Slip
                  </span>
                  {data.order.courierSlip ? (
                    <img
                      src={process.env.REACT_APP_S3URL + data.order.courierSlip}
                      alt="delivery__slip"
                      className={styles.delivery_image}
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_S3URL + data.order.courierSlip,
                          "mozillaWindow",
                          "popup"
                        )
                      }
                    />
                  ) : (
                    <div className={styles.delivery_image}>No Data</div>
                  )}
                </div></div>
               <div style={{
                padding:"10px",
                backgroundColor:mode? "#232529":"white",
                borderRadius:"20px",
                marginTop:"10px",
               }}> {data.order.packingImgs &&
                  data.order.packingImgs.map((item, i) => (
                    <div key={i} className={styles.pack_image_div}>
                      <span  style={{                color: mode ?"white":"#626884",
}} className={styles.DeliveryDetailsTxt}>
                        Pack {i + 1}
                      </span>
                      <img
                        key={i}
                        src={process.env.REACT_APP_S3URL + item}
                        alt="pack_image"
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_S3URL + item,
                            "mozillaWindow",
                            "popup"
                          )
                        }
                        className={styles.delivery_image}
                      />
                    </div>
                  ))}</div>
             <div style={{
  backgroundColor: mode ? "#232529" : "white",
  borderRadius: "20px",
  padding: "10px",
  marginTop: "10px"
}}>
  <div className="w-100 d-flex flex-wrap" style={{ gap: "16px" }}>
    {filterItem &&
      filterItem.map((item, index) => (
        <div key={index} style={{ width: "100%" }}>
          <div style={{
              color: mode ?" #626884":"white",

          }}  className="d-flex flex-column mb-2">
            {item.product_name && (
              <span  style={{
                color: mode ?"white":"#626884",
                
  
            }} >Category: {item.product_name}</span>
            )}
            <span style={{                color: mode ?"white":"#626884",
            fontWeight:"500"
}}>
              Item No: {item.itemNo ? item.itemNo : item.accessoriesName}
            </span>
          </div>
          
          <div
            className={styles.item_card}
            style={{
              border: "1px solid #ccc",
              borderRadius: "20px",
              padding: "10px",
              overflow: "hidden",
            }}
          >
            <div className="d-flex flex-row w-100 mb-2">
              <img
                src={process.env.REACT_APP_S3URL + item.thumbnail}
                alt={process.env.REACT_APP_S3URL + item.thumbnail}
                className={styles.item_image}
                style={{
                  borderRadius: "10px",
                }}
              />
            </div>

            {item.packingImg && (
              <div className={styles.item_pack_div}>
                <img
                  src={process.env.REACT_APP_S3URL + item.packingImg}
                  alt="items_pack_image"
                  className={styles.main_pack_image}
                  style={{
                    borderRadius: "10px",
                  }}
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_S3URL + item.packingImg,
                      "mozillaWindow",
                      "popup"
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      ))}
  </div>
</div>
         </div>
            </div>
            <div style={{backgroundColor:mode ? "#2C2E33CC": " white"}}   className={styles.button_div}>
            <div className={styles.btn_flex} >
        <div style={{width:"80px",}}>      <Button
              buttonIcon={<Back/>}
                btnType="button"
                buttonText={"BACK"}
            buttonBgColor={"black"}
            buttonTextColor={"white"}
                buttonClick={() => {
                  navigate(-1);
                }}
              /></div>
             <div > {orderId && !orderId.includes("saleorder") && (
                
                <Button
                buttonIcon={<DispatchReloadIcon/>}
                  btnType="button"
                  buttonText={"UPDATE"}
                 buttonBgColor={"black"}
                 buttonTextColor={"white"}
                  disabled={
                    data.order.dispatchStatus === "Cancelled" ? true : false
                  }
                 buttonClick={() => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === delivery.partnerName
                        )?.partnerName,
                      delPerMobile:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === delivery.partnerName
                        )?.contactNumber,
                      delTrackingNo:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === delivery.partnerName
                        )?.trackingNumber,
                      delTrackingUrl:
                        partners &&
                        partners.partners.find(
                          (x) => x.partnerName === delivery.partnerName
                        )?.trackingUrl,
                    }));
                    setShowDeliveryModal(true);
                  }}
                />
              )}</div>
              <ModalWrapper
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                noBtn
              >
                {"modalContent"}
              </ModalWrapper>

              <div
                style={{
                  width: "80px",
                }}
              >
                <Button
                buttonIcon={<ShareIcon/>}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"white"}
                  buttonText={"Share"}
                  buttonClick={()=>setShareLinkModalSate(!ShareLinkModalSate)}
                  btnType="button"
                  title="SHARE"
                />
              </div>
            </div>
          </div>
          </form>
         </div>
  
      )}
    </React.Fragment>
  );
};

export default Newviewdispatchdetails;
