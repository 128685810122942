// import React, { useEffect } from 'react'
// import { useState } from 'react'
// import { useNavigate, useSearchParams } from 'react-router-dom'
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
// import { useAuth } from '../../components/context/Auth'
// import { DeleteIcon, Edit, View } from '../../components/icons/Icon'
// import Modal from '../../components/Modal'
// import styles from './Product.module.css'

// const Items = () => {
//     const navigate = useNavigate()
//     const { managerId } = useAuth()
//     const [data, setData] = useState([])
//     const product_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2]
//     const collection_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
//     const [searchParams] = useSearchParams();
//     const getId = searchParams.get("product_id");
//     const product_id = (!getId || getId === null) ? "" : getId
//     const collId = searchParams.get("id");
//     const collection_id = (!collId || collId === null) ? "" : collId
//     const [loading, setLoading] = useState(false)
//     const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
//     const [deleteItemId, setDeleteItemId] = useState('')
//     const [deleteItemTrue, setDeleteItemTrue] = useState(false)
//     const [deleteItemName, setDeleteItemName] = useState('')

//     useEffect(() => {
//         setLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "collection_id": collection_id,
//             "product_id": product_id
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "content/Items", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     setLoading(false)
//                     setData(result.data)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }, [product_id, collection_id, deleteItemTrue])

//     const handleDeleteItem = (deleteItemId) => {
//         if (managerId) {
//             setDeleteItemTrue(false)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "_id": deleteItemId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "content/deleteItem", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     // alert(result.msg)
//                     if (result.status === 'success') {
//                         setDeleteItemTrue(true)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }

//     return (
//         <React.Fragment>
//             <Modal
//                 show={showDeleteItemModal}
//                 close={setShowDeleteItemModal}
//                 closeBtn={true}
//                 heading={deleteItemName&&deleteItemName}
//                 content={
//                     <div className={styles.text_center}>
//                         <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteItemName&&deleteItemName}</p></div>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteItemModal(false)} />
//                             <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteItem(deleteItemId); setShowDeleteItemModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />
//             <div className={styles.product_main_div}>
//                 <div className={styles.product_header}>
//                     <div >
//                         <u onClick={() => navigate(-1)}>
//                             &lt; Go Back
//                         </u>
//                         <h1>{product_name} {'>'} {collection_name}</h1>
//                     </div>
//                     <div>
//                         Sort By
//                         <select className={styles.product_filter}>
//                             <option className={styles.bg_dark}>View All</option>
//                             <option className={styles.bg_dark}>Active</option>
//                             <option className={styles.bg_dark}>Disabled</option>
//                             <option className={styles.bg_dark}>Deleted</option>
//                             <option className={styles.bg_dark}>Ascending</option>
//                             <option className={styles.bg_dark}>Descending</option>
//                         </select>

//                         <GreenOutButton type="button" title="+ ADD ITEM" css="" handleSubmit={() => navigate('/item/' + product_name + "/" + collection_name + "?product_id=" + product_id + "&collection_id=" + collection_id)} />
//                     </div>
//                 </div>
//                 <div className={styles.product_scroll_div}>
//                     {
//                         loading ? <div className={'loadingMainDealer'}>
//                             <img src='/wallicon.gif' alt='walliconGIF' />
//                         </div> :
//                             <>
//                                 {data && data.map((item, index) =>
//                                     <div className={styles.productRow} key={index}>
//                                         <div className={styles.w_100} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
//                                             <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
//                                             <h1>
//                                                 {item.itemNo}
//                                             </h1>
//                                         </div>
//                                         <div className={styles.productBtns}>
//                                             <div className={styles.productsEVS} onClick={() => { setShowDeleteItemModal(true); setDeleteItemId(item._id); setDeleteItemName(item.itemNo) }}>
//                                                 <div><DeleteIcon /></div>
//                                                 <span>Delete</span>
//                                             </div>
//                                             <div className={styles.productsEVS} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
//                                                 <div><Edit color="#ffffff" /></div>
//                                                 Edit
//                                             </div>
//                                             <div className={styles.productsEVS} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
//                                                 <div><View /></div>
//                                                 View
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}
//                             </>
//                     }
//                 </div>
//             </div>
//         </React.Fragment >
//     )
// }

// export default Items






import React, { useEffect, useState } from 'react'
import styles from './Product.module.css'
import { useAuth } from '../../components/context/Auth'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import Button from '../../components/updatedComponents/Dealers/Button';
import DropDown from '../../components/updatedComponents/FormComponents/DropDown';
import { AddTO, DeleteTo, EditeTo, SettingTo, ViewTo } from '../../components/icons/Icon3';
import ManagerModal from '../../components/updatedComponents/Manager/ManagerModal';
import { TextColorCode } from '../../Utils/TextUtils';
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage';
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper';
import { useParams } from 'react-router-dom';
import fetchData from '../../APIsControll/apiControll';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound';
import { TemplateNotFound } from '../../components/icons/Icon2';
const Items = () => {
    const { mode, managerId } = useAuth();
    const { collection, product } = useParams();
    const { show } = useToast()
    const product_id = new URLSearchParams(window.location.search).get("productId");
    const collection_Id = new URLSearchParams(window.location.search).get("collectionId");
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState({
        items: false
    });
    const [modelContainer, setModeContiner] = useState(null);
    const [data, setData] = useState([]);

    const handleSetModel = (con) => {
        setToggle({ ...toggle, modelToggle: true });
        setModeContiner(con);
    }
    async function getItems() {
        try {
            setLoading({ ...loading, items: true });
            const payload = {
                collection_id: product_id,
                product_id: collection_Id
            }
            const res = await fetchData(`content/Items`, payload)
            if (res.status === "success") {
                setData(res?.data)
                return
            }
            if (res.status === "failed") {
                show(res.msg, "error");
                return
            }
        } catch (error) {
            show('', "error");
        } finally {
            setLoading({ ...loading, items: false });
        }

    }
    useEffect(() => {
        getItems();
    }, [])

    const DeleteProduct = async (id) => {
        try {
            setLoading({ ...loading, items: true });
            const payload = {
                managerId: managerId,
                _id: id
            }
            const res = await fetchData(`content/deleteItem`, payload)
            if (res.status === "success") {
                getItems();
                return
            }
            if (res.status === "failed") {
                show(res.msg, "error");
                return
            }
        } catch (error) {
            show('', "error");
        } finally {
            setLoading({ ...loading, items: false });
        }
    }


    return (
        <div className={styles.Main_Layout} >
            <ModalWrapper isOpen={toggle.modelToggle} onClose={() => setToggle({ ...toggle, modelToggle: !toggle.modelToggle })}>
                {modelContainer}
            </ModalWrapper>
            <div className={styles.Layout}
                style={{ backgroundColor: mode ? "#2B2D31" : "#F8F9FC", color: mode ? "#fff" : "#000" }} >
                <div className={styles.Top_bar_css}>
                    <div className={styles.gobackClass}>
                        <TopTabBar text={`Product/${product}/${collection}`} />
                    </div>
                    <div className={styles.btn_Continer}>
                        <div> <DropDown
                            label={"Sort By"}
                            options={[{ label: "Active" }, { label: "Disabled" }, { label: "Delete" }, { label: "Ascending" }, { label: "Descending" },]}
                        /> </div>
                        <div>
                            <Button
                                buttonText={"Add Item"}
                                buttonBgColor={"#4164E3"}
                                buttonTextColor={"#fff"}
                                buttonIcon={<AddTO />}
                            // buttonClick={() => navi(`/products/addproduct`)}
                            />
                        </div>

                    </div>
                </div>


                <div className={styles.container} >
                    <ul className={styles.responsiveTable} >
                        <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" } : { backgroundColor: "#D9DCE5" }} >
                            <div className={styles.col} >Status</div>
                            <div className={styles.col} >Product Name</div>
                            <div className={styles.col} >Delete</div>
                            <div className={styles.col} >Setting</div>
                            <div className={styles.col} >Edit</div>
                            <div className={styles.col} >View</div>
                        </li>
                        <div className={styles.Table_row}>
                            {
                                loading?.items ? <Skeleton lines={10} thickness={5} /> :
                                    data?.length === 0 ? <NoDataFound icon={<TemplateNotFound />} /> :
                                        data?.map((item) =>
                                            // Array.from({ length: 20 }).map((item) =>
                                            <li className={styles.tablerow}
                                                style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa", } :
                                                    { backgroundColor: "#ffff", }} key={item?._id} >
                                                <div className={styles.col} data-label="Status" style={{ color: TextColorCode(item?.status) }}> {item?.status}</div>
                                                <div className={styles.col} data-label="Product Name" style={{ color: mode ? "#fff" : "#000" }} >
                                                    {item?.itemNo}</div>
                                                <div className={styles.col} data-label="Delete" style={{ color: mode ? "#fff" : "#000" }} >
                                                    <span onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleSetModel(<>
                                                            <ManagerModal
                                                                heading={"Delete Product"}
                                                                centerHeading={"Are you sure you want to Delete?"}
                                                                subHeading={
                                                                    "To confirm! type delete in the text field."
                                                                }
                                                                targetWord={"delete"}
                                                                handleSubmit={() => {
                                                                    DeleteProduct(item._id);
                                                                }}
                                                                handleCloseModal={() => setToggle({ ...toggle, modelToggle: false })}
                                                            />
                                                        </>)
                                                    }}> <DeleteTo /> </span>
                                                </div>
                                                <div className={styles.col} data-label="Setting" style={{ color: mode ? "#fff" : "#000" }} >
                                                    <SettingTo mode={mode} />
                                                </div>
                                                <div className={styles.col} data-label="Edit" style={{ color: mode ? "#fff" : "#000" }} >
                                                    <EditeTo />
                                                </div>
                                                <div className={styles.col} data-label="View" style={{ color: mode ? "#fff" : "#000" }} >
                                                    <ViewTo />
                                                </div>
                                            </li>
                                        )}
                        </div>

                    </ul>
                </div>
                <div className={styles.mgx_pagination} style={{ backgroundColor: mode ? "#232529" : "#fff" }} >
                    <div style={{ width: "50%" }}>
                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "14px",
                            }}
                        >
                            <GoToPage
                                key={"1"}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: "12px",
                        }}
                    >
                        <Pagenation
                            key={"1"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Items