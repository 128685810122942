import { useAuth } from "../../context/Auth";
import {
  CancelIcon,
  DealerInfoCardActiveStatusIcon,
  DealerInfoCardActiveStatusIcon1,
  DealerInfoCardBlackListedStatusIcon,
  DealerInfoCardNewStatusIcon,
  DealerInfoCardPendingStatusIcon,
  DealerInfoCardRejectedStatusIcon,
  DispatchedIcon,
  InProgressIcon,
  PackedIcon,
  RupeeIcon,
} from "../../icons/Icon";
import { AllCount, Deleted, Verified } from "../../icons/Icon2";
import styles from "./DealerStyles.module.css";

const DealerStatusCard = ({
  statusText,
  statusItemCount,
  dealerStatus,
  manager,
}) => {
  const renderStatusIcon = () => {
    switch (dealerStatus || statusText) {
      case "Blacklisted":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "New":
        return <DealerInfoCardNewStatusIcon />;
      case "Pending":
        return <DealerInfoCardPendingStatusIcon />;
      case "Rejected":
        return <DealerInfoCardRejectedStatusIcon />;
        case "Disable":
          return <DealerInfoCardRejectedStatusIcon />;
      case "All":
        return <AllCount />;
      case "Verified":
        return <Verified />;
      case "Update":
        return <DealerInfoCardActiveStatusIcon />;
      case "Deleted":
        return <Deleted />;
      case "Cancelled":
        return <CancelIcon />;
      case "In Progress":
        return <InProgressIcon />;
      case "Packed":
        return <PackedIcon />;
      case "Dispatched":
      case "Dispatch Pending":
        return <DispatchedIcon />;
      default:
        return <RupeeIcon />;
    }
  };

  // Developer5@wallicon

  const { mode } = useAuth();
  return (
    <div
      className={styles.dealer_status_card}
      style={
        mode
          ? {
              backgroundColor: "#1B1D21",
              color: "#fff",
              borderColor: "#1B1D21",
            }
          : {}
      }
    >
      {manager ? (
        <>
          {statusText === "All" && <AllCount />}
          {statusText === "Active" && <DealerInfoCardActiveStatusIcon1 />}
          {statusText === "Disabled" && (
            <DealerInfoCardBlackListedStatusIcon fill={mode && "#1B1D21"} />
          )}
          {statusText === "Disable" && (
            <DealerInfoCardBlackListedStatusIcon fill={mode && "#1B1D21"} />
          )}
        </>
      ) : (
        <> {renderStatusIcon()} </>
      )}

      <p>{statusText}</p>
      <p>{statusItemCount}</p>
    </div>
  );
};

export default DealerStatusCard;
