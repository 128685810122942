import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import styles from "./Orders.module.css";

const VoucherList = ({
  mode,
  voucherTab,
  headerData,
  loading,
  orderData,
  handleClick,
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
      case "Packed":
      case "success":
      case "Dispatched":
        return "#31974D";

      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Request For Cancel":
        return "#FF9B04";

      case "Packing In Progress":
      case "Draft":
      case "In Progress":
      case "Cancelled":
      case "Payment Pending":
        return "#F93E3E";

      case "New Order":
      case "draft":
        return "#7698FF";

      default:
        return "";
    }
  };

  const voucherFields = {
    Order: [
      (d) => `${d.storeName}, ${d.city}`,
      "orderId",
      "createdBy",
      "ordermanager",
      "orderType",
      "totalAmount",
      "status",
      "lastAction",
    ],
    Purchase: [
      "voucherNo",
      "supplierName",
      "warehouse",
      "voucherDate",
      "totalAmount",
      "status",
      "totalItems",
    ],
    Adjustment: [
      "voucherNo",
      "warehouse",
      "voucherDate",
      "totalAmount",
      "status",
      "totalItems",
    ],
    Transfer: [
      "voucherNo",
      "fromWarehouse",
      "toWarehouse",
      "voucherDate",
      "totalAmount",
      "status",
      "totalItems",
    ],
  };

  const fields = voucherFields[voucherTab] || [];

  return (
    <>
      <li
        className={`${styles.order_data_list_row_header} ${
          mode ? styles.order_data_list_row_header_dark : ""
        }`}
      >
        {headerData.map((h) => (
          <div className={styles.entry} key={h}>
            {h}
          </div>
        ))}
      </li>

      <div className={styles.order_data_list_container}>
        {loading ? (
          <div style={{ margin: "1rem auto" }}>
            <Spinner height={80} width={80} />
          </div>
        ) : (
          <>
            {orderData.length === 0 ? (
              <img
                src="/assets/updatedAssets/noDATA.png"
                alt="img"
                style={{
                  height: "12rem",
                  width: "16rem",
                  margin: "1rem auto",
                }}
              />
            ) : (
              <>
                {orderData.map((d, index) => (
                  <>
                    <li
                      key={d.orderId + index}
                      className={`${styles.order_data_list_row} ${
                        mode ? styles.order_data_list_row_dark : ""
                      }`}
                      onClick={() => {
                        handleClick(d);
                      }}
                    >
                      {fields.map((field) => (
                        <div
                          key={field}
                          className={styles.entry}
                          style={
                            field === "status"
                              ? { color: getStatusColor(d[field]) }
                              : {}
                          }
                        >
                          {typeof field === "function" ? field(d) : d[field]}
                        </div>
                      ))}
                    </li>
                  </>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default VoucherList;

// eslint-disable-next-line no-lone-blocks
{
  /* <div
                      className={styles.sale_order_mob}
                      style={mode ? { backgroundColor: "#1B1D21" } : {}}
                      onClick={() => {
                        handleOrderClick(d);
                      }}
                    >
                      <div
                        className={`${styles.sale_order_mob_div} ${
                          mode ? styles.sale_order_mob_div_dark : {}
                        }`}
                      >
                        <TextElement
                          label={"Name:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.storeName + ", " + d.city}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                        <EyeIcon color={"#4164E3"} />
                      </div>
                      <div
                        className={`${styles.sale_order_mob_div} ${
                          mode ? styles.sale_order_mob_div_dark : {}
                        }`}
                      >
                        <TextElement
                          label={"Order ID:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.orderId}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                        <TextElement
                          label={"Amount:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.totalAmount}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                      </div>
                      <div
                        className={`${styles.sale_order_mob_div} ${
                          mode ? styles.sale_order_mob_div_dark : {}
                        }`}
                      >
                        <TextElement
                          label={"Date:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.orderDate}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                        <TextElement
                          label={"Last Action:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.lastAction}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                      </div>
                      <div
                        className={`${styles.sale_order_mob_div} ${
                          mode ? styles.sale_order_mob_div_dark : {}
                        }`}
                      >
                        <TextElement
                          label={"Order Manager:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.ordermanager}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                        <TextElement
                          label={"Created By:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.createdBy}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                      </div>
                      <div
                        className={`${styles.sale_order_mob_div} ${
                          mode ? styles.sale_order_mob_div_dark : {}
                        }`}
                        style={{ borderBottom: "none" }}
                      >
                        <TextElement
                          label={"Source:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.orderType}
                          valueColor={mode ? "#fff" : "#646B88"}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                        <TextElement
                          label={"Status:"}
                          labelColor={mode ? "#fff" : "#646B88"}
                          value={d.status}
                          valueColor={getStatusColor(d.status)}
                          type={"order"}
                          fontSize={"12px"}
                          fontWeight={mode && "400"}
                        />
                      </div>
                    </div> */
}
