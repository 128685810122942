import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  NewEyeIcon,
  RemoveIcon,
  SaveAsDraftIcon,
  SaveIcon,
  TestTopAddDealerIcon,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import styles from "./PurchaseOrder.module.css";
import PurchaseNewItemList from "../../components/updatedComponents/OrderManager/PurchaseNewItemList";
import PurchaseItemList from "../../components/updatedComponents/OrderManager/PurchaseItemList";
import React, { useState } from "react";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";

const ProductionSale = () => {
  const { managerName, mode, managerId } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get("orderId");

  const [itemsdetails, setItemsdetails] = useState([
    {
      itemNo: "",
      price: "",
      product_name: "",
      thumbnail: "",
      totalQty: "",
      qty: "",
      unit: "",
      rate: "",
      amount: "",
      warehouse: "",
    },
  ]);

  const productionHeader = [
    "S.No",
    "Item No",
    "Product",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];
  return (
    <div className={styles.production}>
      <div className={styles.scroll_wrapper}>
        <form className={styles.production_form}>
          <div className={styles.production_form_1}>
            <div style={{ position: "relative" }}>
              <InputTag
                labelText={"Company or Dealer's Name / ID"}
                placeholderText={"Search Dealer"}
                padding={"8px"}
                fontSize={"12px"}
                //   value={}
                //   onChange={}
                //   onFocus={}
                //   onBlur={}
                //   onKeyDown={}
                maxLength={100}
                required
                readOnly={orderId}
                enterKeyHint={"enter"}
              />
            </div>
            <div>
              <InputTag
                labelText={"Shipping Address"}
                placeholderText={"Enter Dealer Address"}
                //   value={}
                padding={"8px"}
                fontSize={"12px"}
                readOnly={orderId}
              />
            </div>
          </div>
          <div className={styles.production_form_1}>
            <div>
              <div style={{ width: "50%" }}>
                <VerticalDropDown
                  height={"37px"}
                  fontSize={"12px"}
                  label={"Production Warehouse"}
                  // dropDownText={}
                  // value={}
                  // options={}
                  // onchange={}
                  disableOpen={orderId}
                />
              </div>
              <div style={{ width: "50%" }}>
                <VerticalDropDown
                  height={"37px"}
                  fontSize={"12px"}
                  label={"Production Manager"}
                  // dropDownText={}
                  // value={}
                  // options={}
                  // onchange={}
                  disableOpen={orderId}
                />
              </div>
            </div>
            <div>
              <div style={{ width: "100%", position: "relative" }}>
                {/* <div className={styles.voucher_det}>
                <TextElement
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={
                    generalInfo.deliveryPartner === "Self"
                      ? "Pick Up"
                      : generalInfo.courierName || "--"
                  }
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontWeight={"400"}
                />
              </div> */}
                <VerticalDropDown2
                  height={"37px"}
                  fontSize={"12px"}
                  label={"Delivery Method"}
                  // dropDownOpen={}
                  // dropDownText={}
                  // options={}
                  // onchange={}
                  disableOpen={orderId}
                />
              </div>
            </div>
          </div>
        </form>

        <ul className={styles.p_table}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <li
              className={`${styles.p_table_header} ${
                mode ? styles.p_table_header_dark : ""
              }`}
            >
              {productionHeader.map((h, index) => (
                <div
                  className={`${styles.entry_2} ${
                    h === "Item No" ? styles.item_no : ""
                  }`}
                  key={`${h}-${index}`}
                >
                  {h}
                </div>
              ))}
            </li>
            {!orderId && itemsdetails.length > 1 && (
              <div style={{ cursor: "pointer", visibility: "hidden" }}>
                <RemoveIcon />
              </div>
            )}
          </div>
          <div className={styles.p_table_list}>
            <>
              {itemsdetails.map((item, index) => (
                <React.Fragment key={index}>
                  {orderId ? (
                    <PurchaseItemList index={index} mode={mode} item={item} />
                  ) : (
                    <p style={{ textAlign: "center" }}>More to come</p>
                    //   <PurchaseNewItemList
                    //     index={index}
                    //     mode={mode}
                    //     item={item}
                    //     managerId={managerId}
                    //     itemInpRefs={(el) => (itemInpRefs.current[index] = el)}
                    //     itemsdetailslength={itemsdetails.length}
                    //     onSelectItem={(item) => {
                    //       handleSelectItem(item, index);
                    //     }}
                    //     addItem={() => handleAddClick()}
                    //     removeItem={() => handleRemoveClick(index)}
                    //     showItemSearch={
                    //       showItemSearch && focusedItemIndex === index
                    //     }
                    //     setShowItemSearch={setShowItemSearch}
                    //     focusedIndex={focusedItemIndex}
                    //     onFocus={() => handleItemInputFocus(index)}
                    //     onBlur={handleItemInputBlur}
                    //     onCompletion={() => handleSubmit()}
                    //     warehouse_id={generalInfo.warehouse_id}
                    //     openAddItemModal={openAddItemModal}
                    //     quantityRefs={quantityRefs.current}
                    //   />
                  )}
                </React.Fragment>
              ))}
            </>
          </div>
          {!orderId && (
            <div
              className={styles.cricle_btn}
              // onClick={() => {
              //   handleAddClick();
              // }}
            >
              <TestTopAddDealerIcon color="#4164E3" />
            </div>
          )}
        </ul>
      </div>

      <div className={`${styles.footer} ${mode ? styles.footer_dark : ""}`}>
        <div className={styles.footer_right}>
          {orderId ? (
            <div>
              <Button
                buttonIcon={<NewEyeIcon />}
                buttonText={"View Invoice"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                // buttonClick={() =>
                //   navigate("/orders/purchaseinvoice/" + orderId)
                // }
              />
            </div>
          ) : (
            <div>
              <Button
                buttonIcon={<SaveAsDraftIcon />}
                buttonText={"Save as Draft"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
              />
            </div>
          )}
          <div
            className={`${styles.footer_right_1} ${
              mode ? styles.footer_right_1_dark : ""
            }`}
          >
            <div>
              <p>Sub Total:</p>
              <p>₹</p>
            </div>
            <div className={styles.so_shipping}>
              <p>Shipping Charges:</p>
              <div className={styles.so_check}>
                <p>To Pay</p>
                <input
                  type="checkbox"
                  //   value={}
                  //   onChange={}
                  //   checked={}
                  //   ref={}
                  //   disabled={}
                  //   onKeyDown={}
                />
              </div>
              <p>₹</p>
            </div>
            <div>
              <p>Total Amount(INR):</p>
              <p>₹</p>
            </div>
          </div>
          {!orderId && (
            <div className={styles.footer_right_2}>
              <Button
                buttonIcon={<SaveIcon />}
                buttonText={"Save"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#FFFFFF"}
                buttonBorder={"none"}
                // buttonClick={() => {
                //   if (verifyEntries()) {
                //     handleSubmit();
                //   }
                // }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionSale;
