import { useEffect, useRef, useState } from "react";
import { CancelIcon2, RevertIcon, SaveTaxRateIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import styles from "../../../pages/ordermanager/Orders.module.css";
import FileUpload from "./FileUpload";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";
import { useToast } from "../Toaster/Toaster";

const AddReceiptModal = ({
  handleCloseModal,
  mode,
  totalAmount,
  accounts = [],
  receiptData,
  updateReceiptData,
  type = "create",
  revertCase,
  flag,
  receiptsTotalAmount,
}) => {
  // console.log("receiptsTotalAmount ", receiptsTotalAmount);
  // console.log("receipt data ", receiptData);
  const transactionAmountRef = useRef(null);
  const { show } = useToast();
  const [localReceiptData, setLocalReceiptData] = useState(receiptData);
  const [image, setImage] = useState(
    receiptData?.transactionScreenshot || null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLocalReceiptData(receiptData);
  }, [receiptData]);

  useEffect(() => {
    if (type === "create" && localReceiptData.paymentMethod === "POD") {
      setLocalReceiptData((prevState) => ({
        ...prevState,
        paymentAmount: totalAmount - receiptsTotalAmount,
      }));
    }
  }, [localReceiptData.paymentMethod, receiptsTotalAmount, totalAmount, type]);

  const resetFieldsOnPaymentMethodChange = (newPaymentMethod) => {
    const updatedData = {
      ...localReceiptData,
      paymentMethod: newPaymentMethod,
    };

    switch (newPaymentMethod) {
      case "Cash":
        updatedData.accountNumber = "";
        updatedData.paymentType = "";
        updatedData.transactionId = "";
        updatedData.transactionScreenshot = "";
        break;
      case "Photo":
        updatedData.paymentType = "";
        break;
      case "POD":
        updatedData.accountNumber = "";
        updatedData.paymentType = "Cash";
        updatedData.transactionId = "";
        updatedData.transactionScreenshot = "";
        break;
      default:
        break;
    }
    setLocalReceiptData(updatedData);
  };

  const validateField = (field, value) => {
    switch (field) {
      case "paymentAmount":
        return value && !isNaN(value)
          ? null
          : "Payment amount must be a valid number.";
      case "accountNumber":
        return value ? null : "Account number is required.";
      case "transactionScreenshot":
        return value ? null : "Transaction screenshot is required.";
      case "transactionId":
        return value ? null : "Transaction ID is required.";
      default:
        return null;
    }
  };

  const validateData = () => {
    const errors = {};
    const rules = {
      Cash: ["paymentAmount"],
      Photo: [
        "paymentAmount",
        "accountNumber",
        "transactionScreenshot",
        "transactionId",
      ],
      "Pay on Delivery":
        localReceiptData.paymentType === "Cash"
          ? ["paymentAmount"]
          : [
              "paymentAmount",
              "accountNumber",
              "transactionScreenshot",
              "transactionId",
            ],
    };

    const requiredFields = rules[localReceiptData.paymentMethod] || [];
    requiredFields.forEach((field) => {
      const error = validateField(field, localReceiptData[field]);
      if (error) errors[field] = error;
    });

    if (Object.keys(errors).length > 0) {
      console.error("Validation Errors:", errors);
      show("Please fill all the required fields!", "warning");
      return false;
    }

    return true;
  };

  const buttonClick = async () => {
    if (validateData()) {
      setLoading(true);
      console.log("ladinfdkjhjksvk 1 ", loading);
      try {
        console.log("ladinfdkjhjksvk 2 ", loading);
        await updateReceiptData(localReceiptData);
        console.log("ladinfdkjhjksvk 3", loading);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (file) => {
    setImage(file);
    setLocalReceiptData((prevState) => ({
      ...prevState,
      transactionScreenshot: file || "",
    }));
  };

  const handleFieldChange = (field, value) => {
    setLocalReceiptData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const methodOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
    { label: "Pay on Delivery", value: "POD" },
  ];

  const subMethodOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
  ];

  // console.log("local ", localReceiptData);

  return (
    <div
      className={styles.add_reciept_modal}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div>
        <p style={{ color: mode ? "#fff" : "#000" }}>
          {(type === "create" || type === "edit") && "Receipt"}
          {type === "update" && "Update Receipt"}
        </p>
        <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
          <CloseIcon mode={mode} />
        </div>
      </div>

      <div style={{ gap: "1rem" }}>
        <div style={{ width: "50%" }}>
          <VerticalDropDown2
            height={"37px"}
            fontSize={"12px"}
            label={"Method"}
            dropDownText={localReceiptData.paymentMethod || "Select Method"}
            dropDownOpen={type !== "create" ? false : true}
            options={methodOptions}
            value={methodOptions.find(
              (option) => option.value === localReceiptData.paymentMethod
            )}
            onchange={(option) => {
              resetFieldsOnPaymentMethodChange(option.value);
              transactionAmountRef.current.focus();
            }}
          />
        </div>
        <div style={{ width: "50%" }}>
          {flag !== "verify" ? (
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Total Amount"}
              placeholderText={"000.00"}
              value={
                type !== "create" ? localReceiptData.totalAmount : totalAmount
              }
              readOnly={true}
            />
          ) : (
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Receipt Amount"}
              placeholderText={"000.00"}
              value={totalAmount}
              readOnly={true}
            />
          )}
        </div>
      </div>

      {localReceiptData.paymentMethod === "Photo" && (
        <>
          <div>
            <VerticalDropDown
              height={"37px"}
              fontSize={"12px"}
              label={"Account"}
              dropDownText={
                accounts.find(
                  (a) => a.accountNumber === localReceiptData.accountNumber
                )
                  ? `${localReceiptData.accountNumber} / ${
                      accounts.find(
                        (a) =>
                          a.accountNumber === localReceiptData.accountNumber
                      ).bankName
                    }`
                  : "Select"
              }
              options={accounts.map((a) => ({
                label: `${a.accountNumber} / ${a.bankName}`,
                value: a.accountNumber,
              }))}
              value={{
                label: accounts.find(
                  (a) => a.accountNumber === localReceiptData.accountNumber
                )
                  ? `${localReceiptData.accountNumber} / ${
                      accounts.find(
                        (a) =>
                          a.accountNumber === localReceiptData.accountNumber
                      ).bankName
                    }`
                  : "Select",
                value: localReceiptData.accountNumber,
              }}
              onchange={(option) =>
                handleFieldChange("accountNumber", option.value)
              }
            />
          </div>

          <FileUpload
            mode={mode}
            image={image}
            setImage={setImage}
            onFileChange={handleFileChange}
          />
          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Transaction ID"}
              placeholderText={"Ref / UTR Number / Transaction ID"}
              placeholderType={"text"}
              value={localReceiptData.transactionId || ""}
              onChange={(e) =>
                handleFieldChange("transactionId", e.target.value)
              }
              maxLength={20}
              required={true}
            />
          </div>
        </>
      )}

      {localReceiptData.paymentMethod === "POD" && (
        <>
          <div>
            <VerticalDropDown
              height={"37px"}
              fontSize={"12px"}
              label={"Sub Method"}
              dropDownText={localReceiptData.paymentType || "Select"}
              options={subMethodOptions}
              value={subMethodOptions.find(
                (option) => option.value === localReceiptData.paymentType
              )}
              onchange={(option) =>
                handleFieldChange("paymentType", option.value)
              }
            />
          </div>
          {localReceiptData.paymentType === "Photo" && (
            <div>
              <VerticalDropDown
                height={"37px"}
                fontSize={"12px"}
                label={"Account"}
                dropDownText={
                  accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select"
                }
                options={accounts.map((a) => ({
                  label: `${a.accountNumber} / ${a.bankName}`,
                  value: a.accountNumber,
                }))}
                value={{
                  label: accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select",
                  value: localReceiptData.accountNumber,
                }}
                onchange={(option) =>
                  handleFieldChange("accountNumber", option.value)
                }
              />
            </div>
          )}

          {localReceiptData.paymentType === "Photo" && (
            <>
              <FileUpload
                mode={mode}
                image={image}
                setImage={setImage}
                onFileChange={handleFileChange}
              />
              <div>
                <InputTag
                  padding={"8px"}
                  fontSize={"12px"}
                  labelText={"Transtaction ID"}
                  placeholderText={"Ref / UTR Number / Transaction ID"}
                  placeholderType={"text"}
                  value={localReceiptData.transactionId}
                  onChange={(e) =>
                    handleFieldChange("transactionId", e.target.value)
                  }
                  maxLength={20}
                  required={true}
                />
              </div>
            </>
          )}
        </>
      )}

      <div>
        <InputTag
          padding={"8px"}
          fontSize={"12px"}
          labelText={"Transaction"}
          placeholderText={"Enter Transaction Amount (In INR)"}
          placeholderType={"number"}
          ref={transactionAmountRef}
          value={localReceiptData.paymentAmount || ""}
          onChange={(e) => {
            handleFieldChange("paymentAmount", e.target.value);
          }}
          maxLength={10}
          required={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              buttonClick();
            }
          }}
        />
      </div>

      <div
        style={{ gap: "1rem", justifyContent: "center", marginTop: "0.5rem" }}
      >
        <Button
          buttonBgColor={"#000"}
          buttonText={"CANCEL"}
          buttonTextColor={"#fff"}
          buttonIcon={<CancelIcon2 />}
          buttonClick={handleCloseModal}
        />

        {localReceiptData.status !== "Received" &&
          localReceiptData.status !== "Cancelled" &&
          localReceiptData.paymentMethod === "Online" && (
            <Button
              buttonBgColor={"#F93E3E"}
              buttonText={"Revert Back"}
              buttonTextColor={"#FFFFFF"}
              buttonIcon={<RevertIcon color={"#FFFFFF"} />}
              buttonClick={() => {
                handleCloseModal();
                revertCase();
              }}
            />
          )}

        <Button
          buttonBgColor={"#4164E3"}
          buttonText={
            type === "create"
              ? "SAVE"
              : type === "update"
              ? localReceiptData.status === "Received"
                ? "VERIFIED"
                : "Verify & Update"
              : localReceiptData.status === "Received"
              ? "VERIFIED"
              : "VERIFY"
          }
          buttonTextColor={"#fff"}
          buttonIcon={<SaveTaxRateIcon />}
          buttonClick={buttonClick}
          disabled={
            localReceiptData.status === "Received" ||
            localReceiptData.status === "Cancelled" ||
            localReceiptData.status === "Refund" ||
            loading
          }
          loading={loading}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default AddReceiptModal;

// import { useEffect, useRef, useState } from "react";
// import { CancelIcon2, SaveTaxRateIcon } from "../../icons/Icon";
// import { CloseIcon } from "../../icons/Icon2";
// import Button from "../Dealers/Button";
// import InputTag from "../FormComponents/InputTag";
// import VerticalDropDown from "../FormComponents/VerticalDropDown";
// import styles from "../../../pages/ordermanager/Orders.module.css";
// import FileUpload from "./FileUpload";
// import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";
// import { useToast } from "../Toaster/Toaster";

// const AddReceiptModal = ({
//   handleCloseModal,
//   mode,
//   totalAmount,
//   accounts = [],
//   receiptData,
//   updateReceiptData,
//   type = "create",
// }) => {
//   const transactionAmountRef = useRef(null);
//   const {show} = useToast();
//   const [localReceiptData, setLocalReceiptData] = useState(receiptData);
//   const [image, setImage] = useState(
//     receiptData?.transactionScreenshot || null
//   );
//   const isEditMode = type === "edit";

//   // console.log("Image ", receiptData.transactionScreenshot);

//   // console.log("Accounts ", accounts);

//   console.log("local data ", localReceiptData);

//   useEffect(() => {
//     setLocalReceiptData(receiptData);
//   }, [receiptData]);

//   const validateField = (field, value) => {
//     switch (field) {
//       case "paymentAmount":
//         return value && !isNaN(value)
//           ? null
//           : "Payment amount must be a valid number.";
//       case "accountNumber":
//         return value ? null : "Account number is required.";
//       case "transactionScreenshot":
//         return value ? null : "Transaction screenshot is required.";
//       case "transactionId":
//         return value ? null : "Transaction ID is required.";
//       default:
//         return null;
//     }
//   };

//   const validateData = () => {
//     const errors = {};
//     const rules = {
//       Cash: ["paymentAmount"],
//       Photo: [
//         "paymentAmount",
//         "accountNumber",
//         "transactionScreenshot",
//         "transactionId",
//       ],
//       "Pay on Delivery":
//         localReceiptData.subMethod === "Cash"
//           ? ["paymentAmount"]
//           : [
//               "paymentAmount",
//               "accountNumber",
//               "transactionScreenshot",
//               "transactionId",
//             ],
//     };

//     const requiredFields = rules[localReceiptData.paymentMethod] || [];
//     requiredFields.forEach((field) => {
//       const error = validateField(field, localReceiptData[field]);
//       if (error) errors[field] = error;
//     });

//     if (Object.keys(errors).length > 0) {
//       console.error("Validation Errors:", errors);
//       show("Please fill all the required fields!", "warning");
//       return false;
//     }

//     return true;
//   };

//   const buttonClick = () => {
//     if (validateData()) {
//       updateReceiptData(localReceiptData);
//     }
//   };

//   const handleFileChange = (file) => {
//     setImage(file);
//     setLocalReceiptData((prevState) => ({
//       ...prevState,
//       transactionScreenshot: file || "",
//     }));
//   };

//   const handleFieldChange = (field, value) => {
//     setLocalReceiptData((prevState) => ({
//       ...prevState,
//       [field]: value,
//     }));
//   };

//   const methodOptions = [
//     { label: "Cash", value: "Cash" },
//     { label: "Photo", value: "Photo" },
//     { label: "Pay on Delivery", value: "Pay on Delivery" },
//   ];

//   const subMethodOptions = [
//     { label: "Cash", value: "Cash" },
//     { label: "Photo", value: "Photo" },
//   ];

//   return (
//     <div
//       className={styles.add_reciept_modal}
//       style={mode ? { backgroundColor: "#232529" } : {}}
//     >
//       <div>
//         <p style={{ color: mode ? "#fff" : "#000" }}>Receipt</p>
//         <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
//           <CloseIcon mode={mode} />
//         </div>
//       </div>

//       <div style={{ gap: "1rem" }}>
//         <div style={{ width: "50%" }}>
//           <VerticalDropDown2
//             height={"37px"}
//             fontSize={"12px"}
//             label={"Method"}
//             dropDownText={localReceiptData.paymentMethod || "Select Method"}
//             dropDownOpen={isEditMode ? false : true}
//             options={methodOptions}
//             value={methodOptions.find(
//               (option) => option.value === localReceiptData.paymentMethod
//             )}
//             onchange={(option) => {
//               handleFieldChange("paymentMethod", option.value);
//               transactionAmountRef.current.focus();
//             }}
//           />
//         </div>
//         <div style={{ width: "50%" }}>
//           <InputTag
//             padding={"8px"}
//             fontSize={"12px"}
//             labelText={"Total Amount"}
//             placeholderText={"000.00"}
//             value={isEditMode ? localReceiptData.totalAmount : totalAmount}
//             readOnly={true}
//           />
//         </div>
//       </div>

//       {localReceiptData.paymentMethod === "Photo" && (
//         <>
//           <div>
//             <VerticalDropDown
//               height={"37px"}
//               fontSize={"12px"}
//               label={"Account"}
//               dropDownText={
//                 accounts.find(
//                   (a) => a.accountNumber === localReceiptData.accountNumber
//                 )
//                   ? `${localReceiptData.accountNumber} / ${
//                       accounts.find(
//                         (a) =>
//                           a.accountNumber === localReceiptData.accountNumber
//                       ).bankName
//                     }`
//                   : "Select"
//               }
//               options={accounts.map((a) => ({
//                 label: `${a.accountNumber} / ${a.bankName}`,
//                 value: a.accountNumber,
//               }))}
//               value={{
//                 label: accounts.find(
//                   (a) => a.accountNumber === localReceiptData.accountNumber
//                 )
//                   ? `${localReceiptData.accountNumber} / ${
//                       accounts.find(
//                         (a) =>
//                           a.accountNumber === localReceiptData.accountNumber
//                       ).bankName
//                     }`
//                   : "Select",
//                 value: localReceiptData.accountNumber,
//               }}
//               onchange={(option) =>
//                 handleFieldChange("accountNumber", option.value)
//               }
//             />
//           </div>

//           <FileUpload
//             mode={mode}
//             image={image}
//             setImage={setImage}
//             onFileChange={handleFileChange}
//           />
//           <div>
//             <InputTag
//               padding={"8px"}
//               fontSize={"12px"}
//               labelText={"Transaction ID"}
//               placeholderText={"Ref / UTR Number / Transaction ID"}
//               placeholderType={"number"}
//               value={localReceiptData.transactionId || ""}
//               onChange={(e) =>
//                 handleFieldChange("transactionId", e.target.value)
//               }
//               maxLength={20}
//               required={true}
//             />
//           </div>
//         </>
//       )}

//       {localReceiptData.paymentMethod === "Pay on Delivery" && (
//         <>
//           <div>
//             <VerticalDropDown
//               height={"37px"}
//               fontSize={"12px"}
//               label={"Sub Method"}
//               dropDownText={localReceiptData.subMethod || "Select"}
//               options={subMethodOptions}
//               value={subMethodOptions.find(
//                 (option) => option.value === localReceiptData.subMethod
//               )}
//               onchange={(option) =>
//                 handleFieldChange("subMethod", option.value)
//               }
//             />
//           </div>
//           {localReceiptData.subMethod === "Photo" && (
//             <div>
//               <VerticalDropDown
//                 height={"37px"}
//                 fontSize={"12px"}
//                 label={"Account"}
//                 dropDownText={
//                   accounts.find(
//                     (a) => a.accountNumber === localReceiptData.accountNumber
//                   )
//                     ? `${localReceiptData.accountNumber} / ${
//                         accounts.find(
//                           (a) =>
//                             a.accountNumber === localReceiptData.accountNumber
//                         ).bankName
//                       }`
//                     : "Select"
//                 }
//                 options={accounts.map((a) => ({
//                   label: `${a.accountNumber} / ${a.bankName}`,
//                   value: a.accountNumber,
//                 }))}
//                 value={{
//                   label: accounts.find(
//                     (a) => a.accountNumber === localReceiptData.accountNumber
//                   )
//                     ? `${localReceiptData.accountNumber} / ${
//                         accounts.find(
//                           (a) =>
//                             a.accountNumber === localReceiptData.accountNumber
//                         ).bankName
//                       }`
//                     : "Select",
//                   value: localReceiptData.accountNumber,
//                 }}
//                 onchange={(option) =>
//                   handleFieldChange("accountNumber", option.value)
//                 }
//               />
//             </div>
//           )}

//           {localReceiptData.subMethod === "Photo" && (
//             <>
//               <FileUpload
//                 mode={mode}
//                 image={image}
//                 setImage={setImage}
//                 onFileChange={handleFileChange}
//               />
//               <div>
//                 <InputTag
//                   padding={"8px"}
//                   fontSize={"12px"}
//                   labelText={"Transtaction ID"}
//                   placeholderText={"Ref / UTR Number / Transaction ID"}
//                   value={localReceiptData.transactionId}
//                   onChange={(e) =>
//                     handleFieldChange("transactionId", e.target.value)
//                   }
//                   maxLength={20}
//                   required={true}
//                 />
//               </div>
//             </>
//           )}
//         </>
//       )}

//       <div>
//         <InputTag
//           padding={"8px"}
//           fontSize={"12px"}
//           labelText={"Transaction"}
//           placeholderText={"Enter Transaction Amount (In INR)"}
//           placeholderType={"number"}
//           ref={transactionAmountRef}
//           value={localReceiptData.paymentAmount || ""}
//           onChange={(e) => {
//             handleFieldChange("paymentAmount", e.target.value);
//           }}
//           maxLength={10}
//           required={true}
//           onKeyDown={(e) => {
//             if (e.key === "Enter") {
//               buttonClick();
//             }
//           }}
//         />
//       </div>

//       <div
//         style={{ gap: "1rem", justifyContent: "center", marginTop: "0.5rem" }}
//       >
//         <Button
//           buttonBgColor={"#000"}
//           buttonText={"CANCEL"}
//           buttonTextColor={"#fff"}
//           buttonIcon={<CancelIcon2 />}
//           buttonClick={handleCloseModal}
//         />
//         <Button
//           buttonBgColor={"#4164E3"}
//           buttonText={isEditMode ? "VERIFY" : "SAVE"}
//           buttonTextColor={"#fff"}
//           buttonIcon={<SaveTaxRateIcon />}
//           buttonClick={buttonClick}
//         />
//       </div>
//     </div>
//   );
// };

// export default AddReceiptModal;
