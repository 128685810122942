import React, { useEffect, useRef, useState } from "react";
import styles from "./globalModel.module.css";
import InputTag from "../FormComponents/InputTag";
import { useAuth } from "../../context/Auth";
import fetchData from "../../../APIsControll/apiControll";
import Button from "../Dealers/Button";
import { Block } from "../../icons/Icon2";
import { validateForm } from "../../../validation/AddDealer";
import { CrossIcon, TestTopAddDealerIcon } from "../../icons/Icon";
import { useToast } from "../Toaster/Toaster";
import { Spinner } from "../../icons/Icon";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";

const AdduserModel = ({ close, focus, onDealerAdd }) => {
  const { mode, managerId, setIsLoading, isLoading } = useAuth();
  const inpRefs = useRef([]);
  const { show } = useToast();
  const [manager, setManager] = useState([]);
  const [SelectManger, setSelectManger] = useState("");
  const [Designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    pin: "",
    selectManager: "",
    fullName: "",
    mobileNumber: "",
    storeName: "",
    whatsAppNumber: "",
    shippingAddress: "",
    state: "",
    district: "",
    city: "",
    gstUin: "",
  });

  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [designationDD, setDesignationDD] = useState(false);
  const [omDD, setOmDD] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // console.log(" focus ", focus);
  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        if (inpRefs.current && inpRefs.current[0]) {
          inpRefs.current[0].focus();
        }
      }, 100);
    }
  }, [focus]);

  useEffect(() => {
    const getLocation = async () => {
      if (formData.pin.length === 6) {
        setIsLoading(true);
        const res = await fetchData("locations/locations", {
          keyword: formData.pin,
        });
        setIsLoading(false);
        setFormData((prevState) => ({
          ...prevState,
          district: res.districts[0] ? res.districts[0].toUpperCase() : "",
          city: res.SubDistName[0] ? res.SubDistName[0] : "",
          state: res.states[0] ? res.states[0] : "",
        }));
        setErrorMsg("");
        inpRefs.current[6].focus();
        if (
          res.SubDistName.length === 0 ||
          res.districts.length === 0 ||
          res.states[0].length === 0
        ) {
          inpRefs.current[5].focus();
          setErrorMsg("No such Pincode exists!");
        }
      }
    };
    getLocation();
  }, [formData.pin, setIsLoading]);

  useEffect(() => {
    const addDealer = async () => {
      const res = await fetchData("order/ordermanagers", { managerId });
      if (res.status === "success") {
        setManager(res.data);
      }
    };

    addDealer();
  }, [managerId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm(formData, Designation, SelectManger);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      mobile: formData.mobileNumber,
      refcode: managerId,
      name: formData.fullName,
      street: formData.shippingAddress,
      pincode: formData.pin,
      city: formData.city,
      district: formData.district,
      state: formData.state,
      country: "India",
      designation: Designation ? Designation.label : "",
      gstuin: formData.gstUin,
      storeName: formData.storeName,
      whatsapp: formData.whatsAppNumber,
      managerId: SelectManger ? SelectManger.value : "",
    };
    setLoading(true);
    const res = await fetchData("order/addDealer", payload);
    if (res.status === "success") {
      setLoading(false);
      payload.dealerId = formData.mobileNumber;
      if (onDealerAdd) {
        onDealerAdd(payload);
      }
      show(`${res.msg}`, "success");
      close();
      setSelectManger("");
      setDesignation("");
      setFormData({
        pin: "",
        selectManager: "",
        fullName: "",
        mobileNumber: "",
        storeName: "",
        whatsAppNumber: "",
        shippingAddress: "",
        state: "",
        district: "",
        city: "",
        gstUin: "",
      });
    } else {
      setLoading(false);
      show(`${res.msg}`, "error");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inpRefs.current[index + 1]) {
        inpRefs.current[index + 1].focus();
      }
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      // console.log("Checkbox is checked");
      setFormData((prevState) => ({
        ...prevState,
        whatsAppNumber: formData.mobileNumber,
      }));
    } else {
      // console.log("Checkbox is unchecked");
      setFormData((prevState) => ({
        ...prevState,
        whatsAppNumber: "",
      }));
    }
  };

  return (
    <div className={mode ? styles.mainContiner : styles.mainContiner_light}>
      <div className={styles.dealer_form_header}>
        <p style={mode ? { color: "#fff" } : {}}>Add Dealer</p>
        <div onClick={() => close()} style={{ cursor: "pointer" }}>
          <CrossIcon color={mode ? "#fff" : "#1B1D21"} />
        </div>
      </div>
      <div className={styles.formContiner}>
        <div>
          <div className={styles.inputbox}>
            <InputTag
              name="fullName"
              labelText={"Full Name"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"text"}
              required={true}
              value={formData.fullName}
              onChange={handleChange}
              maxLength={100}
              star={true}
              ref={(el) => (inpRefs.current[0] = el)}
              onKeyDown={(e) => handleKeyDown(e, 0)}
            />
            {errors.fullName && (
              <span className={styles.error}>{errors.fullName}</span>
            )}
          </div>
          <div className={styles.inputbox}>
            <InputTag
              name="storeName"
              labelText={"Store / Company name"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"text"}
              required={true}
              value={formData.storeName}
              onChange={handleChange}
              maxLength={200}
              star={true}
              ref={(el) => (inpRefs.current[1] = el)}
              onKeyDown={(e) => handleKeyDown(e, 1)}
            />
            {errors.storeName && (
              <span className={styles.error}>{errors.storeName}</span>
            )}
          </div>
        </div>

        <div>
          <div className={styles.inputbox}>
            <InputTag
              name="mobileNumber"
              labelText={"Mobile Number"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"number"}
              required={true}
              value={formData.mobileNumber}
              onChange={handleChange}
              maxLength={10}
              star={true}
              ref={(el) => (inpRefs.current[2] = el)}
              onKeyDown={(e) => handleKeyDown(e, 2)}
            />
            {errors.mobileNumber && (
              <span className={styles.error}>{errors.mobileNumber}</span>
            )}
          </div>
          <div className={styles.inputbox} style={{ position: "relative" }}>
            <div className={styles.inp_check_mark}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={formData.mobileNumber.length !== 10}
              />
            </div>
            <InputTag
              name="whatsAppNumber"
              labelText={"WhatsApp Number"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"number"}
              required={true}
              value={formData.whatsAppNumber}
              onChange={handleChange}
              maxLength={10}
              star={true}
              ref={(el) => (inpRefs.current[3] = el)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFormData((prevState) => ({
                    ...prevState,
                    whatsAppNumber: formData.mobileNumber,
                  }));
                  setIsChecked(true);
                  setDesignationDD(true);
                }
              }}
            />
            {errors.whatsAppNumber && (
              <span className={styles.error}>{errors.whatsAppNumber}</span>
            )}
          </div>
        </div>

        <div>
          <div className={styles.inputbox}>
            <VerticalDropDown2
              dropDownText={"--Select--"}
              label={"Designation"}
              height={"37px"}
              fontSize={"12px"}
              value={Designation}
              onchange={(label) => {
                setDesignation(label);
                setOmDD(true);
              }}
              options={[
                { label: "Interior Designer" },
                { label: "Store Owner" },
                { label: "Contractor" },
                { label: "Architect" },
                { label: "Others" },
              ]}
              star={true}
              dropDownOpen={designationDD}
            />
            {errors.designation && (
              <span className={styles.error}>{errors.designation}</span>
            )}
          </div>
          <div className={styles.inputbox}>
            <VerticalDropDown2
              dropDownText={"--Select--"}
              label={"Assign Order Manager"}
              height={"37px"}
              fontSize={"12px"}
              value={SelectManger}
              onchange={(label) => {
                setSelectManger(label);
                inpRefs.current[4].focus();
              }}
              options={manager.map((e) => ({
                label: e.name,
                value: e.managerId,
              }))}
              star={true}
              dropDownOpen={omDD}
            />
            {errors.selectManager && (
              <p className={styles.error}>{errors.selectManager}</p>
            )}
          </div>
        </div>

        <div>
          <div className={styles.inputbox}>
            <InputTag
              name="shippingAddress"
              labelText={"Shipping Address"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"text"}
              required={true}
              value={formData.shippingAddress}
              onChange={handleChange}
              maxLength={600}
              star={true}
              ref={(el) => (inpRefs.current[4] = el)}
              onKeyDown={(e) => handleKeyDown(e, 4)}
            />
            {errors.shippingAddress && (
              <span className={styles.error}>{errors.shippingAddress}</span>
            )}
          </div>
          <div className={styles.inputbox}>
            <InputTag
              name="pin"
              labelText={"PIN Code"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"number"}
              required={true}
              value={formData.pin}
              onChange={handleChange}
              maxLength={6}
              star={true}
              ref={(el) => (inpRefs.current[5] = el)}
            />
            {errors.pin && <span className={styles.error}>{errors.pin}</span>}
          </div>
        </div>

        <div>
          <div className={styles.inputbox}>
            <InputTag
              name="gstUin"
              labelText={"GSTUIN (Optional)"}
              padding={"8px"}
              fontSize={"12px"}
              placeholderType={"text"}
              required={false}
              value={formData.gstUin}
              onChange={handleChange}
              maxLength={15}
              ref={(el) => (inpRefs.current[6] = el)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            {errors.gstUin && (
              <span className={styles.error}>{errors.gstUin}</span>
            )}
          </div>
          <div className={styles.inputbox} style={{ position: "relative" }}>
            <div
              className={`${styles.pin_code_res} ${
                mode ? styles.pin_code_res_dark : {}
              }`}
            >
              {errorMsg.length !== 0 ? (
                <span className={styles.error}>{errorMsg}</span>
              ) : isLoading ? (
                <span>
                  <Spinner size="15" />
                </span>
              ) : formData.state.length !== 0 ||
                formData.district.length !== 0 ||
                formData.city.length !== 0 ? (
                <p>
                  {formData.state} / {formData.district} / {formData.city}
                </p>
              ) : (
                <p>State / District / City</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.btnContiner}>
        <Button
          buttonBgColor={"#000000"}
          buttonBorder={"#000000"}
          buttonText={"CANCEL"}
          buttonTextColor={"#FFFFFF"}
          buttonClick={() => close()}
          buttonIcon={<Block />}
          buttonFontWeight={"500"}
        />
        <Button
          buttonBgColor={"#4164E3"}
          buttonBorder={"#4164E3"}
          buttonText={"ADD DEALER"}
          buttonIcon={<TestTopAddDealerIcon />}
          buttonTextColor={"#FFFFFF"}
          buttonClick={handleSubmit}
          buttonFontWeight={"500"}
        />
      </div>
    </div>
  );
};

export default AdduserModel;

// import React, { useEffect, useRef, useState } from "react";
// import styles from "./globalModel.module.css";
// import InputTag from "../FormComponents/InputTag";
// import { useAuth } from "../../context/Auth";
// import fetchData from "../../../APIsControll/apiControll";
// import VerticalDropDown from "../../updatedComponents/FormComponents/VerticalDropDown";
// import Button from "../Dealers/Button";
// import { Block } from "../../icons/Icon2";
// import { validateForm } from "../../../validation/AddDealer"; // Import the validation function
// import { CrossIcon } from "../../icons/Icon";
// import { useToast } from "../Toaster/Toaster";

// const AdduserModel = ({ close }) => {
//   const { mode, managerId, setIsLoading, isLoading } = useAuth();
//   const { show } = useToast();
//   const [manager, setManager] = useState([]);
//   const [SelectManger, setSelectManger] = useState("");
//   const [Designation, setDesignation] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     pin: "",
//     selectManager: "",
//     fullName: "",
//     mobileNumber: "",
//     storeName: "",
//     whatsAppNumber: "",
//     shippingAddress: "",
//     state: "",
//     district: "",
//     city: "",
//     gstUin: "",
//   });

//   const [errors, setErrors] = useState({});
//   // const dealerNameRef = useRef(null);

//   useEffect(() => {
//     const getLocation = async () => {
//       if (formData.pin.length === 6) {
//         setIsLoading(true);
//         const res = await fetchData("locations/locations", {
//           keyword: formData.pin,
//         });
//         setIsLoading(false);
//         setFormData((prevState) => ({
//           ...prevState,
//           district: res.districts[0] ? res.districts[0].toUpperCase() : "",
//           city: res.SubDistName[0] ? res.SubDistName[0] : "",
//           state: res.states[0] ? res.states[0] : "",
//         }));
//       }
//     };

//     getLocation();
//   }, [formData.pin]);

//   useEffect(() => {
//     const addDealer = async () => {
//       const res = await fetchData("order/ordermanagers", { managerId });
//       if (res.status === "success") {
//         setManager(res.data);
//       }
//     };

//     addDealer();
//   }, [managerId]);

//   // useEffect(() => {
//   //   if (dealerNameRef.current) {
//   //     console.log("Dealer Current Ref ", dealerNameRef.current);
//   //     dealerNameRef.current.focus();
//   //   }
//   // }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleSubmit = async () => {
//     const validationErrors = validateForm(formData, Designation, SelectManger);

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }

//     const payload = {
//       mobile: formData.mobileNumber,
//       refcode: managerId,
//       name: formData.fullName,
//       street: formData.shippingAddress,
//       pincode: formData.pin,
//       city: formData.city,
//       district: formData.district,
//       state: formData.state,
//       country: "India",
//       designation: Designation ? Designation.label : "",
//       gstuin: formData.gstUin,
//       storeName: formData.storeName,
//       whatsapp: formData.whatsAppNumber,
//       managerId: SelectManger ? SelectManger.value : "",
//     };
//     setLoading(true);
//     const res = await fetchData("order/addDealer", payload);
//     if (res.status === "success") {
//       setLoading(false);
//       show(`${res.msg}`, "success");
//       close();
//       setSelectManger("");
//       setDesignation("");
//       setFormData({
//         pin: "",
//         selectManager: "",
//         fullName: "",
//         mobileNumber: "",
//         storeName: "",
//         whatsAppNumber: "",
//         shippingAddress: "",
//         state: "",
//         district: "",
//         city: "",
//         gstUin: "",
//       });
//     } else {
//       setLoading(false);
//       show(`${res.msg}`, "error");
//     }
//   };

//   return (
//     <div className={mode ? styles.mainContiner : styles.mainContiner_light}>
//       <div className={styles.dealer_form_header}>
//         <p style={mode ? { color: "#fff" } : {}}>Add Dealer</p>
//         <div onClick={() => close()} style={{ cursor: "pointer" }}>
//           <CrossIcon color={mode ? "#fff" : "#1B1D21"} />
//         </div>
//       </div>
//       <div className={styles.formContiner}>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="fullName"
//             labelText={"Full Name"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Name"}
//             placeholderType={"text"}
//             required={true}
//             value={formData.fullName}
//             onChange={handleChange}
//             maxLength={100}
//             star={true}
//             // ref={dealerNameRef}
//           />
//           {errors.fullName && (
//             <span className={styles.error}>{errors.fullName}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="mobileNumber"
//             labelText={"Mobile Number"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"+91 916104XXX"}
//             placeholderType={"number"}
//             required={true}
//             value={formData.mobileNumber}
//             onChange={handleChange}
//             maxLength={10}
//             star={true}
//           />
//           {errors.mobileNumber && (
//             <span className={styles.error}>{errors.mobileNumber}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="storeName"
//             labelText={"Store / Company name"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Wallicon Pvt Ltd"}
//             placeholderType={"text"}
//             required={true}
//             value={formData.storeName}
//             onChange={handleChange}
//             maxLength={200}
//             star={true}
//           />
//           {errors.storeName && (
//             <span className={styles.error}>{errors.storeName}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <VerticalDropDown
//             dropDownText={"--Select--"}
//             label={"Designation"}
//             height={"37px"}
//             fontSize={"12px"}
//             value={Designation}
//             onchange={setDesignation}
//             options={[
//               { label: "Interior Designer" },
//               { label: "Store Owner" },
//               { label: "Contractor" },
//               { label: "Architect" },
//               { label: "Others" },
//             ]}
//             star={true}
//           />
//           {errors.designation && (
//             <span className={styles.error}>{errors.designation}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <VerticalDropDown
//             dropDownText={"--Select--"}
//             label={"Assign Order Manager"}
//             height={"37px"}
//             fontSize={"12px"}
//             value={SelectManger}
//             onchange={setSelectManger}
//             options={manager.map((e) => ({
//               label: e.name,
//               value: e.managerId,
//             }))}
//             star={true}
//           />
//           {errors.selectManager && (
//             <p className={styles.error}>{errors.selectManager}</p>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="whatsAppNumber"
//             labelText={"WhatsApp Number"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"+91 916104XXX"}
//             placeholderType={"number"}
//             required={true}
//             value={formData.whatsAppNumber}
//             onChange={handleChange}
//             maxLength={10}
//             star={true}
//           />
//           {errors.whatsAppNumber && (
//             <span className={styles.error}>{errors.whatsAppNumber}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="shippingAddress"
//             labelText={"Shipping Address"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Address "}
//             placeholderType={"text"}
//             required={true}
//             value={formData.shippingAddress}
//             onChange={handleChange}
//             maxLength={600}
//             star={true}
//           />
//           {errors.shippingAddress && (
//             <span className={styles.error}>{errors.shippingAddress}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="pin"
//             labelText={"PIN Code"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"26XXX9"}
//             placeholderType={"number"}
//             required={true}
//             value={formData.pin}
//             onChange={handleChange}
//             maxLength={6}
//             star={true}
//           />
//           {errors.pin && <span className={styles.error}>{errors.pin}</span>}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="state"
//             labelText={"State"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Utter Pradesh"}
//             placeholderType={"text"}
//             required={true}
//             value={formData.state}
//             onChange={handleChange}
//             maxLength={100}
//             loading={isLoading}
//             star={true}
//           />
//           {errors.state && <span className={styles.error}>{errors.state}</span>}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="district"
//             labelText={"District"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Lucknow"}
//             placeholderType={"text"}
//             required={true}
//             value={formData.district}
//             onChange={handleChange}
//             maxLength={100}
//             loading={isLoading}
//             star={true}
//           />
//           {errors.district && (
//             <span className={styles.error}>{errors.district}</span>
//           )}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="city"
//             labelText={"City"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={"Lucknow"}
//             placeholderType={"text"}
//             required={true}
//             value={formData.city}
//             onChange={handleChange}
//             maxLength={100}
//             loading={isLoading}
//             star={true}
//           />
//           {errors.city && <span className={styles.error}>{errors.city}</span>}
//         </div>
//         <div className={styles.inputbox}>
//           <InputTag
//             name="gstUin"
//             labelText={"GSTUIN (Optional)"}
//             padding={"8px"}
//             fontSize={"12px"}
//             // placeholderText={""}
//             placeholderType={"text"}
//             required={false}
//             value={formData.gstUin}
//             onChange={handleChange}
//             maxLength={15}
//           />
//           {errors.gstUin && (
//             <span className={styles.error}>{errors.gstUin}</span>
//           )}
//         </div>
//         {/* <div style={{ height: "10px",backgroundColor:"lavender", width:"100%" }}></div> */}
//       </div>
//       <div className={styles.btnContiner}>
//         <Button
//           buttonBgColor={"#000000"}
//           buttonBorder={"black"}
//           buttonText={"CANCEL"}
//           buttonTextColor={"#FFFFFF"}
//           buttonClick={() => close()}
//           buttonIcon={<Block />}
//           buttonFontWeight={"500"}
//         />
//         <Button
//           buttonBgColor={"#4164E3"}
//           buttonBorder={"#4164E3"}
//           buttonText={"+ ADD DEALER"}
//           buttonTextColor={"#FFFFFF"}
//           buttonClick={handleSubmit}
//           buttonFontWeight={"500"}
//         />
//       </div>
//     </div>
//   );
// };

// export default AdduserModel;
