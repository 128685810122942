import { useEffect, useRef } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import Spinner from "../FormComponents/Spinner";

const SearchItem = ({
  mode,
  results = [],
  currentIndex,
  loading,
  onItemSelect,
  // openModal,
  style,
}) => {
  const rowRefs = useRef([]);
  const sortResults = (a, b) => {
    if (a.totalQty === 0 && b.totalQty === 0) {
      return a.warehouse.localeCompare(b.warehouse);
    } else if (a.totalQty === 0) {
      return 1;
    } else if (b.totalQty === 0) {
      return -1;
    } else {
      return a.totalQty - b.totalQty;
    }
  };
  const sortedResults = results.sort(sortResults);
  useEffect(() => {
    if (rowRefs.current[currentIndex]) {
      rowRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentIndex]);

  return (
    <div
      className={`${styles.search_item} ${mode ? styles.search_item_dark : ""}`}
      onMouseDown={(e) => e.preventDefault()}
      style={style}
    >
      {/* <div className={styles.search_item_header}>
        <p
          style={{ color: mode ? "#749aff" : "#0100E4" }}
          onClick={(e) => openModal(e)}
        >
          Create New Item
        </p>
        <p onClick={(e) => openModal(e)}>ALT + C</p>
      </div>
      <div className={styles.search_item_header}>
        <p style={{ color: mode ? "#749aff" : "#0100E4" }}>End of List</p>
        <p>CTRL + X</p>
      </div> */}

      <ul className={styles.i_table}>
        <div className={styles.i_table_header_con}>
          <li
            className={`${styles.i_table_header} ${
              mode ? styles.i_table_header_dark : ""
            }`}
          >
            <div className={`${styles.entry_2}`}>Item No.</div>
            <div className={`${styles.entry_2}`}>Product</div>
            <div className={`${styles.entry_2}`}>Warehouse</div>
            <div className={`${styles.entry_2}`}>Quantity</div>
          </li>
        </div>
        <div className={styles.i_table_list}>
          {loading ? (
            <div className={styles.item_loader}>
              <Spinner
                height={60}
                width={60}
                color={mode ? "#000000" : "#4164E3"}
              />
            </div>
          ) : (
            sortedResults.map((data, index) => (
              <li
                key={index + data.itemNo}
                ref={(el) => (rowRefs.current[index] = el)}
                className={`${
                  currentIndex === index
                    ? mode
                      ? styles.focused_row_dark
                      : styles.focused_row
                    : mode
                    ? styles.selected_row_dark
                    : styles.selected_row
                } ${styles.i_table_row}`}
                onMouseDown={() => onItemSelect(index)}
              >
                <div className={`${styles.entry_2}`}>{data.itemNo}</div>
                <div className={`${styles.entry_2}`}>{data.product_name}</div>
                <div className={`${styles.entry_2}`}>{data.warehouse}</div>
                <div className={`${styles.entry_2}`}>{data.totalQty}</div>
              </li>
            ))
          )}
        </div>
      </ul>
    </div>
  );
};

export default SearchItem;
