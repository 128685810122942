// import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import {
  DealerInfoCardActiveStatusIcon,
  DealerInfoCardBlackListedStatusIcon,
  DealerInfoCardNewStatusIcon,
  DealerInfoCardPendingStatusIcon,
  DealerInfoCardRejectedStatusIcon,

  DealerInfoCardActiveStatusIcon1,
 
} from "../../icons/Icon";
import styles from "./DealerStyles.module1.css";
import { AllCount, Deleted, Verified } from "../../icons/Icon2";
// import { Warehouseedit, Warehouseview } from "../../icons/Icon4";

const WarehouseInfoCard = ({
  warehouseName,
  warehouseStatus,
  dealerDesignation,
  warehouseLocation,
  type,
  warehouseicon,
  warehouseicon1,
  warehousestreetaddress,
style,
  // imageUri,
  // buttonClick
}) => {
  const { mode } = useAuth();
  // const navigate = useNavigate();

  const renderStatusIcon = () => {
    switch (warehouseStatus) {
      case "disable":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "Blacklisted":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "New":
        return <DealerInfoCardNewStatusIcon />;
      case "Pending":
        return <DealerInfoCardPendingStatusIcon />;
      case "Rejected":
        return <DealerInfoCardRejectedStatusIcon />;
      case "All":
        return <AllCount />;
      case "Verified":
        return <Verified />;
      case "active":
        return <DealerInfoCardActiveStatusIcon1 />;
      case "Update":
        return <DealerInfoCardActiveStatusIcon />;
      case "Deleted":
        return <Deleted />;
      default:
        return null;
    }
  };


  return (
    <div
     
    >
    


      <div  className={style?style :styles.mob_style_1}>
        <div     className={styles.dealer_img}>
         
          <img src={`/assets/updatedAssets/dealerCardPfp.png`} alt={type}  />
          <div style={{color:mode?"white":"black",}} className={styles.dealer_status_icon}>{renderStatusIcon()}{ warehouseStatus}</div>
        </div>

        <div>
          <div style={{}} className={styles.dealer_Info_Card_name}>
            <p  style={mode ? { color: "#fff" } : {}}>{warehouseName}</p>
          </div>

          <div
            className={styles.dealer_Info_Card_Text_Section}
      
          >
            <p style={mode ? { color: "#fff" } : {}}>{dealerDesignation}</p>
            <p
              style={{
                color: mode ? "#fff" : "",
                display: type === "manager" ? "none" : "block",
              }}
            >
              {warehouseLocation}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.dealer_Info_Card_Icons_Tray}>

       <p style={mode ? { color: "#fff" } : {}}>{warehousestreetaddress}</p>  
       <p style={mode ? { color: "#fff" } : {}}>{warehouseicon}</p>  
      
      </div>
      <div className={styles.dealer_Info_Card_Icons_Tray}>
       <p style={mode ? { color: "#fff" } : {}}>{warehouseicon1}</p>  
      
      </div>
    </div>
    
  );
};

export default WarehouseInfoCard;
