import styles from "./Orders.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  DispatchReloadIcon,
  CreateVoucherIcon,
} from "../../components/icons/Icon";
import { useEffect, useState } from "react";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import Button from "../../components/updatedComponents/Dealers/Button";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import fetchData from "../../APIsControll/apiControll";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import { io } from "socket.io-client";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import ToggleSearchBar from "../../components/updatedComponents/FormComponents/ToggleSearchBar";
import VoucherList from "./VoucherList";
import OrderFilters from "./OrderFilters";

const AllOrders = () => {
  const { mode, managerId, voucherTab, setVoucherTab, handleVoucherChange } =
    useAuth();
  const navigate = useNavigate();
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [ordersFilters, setOrdersFilters] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [managers, setManagers] = useState([]);
  const [selectedManagerID, setSelectedManagerID] = useState("");
  const [daysTab, setDaysTab] = useState("today");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [selectedFilter, setselectedFilter] = useState("All");

  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [purchaseFilters, setPurchaseFilters] = useState([]);

  const [adjustmentOrderData, setAdjustmentOrderData] = useState([]);
  const [adjustmentFilters, setAdjustmentFilters] = useState([]);

  const [transferOrderData, setTransferOrderData] = useState([]);
  const [transferFilters, setTransferFilters] = useState([]);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");

  useEffect(() => {
    const socket = io(process.env.REACT_APP_URL);
    socket.on("allorder", (updatedOrders) => {
      console.log("updated order ", updatedOrders);
      return setOrderData((prev) => [...updatedOrders, ...prev]);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const getOrders = async () => {
      setPurchaseOrderData([]);
      setAdjustmentOrderData([]);
      setTransferOrderData([]);
      const apiData = {
        managerId: parseInt(managerId),
        day: daysTab,
        orderStatus: selectedFilter,
        // orderStatus: selectedOrderFilter,
        warehousename: selectedWarehouse,
        ordermanagerId: selectedManagerID,
        keyword: debouncedKeyword,
        startDate: startDate,
        endDate: endDate,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `order/allorders?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setOrderData(result.data);
          setPages(result.pages);
          setLoading(false);
          if (result.orders) {
            const formattedOrders = result.orders.map((s) => ({
              statusText: s.status,
              statusCount: s.count,
            }));
            setOrdersFilters(formattedOrders);
          }

          if (result.warehouses) {
            const formattedWarehouses = result.warehouses.map((w) => ({
              label: w.warehousename,
              value: w._id,
            }));
            setWarehouses(formattedWarehouses);
          }

          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
        setLoading(false);
      }
    };

    if (managerId && voucherTab === "Order") {
      getOrders();
    }
  }, [
    daysTab,
    managerId,
    page,
    // selectedOrderFilter,
    selectedFilter,
    selectedWarehouse,
    selectedManagerID,
    debouncedKeyword,
    startDate,
    endDate,
    voucherTab,
  ]);

  useEffect(() => {
    const getPurchaseOrders = async () => {
      setAdjustmentOrderData([]);
      setTransferOrderData([]);
      setOrderData([]);
      const apiData = {
        managerId: parseInt(managerId),
        keyword: debouncedKeyword,
        // sortKey: sortKey,
        warehouse: selectedWarehouse,
        day: daysTab,
        startDate: startDate,
        endDate: endDate,
        orderStatus: selectedFilter,
        // orderStatus: selectedPurchaseFilter,
        inventorymanagerId: selectedManagerID,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/allPurchase?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setPurchaseOrderData(result.data);
          setPurchaseFilters(result.orders);
          if (result.warehouses) {
            setWarehouses(result.warehouses);
          }
          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (managerId && voucherTab === "Purchase") {
      getPurchaseOrders();
    }
  }, [
    daysTab,
    endDate,
    debouncedKeyword,
    managerId,
    page,
    selectedManagerID,
    // selectedPurchaseFilter,
    selectedFilter,
    selectedWarehouse,
    startDate,
    voucherTab,
  ]);

  useEffect(() => {
    const getAdjustmentOrders = async () => {
      setPurchaseOrderData([]);
      setTransferOrderData([]);
      setOrderData([]);
      const apiData = {
        managerId: parseInt(managerId),
        // sortKey: sortKey,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/allAdjustment?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setAdjustmentOrderData(result.data);
          setAdjustmentFilters(result.orders);
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (managerId && voucherTab === "Adjustment") {
      getAdjustmentOrders();
    }
  }, [managerId, page, voucherTab]);

  useEffect(() => {
    const getTransferOrders = async () => {
      setPurchaseOrderData([]);
      setAdjustmentOrderData([]);
      setOrderData([]);
      const apiData = {
        managerId: parseInt(managerId),
        keyword: debouncedKeyword,
        // sortKey: sortKey,
        fromwarehouse: fromWarehouse,
        towarehouse: toWarehouse,
        day: daysTab,
        startDate: startDate,
        endDate: endDate,
        orderStatus: selectedFilter,
        // orderStatus: selectedTransferFilter,
        inventorymanagerId: selectedManagerID,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/stocktransfers?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setTransferOrderData(result.data);
          setTransferFilters(result.orders);
          if (result.warehouses) {
            setWarehouses(result.warehouses);
          }
          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
    if (managerId && voucherTab === "Transfer") {
      getTransferOrders();
    }
  }, [
    daysTab,
    endDate,
    debouncedKeyword,
    managerId,
    page,
    selectedManagerID,
    selectedWarehouse,
    startDate,
    voucherTab,
    fromWarehouse,
    toWarehouse,
    selectedFilter,
    // selectedTransferFilter,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 700);

    return () => clearTimeout(timer);
  }, [keyword]);

  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setDaysTab(selectedFilter?.value || "today");
  };

  const resetFilters = () => {
    setSelectedWarehouse("");
    setSelectedManagerID("");
    setDaysTab("today");
    // setSelectedOrderFilter("All");
    setselectedFilter("All");
    setStartDate("");
    setEndDate("");
    setKeyword("");
  };

  const orderStatusData = [
    {
      status: "All",
      count: ordersFilters[0]?.statusCount || 0,
    },
    {
      status: "New Order",
      count: ordersFilters[1]?.statusCount || 0,
    },
    {
      status: "Cancelled",
      count: ordersFilters[2]?.statusCount || 0,
    },
    {
      status: "In Progress",
      count: ordersFilters[3]?.statusCount || 0,
    },
    {
      status: "Payment Pending",
      count: ordersFilters[4]?.statusCount || 0,
    },
    {
      status: "Payment Under Verification",
      count: ordersFilters[5]?.statusCount || 0,
    },
    {
      status: "Packing In Progress",
      count: ordersFilters[6]?.statusCount || 0,
    },
    {
      status: "Dispatched Pending",
      count: ordersFilters[7]?.statusCount || 0,
    },
    {
      status: "Dispatched",
      count: ordersFilters[8]?.statusCount || 0,
    },
  ];

  const purchaseStatusData = [
    {
      status: "All",
      count: purchaseFilters[0]?.count || 0,
    },
    {
      status: "draft",
      count: purchaseFilters[1]?.count || 0,
    },
    {
      status: "success",
      count: purchaseFilters[2]?.count || 0,
    },
  ];

  const adjustmentStatusData = [
    {
      status: "All",
      count: adjustmentFilters[0]?.count + adjustmentFilters[1]?.count || 0,
    },
    {
      status: "draft",
      count: adjustmentFilters[0]?.count || 0,
    },
    {
      status: "success",
      count: adjustmentFilters[1]?.count || 0,
    },
  ];

  const transferStatusData = [
    {
      status: "All",
      count: transferFilters[0]?.count || 0,
    },
    {
      status: "draft",
      count: transferFilters[1]?.count || 0,
    },
    {
      status: "New Order",
      count: transferFilters[2]?.count || 0,
    },
    {
      status: "In Progress",
      count: transferFilters[3]?.count || 0,
    },
    {
      status: "Completed",
      count: transferFilters[4]?.count || 0,
    },
  ];

  const dayOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "previousyear",
      label: "Previous Year",
    },
  ];

  const voucherOptions = [
    "Order",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];

  const transferOrderHeader = [
    "Voucher No",
    "From Warehouse",
    "To Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const adjustmentOrderHeader = [
    "Voucher No.",
    "Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const purchaseOrderHeader = [
    "Voucher No.",
    "Vendors Name",
    "Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const orderHeader = [
    "Name",
    "Order ID",
    "Created By",
    "Order Manager",
    "Source",
    "Amount",
    "Status",
    "Last Action",
  ];

  return (
    <div
      className={styles.order}
      style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
    >
      <div className={styles.order_top}>
        <div className={styles.order_top_left}>
          <TopTabBar text={voucherTab} />
        </div>
        <div className={styles.order_right}>
          <div>
            <SlidingFilter2
              itemList={dayOptions.map((item) => item.label)}
              setFilter={handleDayChange}
              selectedItem={
                dayOptions.find((item) => item.value === daysTab)?.label
              }
            />
          </div>
          <div>
            <InputTypedate
              text={"From"}
              onChange={(e) => {
                setStartDate(e.target.value);
                setDaysTab("");
                setselectedFilter("");
                // setSelectedOrderFilter("");
              }}
            />
          </div>
          <div>
            <InputTypedate
              text={"To"}
              onChange={(e) => {
                setEndDate(e.target.value);
                setDaysTab("");
                setselectedFilter("");
                // setSelectedOrderFilter("");
              }}
            />
          </div>
          <div>
            <Button
              buttonIcon={<DispatchReloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => resetFilters()}
            />
          </div>
          <div>
            <Button
              buttonIcon={<CreateVoucherIcon />}
              buttonText={"Create"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                navigate("/orders/order/saleorder");
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={styles.order_filters}
        style={
          mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
        }
      >
        <>
          {voucherTab === "Order" && (
            <OrderFilters
              mode={mode}
              headerData={orderStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => setselectedFilter(s)}
              // selectedFilter={selectedOrderFilter}
              // handleClick={(s) => setSelectedOrderFilter(s)}
            />
          )}
          {voucherTab === "Purchase" && (
            <OrderFilters
              mode={mode}
              headerData={purchaseStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => setselectedFilter(s)}
              // selectedFilter={selectedPurchaseFilter}
              // handleClick={(s) => setSelectedPurchaseFilter(s)}
            />
          )}
          {voucherTab === "Adjustment" && (
            <OrderFilters
              mode={mode}
              headerData={adjustmentStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => setselectedFilter(s)}
            />
          )}
          {voucherTab === "Transfer" && (
            <OrderFilters
              mode={mode}
              headerData={transferStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => setselectedFilter(s)}
              // selectedFilter={selectedTransferFilter}
              // handleClick={(s) => setSelectedTransferFilter(s)}
            />
          )}
        </>
      </div>

      <div className={styles.order_data}>
        <div className={styles.order_data_filters}>
          <div>
            <ToggleSearchBar
              placeholder={"Search by Order ID"}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onToggle={(isExpanded) => setIsSearchBarOpen(isExpanded)}
            />
          </div>

          <div>
            {isSearchBarOpen ? (
              <DropDown
                label={"Type"}
                listLabel={"Order"}
                options={voucherOptions.map((o) => ({ label: o, value: o }))}
                onSelect={(option) => setVoucherTab(option.value)}
                onReject={() => setVoucherTab("Order")}
              />
            ) : (
              <SlidingFilter2
                itemList={voucherOptions.map((item) => item)}
                setFilter={(v) => handleVoucherChange(voucherOptions, v)}
                selectedItem={voucherOptions.find(
                  (item) => item === voucherTab
                )}
              />
            )}
            {voucherTab === "Transfer" ? (
              <>
                <DropDown
                  label={"From"}
                  listLabel={"All"}
                  options={warehouses}
                  onSelect={(option) => setFromWarehouse(option.label)}
                  onReject={() => setFromWarehouse(null)}
                />
                <DropDown
                  label={"To"}
                  listLabel={"All"}
                  options={warehouses}
                  onSelect={(option) => setToWarehouse(option.label)}
                  onReject={() => setToWarehouse(null)}
                />
              </>
            ) : (
              <DropDown
                label={"Warehouse"}
                listLabel={"All"}
                options={warehouses}
                onSelect={(option) => setSelectedWarehouse(option.label)}
                onReject={() => setSelectedWarehouse(null)}
              />
            )}
            <DropDown
              label={"Manager"}
              listLabel={"All"}
              options={managers}
              onSelect={(option) => setSelectedManagerID(option.value)}
              onReject={() => setSelectedManagerID(null)}
            />
            {/* <DropDown
              label={"Sort By"}
              listLabel={"View All"}
              options={orders.map((o) => ({
                label: o.statusText,
                value: o.statusText,
              }))}
            /> */}
          </div>
        </div>

        <ul className={styles.order_data_list}>
          {voucherTab === "Order" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={orderHeader}
              orderData={orderData}
              handleClick={(d) =>
                navigate("/orders/order/saleorder?orderId=" + d.orderId)
              }
            />
          )}

          {voucherTab === "Purchase" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={purchaseOrderHeader}
              orderData={purchaseOrderData}
              handleClick={(d) =>
                navigate(`/orders/order/purchase?orderId=${d.voucherNo}`)
              }
            />
          )}

          {voucherTab === "Adjustment" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={adjustmentOrderHeader}
              orderData={adjustmentOrderData}
              handleClick={(d) =>
                navigate("/orders/order/adjustment?orderId=" + d.voucherNo)
              }
            />
          )}

          {voucherTab === "Transfer" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={transferOrderHeader}
              orderData={transferOrderData}
              handleClick={(d) =>
                navigate("/orders/order/transfer?orderId=" + d.voucherNo)
              }
            />
          )}
        </ul>
      </div>

      <div
        className={styles.pagination}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <div style={{ width: "50%" }}>
          <div className={styles.go_to_page}>
            <GoToPage
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
        <div className={styles.pages}>
          <Pagenation
            currentPage={page}
            setCurrentPage={setPage}
            totalPages={pages}
            key={"1"}
          />
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
// import styles from "./Orders.module.css";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../components/context/Auth";
// import {
//   // TestTopBackArrowIcon,
//   // TestTopDotIcon,
//   DispatchReloadIcon,
//   // DispatchDownloadIcon,
//   CreateVoucherIcon,
//   EyeIcon,
// } from "../../components/icons/Icon";
// import { useEffect, useState } from "react";
// import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
// import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
// import Button from "../../components/updatedComponents/Dealers/Button";
// import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
// import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
// import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
// import fetchData from "../../APIsControll/apiControll";
// import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
// import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
// import { io } from "socket.io-client";
// import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
// import ToggleSearchBar from "../../components/updatedComponents/FormComponents/ToggleSearchBar";
// import TextElement from "../../components/updatedComponents/FormComponents/TextElement";

// const AllOrders = () => {
//   const { mode, managerId, voucherTab, setVoucherTab, handleVoucherChange } =
//     useAuth();
//   const navigate = useNavigate();
//   const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
//   const [ordersFilters, setOrdersFilters] = useState([]);
//   const [orderData, setOrderData] = useState([]);
//   const [warehouses, setWarehouses] = useState([]);
//   const [selectedWarehouse, setSelectedWarehouse] = useState("");
//   const [managers, setManagers] = useState([]);
//   const [selectedManagerID, setSelectedManagerID] = useState("");
//   const [daysTab, setDaysTab] = useState("today");
//   const [selectedOrderFilter, setSelectedOrderFilter] = useState("All");
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [keyword, setKeyword] = useState("");
//   const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(1);
//   const [pages, setPages] = useState(1);
//   // const [voucherTab, setVoucherTab] = useState("Order");

//   const [purchaseOrderData, setPurchaseOrderData] = useState([]);
//   const [purchaseFilters, setPurchaseFilters] = useState([]);
//   const [selectedPurchaseFilter, setSelectedPurchaseFilter] = useState("All");

//   const [adjustmentOrderData, setAdjustmentOrderData] = useState([]);
//   const [adjustmentFilters, setAdjustmentFilters] = useState([]);

//   const [transferOrderData, setTransferOrderData] = useState([]);
//   const [transferFilters, setTransferFilters] = useState([]);
//   const [selectedTransferFilter, setSelectedTransferFilter] = useState("All");
//   const [fromWarehouse, setFromWarehouse] = useState("");
//   const [toWarehouse, setToWarehouse] = useState("");

//   useEffect(() => {
//     const socket = io(process.env.REACT_APP_URL);
//     socket.on("allorder", (updatedOrders) => {
//       console.log("updated order ", updatedOrders);
//       return setOrderData((prev) => [...updatedOrders, ...prev]);
//     });
//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   useEffect(() => {
//     const getOrders = async () => {
//       setPurchaseOrderData([]);
//       setAdjustmentOrderData([]);
//       setTransferOrderData([]);
//       const apiData = {
//         managerId: parseInt(managerId),
//         day: daysTab,
//         orderStatus: selectedOrderFilter,
//         warehousename: selectedWarehouse,
//         ordermanagerId: selectedManagerID,
//         keyword: debouncedKeyword,
//         startDate: startDate,
//         endDate: endDate,
//       };
//       try {
//         setLoading(true);
//         const result = await fetchData(
//           `order/allorders?page=${page}&limit=15`,
//           apiData
//         );
//         if (result.status === "success") {
//           setOrderData(result.data);
//           setPages(result.pages);
//           setLoading(false);
//           if (result.orders) {
//             const formattedOrders = result.orders.map((s) => ({
//               statusText: s.status,
//               statusCount: s.count,
//             }));
//             setOrdersFilters(formattedOrders);
//           }

//           if (result.warehouses) {
//             const formattedWarehouses = result.warehouses.map((w) => ({
//               label: w.warehousename,
//               value: w._id,
//             }));
//             setWarehouses(formattedWarehouses);
//           }

//           if (result.managers) {
//             const formattedManagers = result.managers.map((m) => ({
//               label: m.name,
//               value: m.managerId,
//             }));
//             setManagers(formattedManagers);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching order data:", error);
//         setLoading(false);
//       }
//     };

//     if (managerId && voucherTab === "Order") {
//       getOrders();
//     }
//   }, [
//     daysTab,
//     managerId,
//     page,
//     selectedOrderFilter,
//     selectedWarehouse,
//     selectedManagerID,
//     debouncedKeyword,
//     startDate,
//     endDate,
//     voucherTab,
//   ]);

//   useEffect(() => {
//     const getPurchaseOrders = async () => {
//       setAdjustmentOrderData([]);
//       setTransferOrderData([]);
//       setOrderData([]);
//       const apiData = {
//         managerId: parseInt(managerId),
//         keyword: debouncedKeyword,
//         // sortKey: sortKey,
//         warehouse: selectedWarehouse,
//         day: daysTab,
//         startDate: startDate,
//         endDate: endDate,
//         orderStatus: selectedPurchaseFilter,
//         inventorymanagerId: selectedManagerID,
//       };
//       try {
//         setLoading(true);
//         const result = await fetchData(
//           `inventory/allPurchase?page=${page}&limit=15`,
//           apiData
//         );
//         if (result.status === "success") {
//           setLoading(false);
//           setPages(result.pages);
//           setPurchaseOrderData(result.data);
//           setPurchaseFilters(result.orders);
//           if (result.warehouses) {
//             setWarehouses(result.warehouses);
//           }
//           if (result.managers) {
//             const formattedManagers = result.managers.map((m) => ({
//               label: m.name,
//               value: m.managerId,
//             }));
//             setManagers(formattedManagers);
//           }
//         }
//       } catch (error) {
//         console.log("Error", error);
//       }
//     };
//     if (managerId && voucherTab === "Purchase") {
//       getPurchaseOrders();
//     }
//   }, [
//     daysTab,
//     endDate,
//     debouncedKeyword,
//     managerId,
//     page,
//     selectedManagerID,
//     selectedPurchaseFilter,
//     selectedWarehouse,
//     startDate,
//     voucherTab,
//   ]);

//   useEffect(() => {
//     const getAdjustmentOrders = async () => {
//       setPurchaseOrderData([]);
//       setTransferOrderData([]);
//       setOrderData([]);
//       const apiData = {
//         managerId: parseInt(managerId),
//         // sortKey: sortKey,
//       };
//       try {
//         setLoading(true);
//         const result = await fetchData(
//           `inventory/allAdjustment?page=${page}&limit=15`,
//           apiData
//         );
//         if (result.status === "success") {
//           setLoading(false);
//           setPages(result.pages);
//           setAdjustmentOrderData(result.data);
//           setAdjustmentFilters(result.orders);
//         }
//       } catch (error) {
//         console.log("Error", error);
//       }
//     };
//     if (managerId && voucherTab === "Adjustment") {
//       getAdjustmentOrders();
//     }
//   }, [managerId, page, voucherTab]);

//   useEffect(() => {
//     const getTransferOrders = async () => {
//       setPurchaseOrderData([]);
//       setAdjustmentOrderData([]);
//       setOrderData([]);
//       const apiData = {
//         managerId: parseInt(managerId),
//         keyword: debouncedKeyword,
//         // sortKey: sortKey,
//         fromwarehouse: fromWarehouse,
//         towarehouse: toWarehouse,
//         day: daysTab,
//         startDate: startDate,
//         endDate: endDate,
//         orderStatus: selectedTransferFilter,
//         inventorymanagerId: selectedManagerID,
//       };
//       try {
//         setLoading(true);
//         const result = await fetchData(
//           `inventory/stocktransfers?page=${page}&limit=15`,
//           apiData
//         );
//         if (result.status === "success") {
//           setLoading(false);
//           setPages(result.pages);
//           setTransferOrderData(result.data);
//           setTransferFilters(result.orders);
//           if (result.warehouses) {
//             setWarehouses(result.warehouses);
//           }
//           if (result.managers) {
//             const formattedManagers = result.managers.map((m) => ({
//               label: m.name,
//               value: m.managerId,
//             }));
//             setManagers(formattedManagers);
//           }
//         }
//       } catch (error) {
//         console.log("Error", error);
//       }
//     };
//     if (managerId && voucherTab === "Transfer") {
//       getTransferOrders();
//     }
//   }, [
//     daysTab,
//     endDate,
//     debouncedKeyword,
//     managerId,
//     page,
//     selectedManagerID,
//     selectedPurchaseFilter,
//     selectedWarehouse,
//     startDate,
//     voucherTab,
//     fromWarehouse,
//     toWarehouse,
//     selectedTransferFilter,
//   ]);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setDebouncedKeyword(keyword);
//     }, 700);

//     return () => clearTimeout(timer);
//   }, [keyword]);

//   const getStatusColor = (status) => {
//     switch (status) {
//       case "ordered":
//       case "Packed":
//       case "success":
//       case "Dispatched":
//         return "#31974D";

//       case "Not Packed":
//       case "ordered Pending":
//       case "Payment Under Verification":
//       case "Request For Edit":
//       case "Edit request Approved":
//       case "Request For Cancel":
//         return "#FF9B04";

//       case "Packing In Progress":
//       case "Draft":
//       case "In Progress":
//       case "Cancelled":
//       case "Payment Pending":
//         return "#F93E3E";

//       case "New Order":
//       case "draft":
//         return "#7698FF";

//       default:
//         return "";
//     }
//   };

//   const handleDayChange = (label) => {
//     const selectedFilter = dayOptions.find((item) => item.label === label);
//     setDaysTab(selectedFilter?.value || "today");
//   };

//   // const handleVoucherChange = (v) => {
//   //   const selectedFilter = voucherOptions.find((item) => item === v);
//   //   setVoucherTab(selectedFilter || "Order");
//   // };

//   const handleWarehouseSelection = (option) => {
//     setSelectedWarehouse(option.label);
//   };

//   const handleWarehouseRejection = () => {
//     setSelectedWarehouse(null);
//   };

//   const handleManagerSelection = (option) => {
//     setSelectedManagerID(option.value);
//   };

//   const handleManagerRejection = () => {
//     setSelectedManagerID(null);
//   };

//   const handleOrderClick = (d) => {
//     if (d.status !== "New Order") {
//       navigate("/orders/order/saleorder?orderId=" + d.orderId);
//     }
//   };

//   const handlePurchaseClick = (d) => {
//     navigate(`/orders/order/purchase?orderId=${d.voucherNo}`);
//   };

//   const handleAdjustmentClick = (d) => {
//     navigate("/orders/order/adjustment?orderId=" + d.voucherNo);
//   };

//   const handleTransferClick = (d) => {
//     navigate("/orders/order/transfer?orderId=" + d.voucherNo);
//   };

//   const resetFilters = () => {
//     setSelectedWarehouse("");
//     setSelectedManagerID("");
//     setDaysTab("today");
//     setSelectedOrderFilter("All");
//     setStartDate("");
//     setEndDate("");
//     setKeyword("");
//   };

//   const orderStatusData = [
//     {
//       status: "All",
//       count: ordersFilters[0]?.statusCount || 0,
//     },
//     {
//       status: "New Order",
//       count: ordersFilters[1]?.statusCount || 0,
//     },
//     {
//       status: "Cancelled",
//       count: ordersFilters[2]?.statusCount || 0,
//     },
//     {
//       status: "In Progress",
//       count: ordersFilters[3]?.statusCount || 0,
//     },
//     {
//       status: "Payment Pending",
//       count: ordersFilters[4]?.statusCount || 0,
//     },
//     {
//       status: "Payment Under Verification",
//       count: ordersFilters[5]?.statusCount || 0,
//     },
//     {
//       status: "Packing In Progress",
//       count: ordersFilters[6]?.statusCount || 0,
//     },
//     {
//       status: "Dispatched Pending",
//       count: ordersFilters[7]?.statusCount || 0,
//     },
//     {
//       status: "Dispatched",
//       count: ordersFilters[8]?.statusCount || 0,
//     },
//   ];

//   const purchaseStatusData = [
//     {
//       status: "All",
//       count: purchaseFilters[0]?.count || 0,
//     },
//     {
//       status: "draft",
//       count: purchaseFilters[1]?.count || 0,
//     },
//     {
//       status: "success",
//       count: purchaseFilters[2]?.count || 0,
//     },
//   ];

//   const adjustmentStatusData = [
//     {
//       status: "All",
//       count: adjustmentFilters[0]?.count + adjustmentFilters[1]?.count || 0,
//     },
//     {
//       status: "draft",
//       count: adjustmentFilters[0]?.count || 0,
//     },
//     {
//       status: "success",
//       count: adjustmentFilters[1]?.count || 0,
//     },
//   ];

//   const transferStatusData = [
//     {
//       status: "All",
//       count: transferFilters[0]?.count || 0,
//     },
//     {
//       status: "draft",
//       count: transferFilters[1]?.count || 0,
//     },
//     {
//       status: "New Order",
//       count: transferFilters[2]?.count || 0,
//     },
//     {
//       status: "In Progress",
//       count: transferFilters[3]?.count || 0,
//     },
//     {
//       status: "Completed",
//       count: transferFilters[4]?.count || 0,
//     },
//   ];

//   const dayOptions = [
//     {
//       value: "today",
//       label: "Today",
//     },
//     {
//       value: "yesterday",
//       label: "Yesterday",
//     },
//     {
//       value: "month",
//       label: "This Month",
//     },
//     {
//       value: "previousyear",
//       label: "Previous Year",
//     },
//   ];

//   const voucherOptions = [
//     "Order",
//     "Purchase",
//     "Adjustment",
//     "Transfer",
//     "Production",
//     "Production & Sale",
//     "Production & Transfer",
//   ];

//   const transferOrderHeader = [
//     "Voucher No",
//     "From Warehouse",
//     "To Warehouse",
//     "Date",
//     "Amount",
//     "Status",
//     "Items",
//   ];

//   const adjustmentOrderHeader = [
//     "Voucher No.",
//     "Warehouse",
//     "Date",
//     "Amount",
//     "Status",
//     "Items",
//   ];

//   const purchaseOrderHeader = [
//     "Voucher No.",
//     "Vendors Name",
//     "Warehouse",
//     "Date",
//     "Amount",
//     "Status",
//     "Items",
//   ];

//   const orderHeader = [
//     "Name",
//     "Order ID",
//     "Created By",
//     "Order Manager",
//     "Source",
//     "Amount",
//     "Status",
//     "Last Action",
//   ];

//   return (
//     <div
//       className={styles.order}
//       style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
//     >
//       <div className={styles.order_top}>
//         <div className={styles.order_top_left}>
//           <TopTabBar text={voucherTab} />
//         </div>
//         <div className={styles.order_right}>
//           <div>
//             <SlidingFilter2
//               itemList={dayOptions.map((item) => item.label)}
//               setFilter={handleDayChange}
//               selectedItem={
//                 dayOptions.find((item) => item.value === daysTab)?.label
//               }
//             />
//           </div>
//           <div>
//             <InputTypedate
//               text={"From"}
//               onChange={(e) => {
//                 setStartDate(e.target.value);
//                 setDaysTab("");
//                 setSelectedOrderFilter("");
//               }}
//             />
//           </div>
//           <div>
//             <InputTypedate
//               text={"To"}
//               onChange={(e) => {
//                 setEndDate(e.target.value);
//                 setDaysTab("");
//                 setSelectedOrderFilter("");
//               }}
//             />
//           </div>
//           <div>
//             <Button
//               buttonIcon={<DispatchReloadIcon />}
//               buttonBgColor={"#000000"}
//               buttonTextColor={"#ffffff"}
//               buttonBorder={"none"}
//               buttonClick={() => resetFilters()}
//             />
//           </div>
//           {/* <div>
//             <Button
//               buttonIcon={<DispatchDownloadIcon />}
//               buttonBgColor={"#000000"}
//               buttonTextColor={"#ffffff"}
//               buttonBorder={"none"}
//             />
//           </div> */}
//           <div>
//             <Button
//               buttonIcon={<CreateVoucherIcon />}
//               buttonText={"Create"}
//               buttonBgColor={"#4164E3"}
//               buttonTextColor={"#ffffff"}
//               buttonBorder={"none"}
//               buttonClick={() => {
//                 navigate("/orders/order/saleorder");
//               }}
//             />
//           </div>
//         </div>
//       </div>

//       <div
//         className={styles.order_filters}
//         style={
//           mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
//         }
//       >
//         <>
//           {voucherTab === "Order" ? (
//             <>
//               {orderStatusData?.map((s, index) => (
//                 <div
//                   key={s.status + index}
//                   className={`${styles.order_filter} ${
//                     mode ? styles.order_filter_dark : ""
//                   } ${
//                     s.status === selectedOrderFilter
//                       ? mode
//                         ? styles.order_filter_active_dark
//                         : styles.order_filter_active
//                       : ""
//                   }`}
//                   onClick={() => {
//                     setSelectedOrderFilter(s.status);
//                   }}
//                 >
//                   <DealerStatusCard
//                     statusText={s.status}
//                     statusItemCount={s.count}
//                   />
//                 </div>
//               ))}
//             </>
//           ) : voucherTab === "Purchase" ? (
//             <>
//               {purchaseStatusData?.map((s, index) => (
//                 <div
//                   key={s.status + index}
//                   className={`${styles.order_filter} ${
//                     mode ? styles.order_filter_dark : ""
//                   } ${
//                     s.status === selectedPurchaseFilter
//                       ? mode
//                         ? styles.order_filter_active_dark
//                         : styles.order_filter_active
//                       : ""
//                   }`}
//                   onClick={() => {
//                     setSelectedPurchaseFilter(s.status);
//                   }}
//                 >
//                   <DealerStatusCard
//                     statusText={s.status}
//                     statusItemCount={s.count}
//                   />
//                 </div>
//               ))}
//             </>
//           ) : voucherTab === "Adjustment" ? (
//             <>
//               {adjustmentStatusData?.map((s, index) => (
//                 <div
//                   key={s.status + index}
//                   className={`${styles.order_filter} ${
//                     mode ? styles.order_filter_dark : ""
//                   } ${
//                     s.status === selectedPurchaseFilter
//                       ? mode
//                         ? styles.order_filter_active_dark
//                         : styles.order_filter_active
//                       : ""
//                   }`}
//                   // onClick={() => {
//                   //   setSelectedPurchaseFilter(s.status);
//                   // }}
//                 >
//                   <DealerStatusCard
//                     statusText={s.status}
//                     statusItemCount={s.count}
//                   />
//                 </div>
//               ))}
//             </>
//           ) : voucherTab === "Transfer" ? (
//             <>
//               {transferStatusData?.map((s, index) => (
//                 <div
//                   key={s.status + index}
//                   className={`${styles.order_filter} ${
//                     mode ? styles.order_filter_dark : ""
//                   } ${
//                     s.status === selectedTransferFilter
//                       ? mode
//                         ? styles.order_filter_active_dark
//                         : styles.order_filter_active
//                       : ""
//                   }`}
//                   onClick={() => {
//                     setSelectedTransferFilter(s.status);
//                   }}
//                 >
//                   <DealerStatusCard
//                     statusText={s.status}
//                     statusItemCount={s.count}
//                   />
//                 </div>
//               ))}
//             </>
//           ) : null}
//         </>
//       </div>

//       <div className={styles.order_data}>
//         <div className={styles.order_data_filters}>
//           <div>
//             <ToggleSearchBar
//               placeholder={"Search by Order ID"}
//               value={keyword}
//               onChange={(e) => setKeyword(e.target.value)}
//               onToggle={(isExpanded) => setIsSearchBarOpen(isExpanded)}
//             />
//           </div>

//           <div>
//             {isSearchBarOpen ? (
//               <DropDown
//                 label={"Type"}
//                 listLabel={"Order"}
//                 options={voucherOptions.map((o) => ({ label: o, value: o }))}
//                 onSelect={(option) => setVoucherTab(option.value)}
//                 onReject={() => setVoucherTab("Order")}
//               />
//             ) : (
//               <SlidingFilter2
//                 itemList={voucherOptions.map((item) => item)}
//                 setFilter={(v) => handleVoucherChange(voucherOptions, v)}
//                 selectedItem={voucherOptions.find(
//                   (item) => item === voucherTab
//                 )}
//               />
//             )}
//             {voucherTab === "Transfer" ? (
//               <>
//                 <DropDown
//                   label={"From"}
//                   listLabel={"All"}
//                   options={warehouses}
//                   onSelect={(option) => setFromWarehouse(option.label)}
//                   onReject={() => setFromWarehouse(null)}
//                 />
//                 <DropDown
//                   label={"To"}
//                   listLabel={"All"}
//                   options={warehouses}
//                   onSelect={(option) => setToWarehouse(option.label)}
//                   onReject={() => setToWarehouse(null)}
//                 />
//               </>
//             ) : (
//               <DropDown
//                 label={"Warehouse"}
//                 listLabel={"All"}
//                 options={warehouses}
//                 onSelect={handleWarehouseSelection}
//                 onReject={handleWarehouseRejection}
//               />
//             )}
//             <DropDown
//               label={"Manager"}
//               listLabel={"All"}
//               options={managers}
//               onSelect={handleManagerSelection}
//               onReject={handleManagerRejection}
//             />
//             {/* <DropDown
//               label={"Sort By"}
//               listLabel={"View All"}
//               options={orders.map((o) => ({
//                 label: o.statusText,
//                 value: o.statusText,
//               }))}
//             /> */}
//           </div>
//         </div>

//         <ul className={styles.order_data_list}>

//           <li
//             className={`${styles.order_data_list_row_header} ${
//               mode ? styles.order_data_list_row_header_dark : ""
//             }`}
//           >
//             {voucherTab === "Order" ? (
//               <>
//                 {orderHeader.map((h) => (
//                   <div className={styles.entry} key={h}>
//                     {h}
//                   </div>
//                 ))}
//               </>
//             ) : voucherTab === "Purchase" ? (
//               <>
//                 {purchaseOrderHeader.map((h) => (
//                   <div className={styles.entry} key={h}>
//                     {h}
//                   </div>
//                 ))}
//               </>
//             ) : voucherTab === "Adjustment" ? (
//               <>
//                 {adjustmentOrderHeader.map((h) => (
//                   <div className={styles.entry} key={h}>
//                     {h}
//                   </div>
//                 ))}
//               </>
//             ) : voucherTab === "Transfer" ? (
//               <>
//                 {transferOrderHeader.map((h) => (
//                   <div className={styles.entry} key={h}>
//                     {h}
//                   </div>
//                 ))}
//               </>
//             ) : null}
//           </li>

//           <div className={styles.order_data_list_container}>
//             {loading ? (
//               <div style={{ margin: "1rem auto" }}>
//                 <Spinner height={80} width={80} />
//               </div>
//             ) : voucherTab === "Order" ? (
//               <>
//                 {orderData.length === 0 ? (
//                   <img
//                     src="/assets/updatedAssets/noDATA.png"
//                     alt="img"
//                     style={{
//                       height: "12rem",
//                       width: "16rem",
//                       margin: "1rem auto",
//                     }}
//                   />
//                 ) : (
//                   <>
//                     {orderData.map((d, index) => (
//                       <>
//                         <li
//                           key={d.orderId + index}
//                           className={`${styles.order_data_list_row} ${
//                             mode ? styles.order_data_list_row_dark : ""
//                           }`}
//                           onClick={() => {
//                             handleOrderClick(d);
//                           }}
//                         >
//                           <div className={styles.entry}>
//                             {d.storeName + ", " + d.city}
//                           </div>
//                           <div className={styles.entry}>{d.orderId}</div>
//                           <div className={styles.entry}>{d.createdBy}</div>
//                           <div className={styles.entry}>{d.ordermanager}</div>
//                           <div className={styles.entry}>{d.orderType}</div>
//                           <div className={styles.entry}>{d.totalAmount}</div>
//                           <div
//                             className={styles.entry}
//                             style={{ color: getStatusColor(d.status) }}
//                           >
//                             {d.status}
//                           </div>
//                           <div className={styles.entry}>{d.lastAction}</div>
//                         </li>

//                         <div
//                           className={styles.sale_order_mob}
//                           style={mode ? { backgroundColor: "#1B1D21" } : {}}
//                           onClick={() => {
//                             handleOrderClick(d);
//                           }}
//                         >

//                           <div
//                             className={`${styles.sale_order_mob_div} ${
//                               mode ? styles.sale_order_mob_div_dark : {}
//                             }`}
//                           >
//                             <TextElement
//                               label={"Name:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.storeName + ", " + d.city}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                             <EyeIcon color={"#4164E3"} />
//                           </div>
//                           <div
//                             className={`${styles.sale_order_mob_div} ${
//                               mode ? styles.sale_order_mob_div_dark : {}
//                             }`}
//                           >
//                             <TextElement
//                               label={"Order ID:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.orderId}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                             <TextElement
//                               label={"Amount:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.totalAmount}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                           </div>
//                           <div
//                             className={`${styles.sale_order_mob_div} ${
//                               mode ? styles.sale_order_mob_div_dark : {}
//                             }`}
//                           >
//                             <TextElement
//                               label={"Date:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.orderDate}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                             <TextElement
//                               label={"Last Action:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.lastAction}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                           </div>
//                           <div
//                             className={`${styles.sale_order_mob_div} ${
//                               mode ? styles.sale_order_mob_div_dark : {}
//                             }`}
//                           >
//                             <TextElement
//                               label={"Order Manager:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.ordermanager}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                             <TextElement
//                               label={"Created By:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.createdBy}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                           </div>
//                           <div
//                             className={`${styles.sale_order_mob_div} ${
//                               mode ? styles.sale_order_mob_div_dark : {}
//                             }`}
//                             style={{ borderBottom: "none" }}
//                           >
//                             <TextElement
//                               label={"Source:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.orderType}
//                               valueColor={mode ? "#fff" : "#646B88"}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                             <TextElement
//                               label={"Status:"}
//                               labelColor={mode ? "#fff" : "#646B88"}
//                               value={d.status}
//                               valueColor={getStatusColor(d.status)}
//                               type={"order"}
//                               fontSize={"12px"}
//                               fontWeight={mode && "400"}
//                             />
//                           </div>
//                         </div>

//                       </>
//                     ))}
//                   </>
//                 )}
//               </>
//             ) : voucherTab === "Purchase" ? (
//               <>
//                 {purchaseOrderData.length === 0 ? (
//                   <img
//                     src="/assets/updatedAssets/noDATA.png"
//                     alt="img"
//                     style={{
//                       height: "12rem",
//                       width: "16rem",
//                       margin: "1rem auto",
//                     }}
//                   />
//                 ) : (
//                   <>
//                     {purchaseOrderData.map((d, index) => (
//                       <li
//                         key={d.voucherNo + index}
//                         className={`${styles.order_data_list_row} ${
//                           mode ? styles.order_data_list_row_dark : ""
//                         }`}
//                         onClick={() => handlePurchaseClick(d)}
//                       >
//                         <div className={styles.entry}>{d.voucherNo}</div>
//                         <div className={styles.entry}>{d.supplierName}</div>
//                         <div className={styles.entry}>{d.warehouse}</div>
//                         <div className={styles.entry}>{d.voucherDate}</div>
//                         <div className={styles.entry}>{d.totalAmount}</div>
//                         <div
//                           className={styles.entry}
//                           style={{ color: getStatusColor(d.status) }}
//                         >
//                           {d.status}
//                         </div>
//                         <div className={styles.entry}>{d.totalItems}</div>
//                       </li>
//                     ))}
//                   </>
//                 )}
//               </>
//             ) : voucherTab === "Adjustment" ? (
//               <>
//                 {adjustmentOrderData.length === 0 ? (
//                   <img
//                     src="/assets/updatedAssets/noDATA.png"
//                     alt="img"
//                     style={{
//                       height: "12rem",
//                       width: "16rem",
//                       margin: "1rem auto",
//                     }}
//                   />
//                 ) : (
//                   <>
//                     {adjustmentOrderData.map((d, index) => (
//                       <li
//                         key={d.voucherNo + index}
//                         className={`${styles.order_data_list_row} ${
//                           mode ? styles.order_data_list_row_dark : ""
//                         }`}
//                         onClick={() => handleAdjustmentClick(d)}
//                       >
//                         <div className={styles.entry}>{d.voucherNo}</div>
//                         <div className={styles.entry}>{d.warehouse}</div>
//                         <div className={styles.entry}>{d.voucherDate}</div>
//                         <div className={styles.entry}>{d.totalAmount}</div>
//                         <div
//                           className={styles.entry}
//                           style={{ color: getStatusColor(d.status) }}
//                         >
//                           {d.status}
//                         </div>
//                         <div className={styles.entry}>{d.totalItems}</div>
//                       </li>
//                     ))}
//                   </>
//                 )}
//               </>
//             ) : voucherTab === "Transfer" ? (
//               <>
//                 {transferOrderData.length === 0 ? (
//                   <img
//                     src="/assets/updatedAssets/noDATA.png"
//                     alt="img"
//                     style={{
//                       height: "12rem",
//                       width: "16rem",
//                       margin: "1rem auto",
//                     }}
//                   />
//                 ) : (
//                   <>
//                     {transferOrderData.map((d, index) => (
//                       <li
//                         key={d.voucherNo + index}
//                         className={`${styles.order_data_list_row} ${
//                           mode ? styles.order_data_list_row_dark : ""
//                         }`}
//                         onClick={() => handleTransferClick(d)}
//                       >
//                         <div className={styles.entry}>{d.voucherNo}</div>
//                         <div className={styles.entry}>{d.fromWarehouse}</div>
//                         <div className={styles.entry}>{d.toWarehouse}</div>
//                         <div className={styles.entry}>{d.voucherDate}</div>
//                         <div className={styles.entry}>{d.totalAmount}</div>
//                         <div
//                           className={styles.entry}
//                           style={{ color: getStatusColor(d.status) }}
//                         >
//                           {d.status}
//                         </div>
//                         <div className={styles.entry}>{d.totalItems}</div>
//                       </li>
//                     ))}
//                   </>
//                 )}
//               </>
//             ) : null}
//           </div>

//         </ul>
//       </div>

//       <div
//         className={styles.pagination}
//         style={mode ? { backgroundColor: "#232529" } : {}}
//       >
//         <div style={{ width: "50%" }}>
//           <div className={styles.go_to_page}>
//             <GoToPage
//               currentPage={page}
//               setCurrentPage={setPage}
//               totalPages={pages}
//               key={"1"}
//             />
//           </div>
//         </div>
//         <div className={styles.pages}>
//           <Pagenation
//             currentPage={page}
//             setCurrentPage={setPage}
//             totalPages={pages}
//             key={"1"}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllOrders;
