import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChatIcon } from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import styles from "./Orders.module.css";
import { useAuth } from "../../components/context/Auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaleOrder from "./SaleOrder";
import PurchaseOrder from "./PurchaseOrder";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import AdjustmentOrder from "./AdjustmentOrder";
// import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import Transfer from "./Transfer";
import Production from "./Production";
import ProductionSale from "./ProductionSale";
import ProductionTransfer from "./ProductionTransfer";

const Order = () => {
  const { mode, designations } = useAuth();
  // const { show } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { route } = useParams();
  let isNew = false;
  isNew = location.search === "" ? true : false;

  const [isDefaultCase, setIsDefaultCase] = useState(false);
  const [orderType, setOrderType] = useState(isNew && "Sales");
  //same but for mobile
  const [selectedOrderType, setSelectedOrderType] = useState(
    isNew && {
      label: "Sales",
      value: "Sales",
    }
  );
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (route === "saleorder" || location.pathname.includes("saleorder")) {
      setOrderType("Sales");
    }
    if (route === "purchase" || location.pathname.includes("purchase")) {
      setOrderType("Purchase");
    }
    if (route === "adjustment" || location.pathname.includes("adjustment")) {
      setOrderType("Adjustment");
    }
    if (route === "transfer" || location.pathname.includes("transfer")) {
      setOrderType("Transfer");
    }
  }, [location.pathname, route]);

  const orderTypeChange = (label) => {
    const selectedFilter = orderTypeData.find((item) => item === label);
    setOrderType(selectedFilter || "Sales");
  };

  const handleOrderData = useCallback((data) => {
    setOrderData(data);
  }, []);

  let orderTypeData = [
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];

  const designationOptionsMap = useMemo(
    () => ({
      inventorymanager: [
        "Sales",
        "Purchase",
        "Adjustment",
        "Transfer",
        "Production",
        "Production & Sale",
        "Production & Transfer",
      ],
      superadmin: [
        "Sales",
        "Purchase",
        "Adjustment",
        "Transfer",
        "Production",
        "Production & Sale",
        "Production & Transfer",
      ],
      ordermanager: ["Sales", "Production & Sale"],
      default: ["Sales"],
    }),
    []
  );

  const getAllowedOrderTypes = useCallback(
    (designations) => {
      const adminRoles = ["inventorymanager", "superadmin"];
      const orderRoles = ["ordermanager"];

      if (!designations || designations.length === 0) {
        setIsDefaultCase(true);
        return designationOptionsMap.default;
      }

      const hasAdminRole = designations.some((designation) =>
        adminRoles.includes(designation)
      );

      const hasOrderRole = designations.some((designation) =>
        orderRoles.includes(designation)
      );

      const isDefaultCase = !hasAdminRole && !hasOrderRole;
      setIsDefaultCase(isDefaultCase);

      if (hasAdminRole) {
        return designationOptionsMap.inventorymanager;
      }

      if (hasOrderRole) {
        return designationOptionsMap.ordermanager;
      }

      return designationOptionsMap.default;
    },
    [designationOptionsMap]
  );

  orderTypeData = useMemo(() => {
    return getAllowedOrderTypes(designations);
  }, [designations, getAllowedOrderTypes]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isNew) {
        const allowedOrderTypes = getAllowedOrderTypes(designations);

        const orderTypeMapping = {
          F1: "Sales",
          F2: "Purchase",
          F3: "Adjustment",
          F4: "Transfer",
          F5: "Production",
          F6: "Production & Sale",
          F7: "Production & Transfer",
        };

        if (
          e.key.startsWith("F") &&
          !isNaN(e.key.slice(1)) &&
          e.key.length > 1
        ) {
          e.preventDefault();
        }

        const selectedType = orderTypeMapping[e.key];
        if (selectedType && allowedOrderTypes.includes(selectedType)) {
          setOrderType(selectedType);
          setSelectedOrderType({ label: selectedType, value: selectedType });
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [designations, getAllowedOrderTypes, isNew]);

  const topText1 = orderType === "Sales" ? "Sale Order" : orderType || "";
  const topText2 = orderData?.orderId ? ` / ${orderData.orderId}` : "";
  const topText = isNew ? topText1 : `${topText1}${topText2}`;

  // console.log("IS DEFAULT ", isDefaultCase);

  return (
    <div
      className={`${styles.order}`}
      style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
    >
      <div className={styles.order_top}>
        <div className={styles.order_top_left}>
          <TopTabBar text={topText} />
        </div>
        <div className={styles.order_right}>
          {isNew ? (
            <div style={{ display: isDefaultCase && "none" }}>
              <div className={styles.order_sf}>
                <SlidingFilter2
                  itemList={orderTypeData.map((item) => item)}
                  setFilter={orderTypeChange}
                  selectedItem={orderTypeData.find(
                    (item) => item === orderType
                  )}
                />
              </div>
              <div className={styles.order_dd_mob}>
                <VerticalDropDown
                  height={"37px"}
                  fontSize={"12px"}
                  onchange={(option) => {
                    setSelectedOrderType(option);
                    setOrderType(option.value);
                  }}
                  value={selectedOrderType}
                  options={orderTypeData.map((o) => ({ label: o, value: o }))}
                />
              </div>
            </div>
          ) : (
            <div className={styles.right}>
              <TextElement
                label={"Voucher No. & Date:"}
                labelColor={mode ? "#fff" : "#646B88"}
                value={orderData.orderId + " / " + orderData.orderDate}
                valueColor={mode ? "#fff" : "#646B88"}
                fontSize={"12px"}
                type={"order"}
              />
              {orderType === "Sales" && (
                <TextElement
                  label={"Created By:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.createdBy}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              {orderType === "Sales" && (
                <TextElement
                  label={"Order Manager:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.orderManager || "In Dev."}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              {orderType === "Sales" && (
                <TextElement
                  label={"Source:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.type}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              <TextElement
                label={"Status:"}
                labelColor={mode ? "#fff" : "#646B88"}
                value={orderData.status}
                valueColor={mode ? "#fff" : "#646B88"}
                fontSize={"12px"}
                type={"order"}
              />
            </div>
          )}

          {isNew && (
            <div>
              <Button
                buttonIcon={<ChatIcon />}
                buttonText={"Chat"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/whatsappchats");
                }}
              />
            </div>
          )}
        </div>
      </div>

      {orderType === "Sales" && <SaleOrder onOrderData={handleOrderData} />}
      {orderType === "Purchase" && (
        <PurchaseOrder onOrderData={handleOrderData} />
      )}
      {orderType === "Adjustment" && (
        <AdjustmentOrder onOrderData={handleOrderData} />
      )}
      {orderType === "Transfer" && <Transfer onOrderData={handleOrderData} />}
      {orderType === "Production" && <Production />}
      {orderType === "Production & Sale" && <ProductionSale />}
      {orderType === "Production & Transfer" && <ProductionTransfer />}
    </div>
  );
};

export default Order;
