// import React, { useEffect, useState } from 'react'
// import styles from "./Invoices.module.css"
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
// import { useNavigate, useSearchParams } from 'react-router-dom'
// // import { Spinner } from '../../components/icons/Icon'
// import { useAuth } from '../../components/context/Auth'

// const SaleInvoice = () => {
//     const navigate = useNavigate();
//     const { managerId } = useAuth()
//     const [searchparams] = useSearchParams()
//     const orderId = searchparams.get("orderId")
//     const [data, setData] = useState({})
//     const [reciptsInfo, setReciptsInfo] = useState([])
//     const [dragging, setDragging] = useState(false);
//     const [files, setFiles] = useState([]);
//     // const [files, setFiles] = useState(null);
//     const allowedFiles = {
//         image: ["png", "jpeg", "jpg", "webp", "svg", "gif"],
//         document: ["pdf", "doc", "xls", "ppt", "txt", "json"],
//       };
//       const validateFile = (file) => {
//         const fileExtension = file.name.split(".").pop().toLowerCase();
//         const isValid =
//           allowedFiles.image.includes(fileExtension) ||
//           allowedFiles.document.includes(fileExtension);

//         if (!isValid) {
//           alert(`Invalid file type: ${file.name}. Please upload valid files.`);
//           return false;
//         }

//         return true;
//       };

//       const handleFileChange = (e) => {
//         const uploadedFile = e.target.files[0];
//         if (uploadedFile && validateFile(uploadedFile)) {
//           setFiles(uploadedFile);
//         } else {
//           e.target.value = null; // Reset the input if validation fails
//         }
//       };

//     const handleDragOver = (e) => {
//         e.preventDefault();
//         setDragging(true);
//     };

//     const handleDragLeave = (e) => {
//         e.preventDefault();
//         setDragging(false);
//     };

//     const handleDrop = (e) => {
//         e.preventDefault();
//         setDragging(false);

//         const droppedFiles = Array.from(e.dataTransfer.files);
//         setFiles(droppedFiles);
//     };

//     useEffect(() => {
//         if (orderId && managerId) {
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "orderId": orderId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "invoice/getInvoice", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         if (result.data) {
//                             setData(result.data)
//                             setReciptsInfo(result.data.receipts)
//                         }
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }, [orderId, managerId])

//     const filterItem = (data.order && data.order.items && data.order.items.length > 0) && data.order.items.filter(item => (item.unit || item.qty > 0 || item.desc))

//     // useEffect(() => {
//     //     if (files && files.length !== 0 && orderId && managerId) {
//     //         var myHeaders = new Headers();
//     //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//     //         var formdata = new FormData();
//     //         formdata.append("pdf", files[0]);

//     //         var requestOptions = {
//     //             method: 'POST',
//     //             headers: myHeaders,
//     //             body: formdata,
//     //             redirect: 'follow'
//     //         };

//     //         fetch(process.env.REACT_APP_URL + "invoice/uploadInvoice?managerId=" + managerId + "&orderId=" + orderId, requestOptions)
//     //             .then(response => response.json())
//     //             .then(result => {
//     //                 alert(result.msg)
//     //             })
//     //             .catch(error => console.log('error', error));
//     //     }
//     // }, [files, orderId, managerId])
//     useEffect(() => {
//         if (files && orderId && managerId) {
//           const myHeaders = new Headers();
//           myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//           const formdata = new FormData();
//           formdata.append("pdf", files);

//           const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: formdata,
//             redirect: "follow",
//           };

//           fetch( 
//             `${process.env.REACT_APP_URL}invoice/uploadInvoice?managerId=${managerId}&orderId=${orderId}`,
//             requestOptions
//           )
//             .then((response) => response.json())
//             .then((result) => {
//               alert(result.msg || "File uploaded successfully.");
//               setFiles(null); // Reset file input after successful upload
//             })
//             .catch((error) => {
//               console.error("Error uploading file:", error);
//               alert("An error occurred while uploading the file.");
//             });
//         }
//       }, [files, orderId, managerId]);

//     return (
//         <React.Fragment>
//             <div
//                 className={dragging ? styles.invoice_dragDrop : styles.invoice_right_main}
//                 onDragOver={handleDragOver}
//                 onDragLeave={handleDragLeave}
//                 onDrop={handleDrop}
//             //   className={dragging?'draganddrop_active':'draganddrop'}
//             >
//                 <div className='text-center'>
//                     <h1 className={styles.Invoices_Heading}>Sale Invoice</h1>
//                 </div>

//                 <div className={styles.scroll_main_div}>
//                     <div className='d-flex justify-content-between'>

//                         <div className='w-50 me-5 pe-5'>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Dealer’s Name</label>
//                                 <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.name}</div>
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Billing Address</label>
//                                 <textarea
//                                     type="text"
//                                     rows="3"
//                                     className='border rounded bg-transparent text-light w-75 ms-5 px-2 py-2'
//                                     value={data.order && data.order.billingAddress}
//                                 />
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Shipping Address</label>
//                                 <textarea
//                                     type="text"
//                                     rows="3"
//                                     className='border rounded bg-transparent text-light w-75 ms-5 px-2 py-2'
//                                     value={data.order && (data.order.shippingInfo.street + ', ' + data.order.shippingInfo.district + ', ' + data.order.shippingInfo.city + ', ' + data.order.shippingInfo.state + ', INDIA, ' + data.order.shippingInfo.pincode)}
//                                 />
//                             </div>
//                         </div>

//                         <div className='w-50 ms-5 ps-5'>
//                             <div className='d-flex justify-content-between align-items-center mt-2 pt-2'>
//                                 <div className='w-50 d-flex justify-content-between me-5'>
//                                     <label>Voucher No.</label>
//                                     <b>{data.order && data.order.orderId}</b>
//                                 </div>
//                                 <div className='w-50 d-flex justify-content-between ms-5'>
//                                     <label>Voucher Date</label>
//                                     <b>{data.order && data.order.orderDate}</b>
//                                 </div>
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-3'>
//                                 <label className='w-25'>Warehouse</label>
//                                 <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.warehousename}</div>
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Order Manager</label>
//                                 <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.ordermanager}</div>
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Delivery Method</label>
//                                 <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.shippingInfo.courierName}</div>
//                             </div>
//                             <div className='d-flex justify-content-between align-items-center mt-2'>
//                                 <label className='w-25'>Delivery Partners</label>
//                                 <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.shippingInfo.methodName}</div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className={styles.fixTableHead_packing}>
//                         <table>
//                             <thead>
//                                 <tr>
//                                     <th>S.No.</th>
//                                     <th>Item Description</th>
//                                     <th>Qty</th>
//                                     <th>Rate</th>
//                                     <th>Per</th>
//                                     <th className='text-center'>Amount</th>
//                                 </tr>
//                             </thead>
//                             {filterItem && filterItem.map((val, key) => {
//                                 return (
//                                     <tbody key={key}>
//                                         <tr className={styles.h10}>
//                                             <td>{key + 1}</td>
//                                             <td>
//                                                 <div className={styles.itemDescription_div}>
//                                                     <span>{val.itemNo ? val.itemNo : val.accessoriesName}</span>
//                                                     <span>{val.product_name}</span>
//                                                     <span>{val.warehouse}</span>
//                                                     <span>{val.batch}</span>
//                                                 </div>
//                                             </td>
//                                             <td>{val.qty}</td>
//                                             <td>{val.price}</td>
//                                             <td>{val.unit ? val.unit : 'Pcs'}</td>
//                                             <td className='text-end'>{val.qty * val.price}</td>
//                                         </tr>
//                                         {(key === val.descKey || val.desc) && <tr style={{ border: '1px solid' }}>
//                                             <td colSpan={6} className={styles.itemDescription_div}>
//                                                 {val.desc}
//                                             </td>
//                                         </tr>
//                                         }

//                                         {/* <tr className={styles.brderTr}>
//                                                         <td></td>
//                                                         <td></td>
//                                                         <td></td>
//                                                         <td></td>
//                                                     </tr> */}
//                                     </tbody>
//                                 )
//                             })}
//                         </table>
//                     </div>

//                     <div className={styles.d_flex}>
//                         <div className={styles.addRecipt_div}>

//                             {/* <div className={styles.addRecipt_btn_div}>
//                             {payStatus &&
//                                 <button
//                                     type='button'
//                                     className={styles.addRecipt_btn}
//                                     onClick={() => {
//                                         if (!orderId) {
//                                             alert("Please Create Voucher Before")
//                                         } else {
//                                             setShowPaymentModal(true)
//                                             setReciptData(prevState => ({
//                                                 ...prevState,
//                                                 paymentMethod: 'Cash',
//                                                 paymentType: '',
//                                                 paymentAmount: '',
//                                                 transactionId: '',
//                                                 accountNumber: '',
//                                                 transactionScreenshot: '',
//                                                 receiptId: '',
//                                             }))
//                                         }
//                                     }}
//                                 >
//                                     Add Receipts
//                                 </button>
//                             }
//                         </div> */}

//                             <div className={styles.receipt_scroll}>
//                                 {
//                                     reciptsInfo && reciptsInfo.map((item, index) =>
//                                         <div key={index} className={styles.payment_detailsDiv} onClick={() => {
//                                             // setReciptData(item); setShowPaymentModal(true)
//                                         }}>
//                                             <span>Receipt Id : <b>{item.receiptId}</b></span>
//                                             <span>Method : <b>{item.paymentMethod}</b></span>
//                                             <span>Amount : <b>{item.paymentAmount}</b></span>
//                                             <span
//                                                 className={styles.recipt_statusWidth}>
//                                                 Status :
//                                                 <b role='button'
//                                                     className={item.status.includes('Pending') ? styles.recipt_status_pending :
//                                                         item.status === 'Cancelled' ? styles.recipt_status_red :
//                                                             styles.recipt_status_green}>
//                                                     <u>{item.status}</u>
//                                                 </b>
//                                             </span>
//                                         </div>
//                                     )
//                                 }
//                             </div>
//                         </div>

//                         <div className={styles.subTotal_flex_col}>
//                             <div className={styles.subTotal_div}>
//                                 <span>Sub Total</span>
//                                 <span>{data.order && data.order.subtotal}</span>
//                             </div>
//                             <div className={styles.subTotal_div}>
//                                 <span>Shipping Charges</span>
//                                 <div className='w-50 d-flex justify-content-between align-items-center'>
//                                     <input
//                                         type="number"
//                                         onWheel={(e) => e.target.blur()}
//                                         className={styles.shipingCharges_input}
//                                         value={data.order && data.order.shippingInfo.shippingCharge}
//                                         // value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
//                                         // onChange={(e) => setGeneralInfo(prevState => ({
//                                         //     ...prevState,
//                                         //     shippingCharge: e.target.value
//                                         // }))}
//                                         maxLength="10"
//                                         onInput={(e) => e.target.value = e.target.value.slice(0, 10)}

//                                     />
//                                     <span className='d-flex justify-content-between align-items-center'>
//                                         To Pay&nbsp;&nbsp;
//                                         <input
//                                             type='checkbox'
//                                             className={styles.toPay_checkbox}
//                                             // value={generalInfo.toPay}
//                                             // onChange={(e) => setGeneralInfo(prevState => ({
//                                             //     ...prevState,
//                                             //     toPay: e.target.checked
//                                             // }))}
//                                             checked={data.order && data.order.toPay && data.order.toPay === true}
//                                             disabled
//                                         />
//                                     </span>

//                                 </div>
//                                 <span className={styles.fixSize_input}>{data.order && data.order.shippingInfo.shippingCharge}</span>
//                                 {/* <span className={styles.fixSize_input}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span> */}
//                             </div>
//                             <div className={styles.subTotal_div}>
//                                 <input
//                                     type="text"
//                                     className={styles.adjustment}
//                                     value={data.order && data.order.manageName}
//                                     placeholder="Enter Manage"
//                                     disabled
//                                 // onChange={(e) => setGeneralInfo(prevState => ({
//                                 //     ...prevState,
//                                 //     manageName: e.target.value,
//                                 // }))}
//                                 // required={generalInfo.manageValue ? true : false}
//                                 />
//                                 <div className='w-50'>
//                                     <input
//                                         type="number"
//                                         className={styles.shipingCharges_input}
//                                         value={data.order && data.order.manageValue ? data.order.manageValue : 0}
//                                         name='manageValue'
//                                         // onChange={(e) => {
//                                         //     setGeneralInfo(prevState => ({
//                                         //         ...prevState,
//                                         //         manageValue: e.target.value
//                                         //     }))
//                                         // }}
//                                         maxLength="10"
//                                         onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                         // required={generalInfo.manageName ? true : false}
//                                         onWheel={(e) => e.target.blur()}
//                                     />
//                                 </div>
//                                 <span className={styles.subTotal_txtRed}>
//                                     {data.order && data.order.manageValue ? data.order.manageValue : 0}
//                                 </span>
//                             </div>

//                             {/* <div className={styles.subTotal_div}>
//                             <span>Round Off</span>
//                             <span className={styles.fixSize_input}>
//                                 {roundedAmt}</span>
//                         </div> */}

//                             <div className={styles.totalINR_div}>
//                                 <span>Total (INR)</span>
//                                 <span>{data.order && data.order.totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
//                             </div>
//                         </div>

//                     </div>

//                     <div className={styles.orders_btn_div}>
//                         <RedOutButton
//                             btnType="button"
//                             title='BACK'
//                             handleSubmit={() => navigate(-1)}
//                         />
//                         <GreenOutButton
//                             btnType="button"
//                             title="VIEW ORDER"
//                             handleSubmit={() => { navigate('/orders/saleorder?orderId=' + orderId) }}
//                         />
//                         {/* <label className={styles.uploadData_btn}>
//                             UPLOAD INVOICE
//                             <input className='d-none' type="file" onChange={(e) => setFiles(e.target.files)} />
//                         </label> */}
//                            <div>
//       <label className={styles.uploadData_btn}>
//         UPLOAD INVOICE
//         <input
//           className="d-none"
//           type="file"
//           onChange={handleFileChange}
//         />
//       </label>
//     </div>
//                         <GreenOutButton
//                             btnType="button"
//                             title="VIEW INVOICE"
//                             handleSubmit={() => { navigate('/invoices/uploadedinvoice?orderId=' + orderId) }}
//                         />
//                         <GreenOutButton
//                             btnType="button"
//                             title=" UPLOAD DATA"
//                             handleSubmit={() => { }}
//                         />
//                         <GreenButton
//                             btnType="button"
//                             title='DOWNLOAD DATA'
//                             handleSubmit={() => { }}
//                         />
//                     </div>

//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default SaleInvoice





import React, { useEffect, useState } from 'react'
import styles from "./Invoices.module.css"
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import InputTag from '../../components/updatedComponents/FormComponents/InputTag'
import { useNavigate, useParams, } from 'react-router-dom'
import fetchData from '../../APIsControll/apiControll'
import Button from '../../components/updatedComponents/Dealers/Button'
import { Back, Uploadinvoice, Viewaccountinfo, Viewinvoice } from '../../components/icons/Icon4'
import { handleFileData } from '../../APIsControll/apiControl2'
import FileUploadButton from '../../components/updatedComponents/Dealers/Buttonupload'
import { TemplateDone } from '../../components/icons/Icon2'
import TextElement from '../../components/updatedComponents/FormComponents/TextElement'
import Spinner from '../../components/updatedComponents/FormComponents/Spinner'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'


const SaleInvoice = () => {
    const { mode } = useAuth();
    const navigate = useNavigate();
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [accountinfo, setAccountinfo] = useState({});

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const { managerId } = useAuth()
    const [data, setData] = useState([])
    const [receipt, setReceipt] = useState([])
    const params = useParams()
    const { show } = useToast();

    const orderHeader = [
        "S.no",
        "Item Number",
        "Product",
        "Batch No.",
        "Quantity",
        "Unit",
        "Rate",
        "Amount",
    ];

    const orderHeader1 = [
        "Receipt ID",
        "Method",
        "Amount",
        "Status",
        "Account Info ",
        ,
    ];
    const getStatusColor = (status) => {
        switch (status) {
            case "ordered":
            case "Packed":
            case "success":
                return "green";

            case "Not Packed":
            case "ordered Pending":
            case "Payment Under Verification":
            case "Request For Edit":
            case "Edit request Approved":
            case "Request For Cancel":
                return "red";

            case "Packing In Progress":
            case "Draft":
            case "In Progress":
            case "Payment Pending":
                return "red";

            case "New Order":
            case "draft":
                return "#7698FF";

            default:
                return "";
        }
    };
    const allowedFiles = {
        image: ["png", "jpeg", "jpg", "webp", "svg", "gif"],
        document: ["pdf", "doc", "xls", "ppt", "txt", "json"],
    };
    const validateFile = (file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        const isValid =
            allowedFiles.image.includes(fileExtension) ||
            allowedFiles.document.includes(fileExtension);

        if (!isValid) {
            alert(`Invalid file type: ${file.name}. Please upload valid files.`);
            return false;
        }

        return true;
    };


    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile && validateFile(uploadedFile)) {
            setFiles(uploadedFile);
        } else {
            e.target.value = null;
        }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('orderId');
    console.log("uihiu", orderId);


    useEffect(() => {
        if (files) {
            uploadinvoicedata();
        }
    }, [files]);

    const uploadinvoicedata = async () => {
        if (!files) return;

        const formData = new FormData();
        formData.append("managerId", managerId);
        formData.append("orderId", orderId);
        formData.append("file", files);

        console.log("Uploading file:", files);

        const res = await handleFileData(`invoice/uploadInvoice`, formData);
        console.log(res);
        if (res.status === 'success') {
            show(res.msg)

        }
        if (res.status === "failed") {
            show(res.msg, "error")
  
          }

    };

    const getinvoicedata = async () => {
        try {
            const payload = {
                managerId: parseInt(managerId),
                orderId: orderId,


            }
            console.log("azzzz", payload);

            const res = await fetchData(`invoice/getInvoice`, payload);
            if (res.status === "success") {
                setData(res?.data?.order)
                setReceipt(res?.data?.receipt)
                console.log("oooo", res.data.receipt);

                console.log("fghjk", res.data);


            }

        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getinvoicedata()
    }, [])
    const shippingData = `${data?.shippingInfo?.city}${" "} ${data?.shippingInfo?.district} ${" "}${data?.shippingInfo?.state}${" "} ${data?.shippingInfo?.country}`



    return (
        <React.Fragment>
            <div
                style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }} className={styles.main} >
                <div className={`${styles.mainCon} ${mode ? styles.mainConDark : styles.mainConLight}`}>
                    <div className={styles.order_top}>
                        <div className={styles.order_top_left}>
                            <TopTabBar text={"Sale Invoice"} />
                        </div>
                        <TextElement
                            label={"Voucher No. & Voucher Date:"}
                            labelColor={mode ? "#fff" : "#646B88"}
                            value={orderId + " / " + data.orderDate}
                            valueColor={mode ? "#fff" : "#646B88"}
                            fontSize={"12px"}
                            type={"order"}
                        />
                    </div>
                    <div>
                        <div className={styles.inputboxes}>
                            <div className={styles.input1}>
                                <InputTag
                                    labelText={"Dealer / Dealer ID"}
                                    padding={"8px"}
                                    fontSize={"12px"}
                                    placeholderText={"Enter Warehouse Name"}
                                    maxLength={40}
                                    placeholderType={"text"}
                                    value={data?.dealerId} /></div>
                            <div className={styles.input2}><InputTag
                                labelText={"Warehouse"}
                                padding={"8px"}
                                fontSize={"12px"}
                                placeholderText={"Enter Warehouse Name"}
                                maxLength={40}
                                placeholderType={"text"}
                                value={data?.warehousename}

                            /></div>
                            <div className={styles.input2}> <InputTag
                                labelText={"Order Manager"}
                                padding={"8px"}
                                fontSize={"12px"}
                                placeholderText={"Enter Warehouse Name"}
                                maxLength={40}
                                placeholderType={"text"}
                                value={data?.ordermanager}

                            /></div>
                            <div className={styles.input2}>    <InputTag
                                labelText={"Delivery Partner"}
                                padding={"8px"}
                                fontSize={"12px"}
                                placeholderText={"Enter Warehouse Name"}
                                maxLength={40}
                                placeholderType={"text"}
                                value={data?.shippingInfo?.partnerName}

                            /></div>
                            <div className={styles.input2}>    <InputTag
                                labelText={"Delivery Method"}
                                padding={"8px"}
                                fontSize={"12px"}
                                placeholderText={"Enter Warehouse Name"}
                                maxLength={40}
                                placeholderType={"text"}
                                value={data?.shippingInfo?.methodName}

                            /></div>
                        </div>
                        <div className={styles.inputboxes1}>
                            <div className={styles.input3}>
                                <InputTag
                                    labelText={"Billing Address"}
                                    padding={"8px"}
                                    fontSize={"12px"}
                                    placeholderText={"Enter Warehouse Name"}
                                    maxLength={40}
                                    placeholderType={"text"}
                                    value={data?.billingAddress}
                                />
                            </div>

                            <div className={styles.input3}>
                                <InputTag
                                    labelText={"Shipping Address"}
                                    padding={"8px"}
                                    fontSize={"12px"}
                                    placeholderText={"Enter Warehouse Name"}
                                    maxLength={40}
                                    placeholderType={"text"}
                                    value={shippingData}

                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.tablemain}>
                        <ul className={styles.order_data_list}>
                            <li
                                className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                                    }`}>

                                {orderHeader.map((h) => (
                                    <div className={styles.entry} key={h}>
                                        {h}
                                    </div>
                                ))}

                            </li>
                            <div className={styles.order_data_list_container}>
                                {loading ?
                                    <div style={{ margin: "1rem auto" }}>
                                        <Spinner height={80} width={80} />
                                    </div>
                                    :
                                    data?.data?.length === 0 ? (
                                        <img
                                            src="/assets/updatedAssets/noDATA.png"
                                            alt="img"
                                            style={{
                                                height: "12rem",
                                                width: "16rem",
                                                margin: "1rem auto",
                                            }}
                                        />
                                    ) :
                                        <>
                                            {data?.items?.map((d, i) => (
                                                <li
                                                    key={i}
                                                    className={`${styles.order_data_list_row2} ${mode ? styles.order_data_list_row_dark : ""
                                                        }`}
                                                    onClick={() => { navigate('/invoices/invoice?orderId=' + d.orderId) }}
                                                >
                                                    <div className={styles.entry}>
                                                        {i + 1}
                                                    </div>
                                                    <div className={styles.entry}>{d.itemNo}</div>
                                                    <div className={styles.entry}>{d.product_name}</div>
                                                    <div className={styles.entry}>{d.batch}</div>
                                                    <div className={styles.entry}>{d.qty}</div>
                                                    <div className={styles.entry}>{d.unit}</div>
                                                    <div
                                                        className={styles.entry}

                                                    >
                                                        {d.price}
                                                    </div>
                                                    <div className={styles.entry}>{d.qty * d.price}</div>
                                                </li>
                                            ))}
                                        </>
                                }
                            </div>
                        </ul>
                    </div>

                    <div className={styles.orders_btn_div1}>
                        <div className={styles.continer_all_feed}>
                            <div className={styles.bottomtable}>
                                <ul className={styles.bottomul} >
                                    <li
                                        className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                                            }`}>
                                        {orderHeader1.map((h) => (
                                            <div className={styles.entry} key={h}>
                                                {h}
                                            </div>
                                        ))}
                                    </li>
                                </ul>

                                <ul className={styles.bottomu2}
                                    style={{
                                        width: "28vw",
                                        minHeight: "5vh",
                                        maxHeight: "10vh",

                                    }}                           >
                                    {receipt?.map((d, i) => (
                                        <li
                                            key={i}
                                            className={`${styles.order_data_list_row2} ${mode ? styles.order_data_list_row_dark : ""
                                                }`}
                                            onClick={() => navigate(`/invoices/invoice?orderId=${d.orderId}`)}
                                        >
                                            <div className={styles.entry}>{d.receiptId}</div>
                                            <div className={styles.entry}>{d.paymentMethod}</div>
                                            <div className={styles.entry}>{d.paymentAmount}</div>

                                            <div className={styles.entry} style={{ color: getStatusColor(d?.status) }}>
                                                {d.status}
                                            </div>
                                            <div style={{ paddingLeft: "15px" }} className={styles.entry}><span onClick={() => {
                                                handleOpenModal();
                                                setAccountinfo(d)
                                            }} style={{ cursor: "pointer" }}>
                                                <Viewaccountinfo />
                                            </span></div>

                                        </li>
                                    ))}
                                </ul>

                            </div>
                            <div className={styles.mshipping} >

                                <div
                                    className={`${styles.so_amount_info} ${mode ? styles.so_amount_info_dark : ""
                                        }`}
                                >
                                    <div>
                                        <p>Sub Total:</p>

                                        <p>{data.subtotal}</p>

                                    </div>
                                    <div className={styles.so_shipping}>
                                        <p>Shipping Charges:{data?.order?.shippingInfo?.shippingCharge}</p>


                                        <div className={styles.so_check}>
                                            <p>To Pay</p>
                                            <input
                                                type="checkbox"
                                                checked={!!data?.toPay}

                                            />
                                            <p>{data?.shippingInfo?.shippingCharge}</p>

                                        </div>

                                    </div>
                                    <div>
                                        <p>Total Amount(INR):</p>
                                        <p>{data.totalAmount}</p>

                                    </div>
                                </div>

                                <div style={{}} className={styles.bottombtncon}>

                                    <div>
                                        <Button
                                            buttonIcon={<Viewinvoice />}
                                            buttonText={" VIEW ORDER"}
                                            buttonBgColor={"black"}
                                            buttonTextColor={"white"}
                                            buttonClick={() => { navigate('/orders/saleorder?orderId=' + orderId) }} /></div>

                                    <div className={styles.updateinvoicebtn} >
                                        <FileUploadButton
                                            buttonIcon={<Uploadinvoice />}
                                            buttonText="Upload File"

                                            buttonBgColor="black"
                                            disabledButtonBgColor="#ccc"
                                            buttonTextColor="#fff"
                                            disabledButtonTextColor="#666"
                                            buttonClick={() => console.log("Button clicked")}
                                            buttonBorder="1px solid black"
                                            loading={false}
                                            disabled={false}
                                            tabIndex={0}
                                            buttonType="button"
                                            onFileChange={handleFileChange}
                                            accept=".jpg,.png,.pdf"
                                            buttonWidth={"10px"} />
                                    </div>
                                    <div>
                                    </div>
                                    <div>
                                        <Button
                                            buttonIcon={<Viewinvoice />}
                                            buttonText={" View Invoice"}
                                            buttonBgColor={"black"}
                                            buttonTextColor={"white"}
                                            buttonClick={() => { navigate('/invoices/uploadedinvoice?orderId=' + orderId) }}

                                        />
                                    </div>                           </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div>
                    <ModalWrapper
                        style={{
                            borderRadius: "15px",
                            backgroundColor: mode ? "black" : "white",
                            color: mode ? "white" : "black",
                            width: "35vh",
                            padding: "10px"
                        }}
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >

                        <p style={{ margin: '0 0 10px 0' }}> Account No:  {accountinfo.accountNumber}</p>

                        <p style={{ margin: 0 }}> IFSC Code:  {accountinfo?.ifscCode}</p>

                    </ModalWrapper>
                </div>


            </div>
        </React.Fragment>
    )
}

export default SaleInvoice