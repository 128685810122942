import React from 'react'
import styles from './ProductCompo.module.css'
import { DealerInfoCardActiveStatusIcon, DealerInfoCardActiveStatusIcon1, DealerInfoCardBlackListedStatusIcon, DealerInfoCardNewStatusIcon, DealerInfoCardPendingStatusIcon, DealerInfoCardRejectedStatusIcon } from '../../icons/Icon';
import { AllCount, Deleted, Verified } from '../../icons/Icon2';
import { DeleteTo, EditeTo } from '../../icons/Icon3';
import { useAuth } from '../../context/Auth';
const StyleTypeCard = ({
    Status,
    imgUrl,
    Text,
    EditeClick,
    DeleteClick,
    ststusIcon
}) => {
    const { mode } = useAuth()
    const renderStatusIcon = () => {
        switch (ststusIcon) {
            case "disable":
                return <DealerInfoCardBlackListedStatusIcon />;
            case "Blacklisted":
                return <DealerInfoCardBlackListedStatusIcon />;
            case "New":
                return <DealerInfoCardNewStatusIcon />;
            case "Pending":
                return <DealerInfoCardPendingStatusIcon />;
            case "Rejected":
                return <DealerInfoCardRejectedStatusIcon />;
            case "All":
                return <AllCount />;
            case "Verified":
                return <Verified />;
            case "active":
                return <DealerInfoCardActiveStatusIcon1 />;
            case "Active":
                return <DealerInfoCardActiveStatusIcon1 />;
            case "Update":
                return <DealerInfoCardActiveStatusIcon />;
            case "Deleted":
                return <Deleted />;
            default:
                return null;
        }
    };
    return (
        <div className={styles.Card_main} style={{
            border: mode ? "1px solid #1B1D21 " : "1px solid #E4E7EB",
            backgroundColor: mode ? "#1B1D21" : "#fff"
        }} >
            <div className={styles.Image_card_continer}>
                <img src={imgUrl ? `${process.env.REACT_APP_S3URLA}${imgUrl}` : "/assets/updatedAssets/dealerCardPfp.png"} alt="" />
            </div>
            <div className={styles.card_Status}>
                <div> {renderStatusIcon()}  </div>
                <div> {Status} </div>
            </div>
            <div className={styles.Card_text} style={{ color: mode ? "#F8F9FC" : "#646B88" }} >{Text}</div>
            <div className={styles.Icon_continer}>
                <div className={styles.icon_ContinerIs}
                    style={{ backgroundColor: mode ? "#212327" : "#F8F9FC" }}
                    onClick={()=>EditeClick()}
                ><EditeTo /></div>
                <div className={styles.icon_ContinerIs}
                    style={{ backgroundColor: mode ? "#212327" : "#F8F9FC" }}
                onClick={()=>DeleteClick()}
                ><DeleteTo /></div>
            </div>
        </div>
    )
}

export default StyleTypeCard