import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import styles from "./Orders.module.css";

const OrderFilters = ({ mode, selectedFilter, headerData, handleClick }) => {
  return (
    <>
      <>
        {headerData?.map((s, index) => (
          <div
            key={s.status + index}
            className={`${styles.order_filter} ${
              mode ? styles.order_filter_dark : ""
            } ${
              s.status === selectedFilter
                ? mode
                  ? styles.order_filter_active_dark
                  : styles.order_filter_active
                : ""
            }`}
            onClick={() => {
              handleClick(s.status);
            }}
          >
            <DealerStatusCard statusText={s.status} statusItemCount={s.count} />
          </div>
        ))}
      </>
    </>
  );
};

export default OrderFilters;
